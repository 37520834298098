import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  schoolCurriculums: [],
  loading: false,
  addOrUpdateloading: false,
  updateUploadProgress: 0,
  curriculums: [],
};

const adminSchoolCurriculumSlice = createSlice({
  name: 'schoolCurriculumsList',
  initialState,
  reducers: {
    setSchoolCurriculumsList: (state, action) => {
      state.schoolCurriculums = action.payload;
      state.loading = initialState.loading;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
    setAddOrUpdateLoader: (state, action) => {
      state.addOrUpdateloading = action.payload;
    },
    setUpdateUploadProgress: (state, action) => {
      state.updateUploadProgress = action.payload;
    },
    setCurriculums: (state, action) => {
      state.curriculums = action.payload;
      state.loading = initialState.loading;
    },
  },
});

export const getSchoolCurriculumsListRedux = (state) =>
  state.AdminSchoolCurriculum.schoolCurriculums || [];
export const getSchoolCurriculumsListLoaderRedux = (state) =>
  state.AdminSchoolCurriculum.loading;
export const getAddOrUpdateSchoolCurriculumLoaderRedux = (state) =>
  state.AdminSchoolCurriculum.addOrUpdateloading;

export const getCurriculumRedux = (state) =>
  state.AdminSchoolCurriculum.curriculums || [];

export const {
  setSchoolCurriculumsList,
  setLoader,
  setAddOrUpdateLoader,
  setUpdateUploadProgress,
  setCurriculums,
} = adminSchoolCurriculumSlice.actions;

export default adminSchoolCurriculumSlice.reducer;
