import { APIClient } from './api_helper';
import * as url from './url_helper';

const api = new APIClient();

// List Of Schools (db)
export const listOfUsersService = ({ value, roleId }) => {
  //console.log('in list of user service -- ', { value, roleId });
  let urlSegments = (value && `?name=${value}&`) || '?';
  urlSegments = (roleId && `${urlSegments}roleId=${roleId}`) || '';
  //console.log(url.LIST_OF_USERS + urlSegments);
  return api.get(url.LIST_OF_USERS + urlSegments);
};

export const createUser = (data, headerInfo) => {
  return api.create(url.CREATE_USER, data, headerInfo);
};

export const updateUser = (data, headerInfo) => {
  return api.put(url.UPDATE_USER, data, headerInfo);
};

export const deleteUser = (data) => {
  return api.delete(url.DELETE_USER + `/${data}`);
};
