import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  chapters: [],
  totalRecords: 0,
  loading: false,
};

const adminChapterSlice = createSlice({
  name: 'chaptersList',
  initialState,
  reducers: {
    setChaptersList: (state, action) => {
      state.chapters = action.payload;
      state.loading = initialState.loading;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
    setTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
  },
});

export const getChaptersListRedux = (state) => state.AdminChapter.chapters;
export const getChaptersListLoaderRedux = (state) => state.AdminChapter.loading;
export const getTotalRecordsRedux = (state) => state.AdminChapter.totalRecords;

export const { setChaptersList, setLoader, setTotalRecords } =
  adminChapterSlice.actions;

export default adminChapterSlice.reducer;
