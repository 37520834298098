import {
  getAccountDetailsService,
  updateAccountDetailsService,
  getGuardiansSiblingsListService,
  saveOrAddNewGuardianService,
  saveOrAddNewSiblingService,
  verifyNewMobileNumberService,
  verifyNewMobileNumberOTPService,
} from 'helpers/auth';
import {
  viewProfileInformationFn,
  resetProfileInformationFn,
  updateProfileDetailsFn,
  resetUpdateProfileDetailsFn,
  setGuardiansListFn,
  setSiblingsListFn,
  saveNewGuardianFn,
  resetSaveNewGuardianFn,
  saveNewSiblingFn,
  resetSaveNewSiblingFn,
  verifyNewMobileNumberFn,
  resetVerifyNewMobileNumberFn,
  verifyNewMobileNumberOTPFn,
  resetVerifyNewMobileNumberOTPFn,
  setSelectStudentFn,
  resetSelectStudentFn,
  setClassJoinedStudentInfoFn,
  resetClassJoinedStudentInfoFn,
  resetGuardiansSiblingsListFn,
  catchErrorsFn,
  setLoader,
} from './reducer';

import { getHeadersPayload } from 'helpers/common';
import { base } from 'config';

export const getSGProfileInformation =
  (accessToken = '') =>
  async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const payload = getHeadersPayload(accessToken);
      const { data, status, message } = await getAccountDetailsService(payload);
      if (status === 200) {
        dispatch(viewProfileInformationFn(data));
      } else {
        const dataObj = { status: status, message: message };
        dispatch(catchErrorsFn(dataObj));
      }
    } catch (e) {
      const error = { status: e?.status || 400, message: e?.message };
      dispatch(catchErrorsFn(error));
    }
  };

export const updateSGProfileInformation =
  (values, accessToken = '') =>
  async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const payload = getHeadersPayload(accessToken);
      const { data, status, message } = await updateAccountDetailsService(
        values,
        payload,
      );

      dispatch(updateProfileDetailsFn({ status, message }));
    } catch (e) {
      const error = { status: e?.status || 400, message: e?.message };
      dispatch(updateProfileDetailsFn(error));
    }
  };

export const getSGGuardiansSiblingsList =
  (accessToken = '') =>
  async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const payload = getHeadersPayload(accessToken);
      const { data, status, message } = await getGuardiansSiblingsListService(
        {},
        payload,
      );
      if (status === 200) {
        const guardianList = getUserListViaType(
          data,
          base?.USER_TYPES?.GUARDIAN,
        );
        const siblingList = getUserListViaType(data, base?.USER_TYPES?.STUDENT);
        dispatch(setGuardiansListFn(guardianList));
        dispatch(setSiblingsListFn(siblingList));
      } else {
        const dataObj = { status: status, message: message };
        dispatch(catchErrorsFn(dataObj));
      }
    } catch (e) {
      const error = { status: e?.status || 400, message: e?.message || '' };
      dispatch(catchErrorsFn(error));
    }
  };

export const saveOrAddNewGuardian =
  (values, accessToken = '') =>
  async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const payload = getHeadersPayload(accessToken);
      const { status, message } = await saveOrAddNewGuardianService(
        values,
        payload,
      );
      dispatch(saveNewGuardianFn({ status, message }));
    } catch (e) {
      const error = { status: e?.status || 400, message: e?.message || '' };
      dispatch(saveNewGuardianFn(error));
    }
  };

export const saveOrAddNewSibling =
  (values, accessToken = '') =>
  async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const payload = getHeadersPayload(accessToken);
      const { status, message } = await saveOrAddNewSiblingService(
        values,
        payload,
      );

      dispatch(saveNewSiblingFn({ status, message }));
    } catch (e) {
      const error = { status: e?.status || 400, message: e?.message || '' };
      dispatch(saveNewSiblingFn(error));
    }
  };

export const verifyNewMobileNumber =
  (values, accessToken = '') =>
  async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const payload = getHeadersPayload(accessToken);
      const { status, message } = await verifyNewMobileNumberService(
        values,
        payload,
      );

      dispatch(verifyNewMobileNumberFn({ status, message }));
    } catch (e) {
      const error = { status: e?.status || 400, message: e?.message || '' };
      dispatch(verifyNewMobileNumberFn(error));
    }
  };

export const verifyNewMobileNumberOTP =
  (values, accessToken = '') =>
  async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const payload = getHeadersPayload(accessToken);
      const { status, message } = await verifyNewMobileNumberOTPService(
        values,
        payload,
      );

      dispatch(verifyNewMobileNumberOTPFn({ status, message }));
    } catch (e) {
      const error = { status: e?.status || 400, message: e?.message || '' };
      dispatch(verifyNewMobileNumberOTPFn(error));
    }
  };

export const resetAllProfileDetails = () => async (dispatch) => {
  dispatch(resetProfileInformationFn());
  dispatch(resetUpdateProfileDetailsFn());
  dispatch(resetSaveNewGuardianFn());
  dispatch(resetSaveNewSiblingFn());
  dispatch(resetVerifyNewMobileNumberFn());
  dispatch(resetVerifyNewMobileNumberOTPFn());
  dispatch(resetSelectStudentFn());
  dispatch(resetGuardiansSiblingsListFn());
  dispatch(triggerResetClassJoinedStudentInfo());
};

export const resetProfileDetails = () => async (dispatch) => {
  dispatch(resetProfileInformationFn());
};

export const resetUpdateProfileDetails = () => async (dispatch) => {
  dispatch(resetUpdateProfileDetailsFn());
};

export const resetSaveNewGuardian = () => async (dispatch) => {
  dispatch(resetSaveNewGuardianFn());
};

export const resetSaveNewSibling = () => async (dispatch) => {
  dispatch(resetSaveNewSiblingFn());
};

export const resetVerifyNewMobileNumber = () => async (dispatch) => {
  dispatch(resetVerifyNewMobileNumberFn());
};

export const resetVerifyNewMobileNumberOTP = () => async (dispatch) => {
  dispatch(resetVerifyNewMobileNumberOTPFn());
};

export const reUpdateProfileInfo = (data) => async (dispatch) => {
  try {
    dispatch(viewProfileInformationFn(data));
  } catch (e) {
    const error = { status: e?.status || 400, message: e?.message };
    dispatch(catchErrorsFn(error));
  }
};

export const getUserListViaType = (data = [], type = '') => {
  if (type) {
    return data?.filter((item) => {
      if (item?.members?.userType?.toString() === type) {
        return item;
      }
    });
  }

  return data;
};

export const triggerSetSelectStudent = (data) => async (dispatch) => {
  try {
    dispatch(setSelectStudentFn(data));
  } catch (e) {
    const error = { status: e?.status || 400, message: e?.message };
    dispatch(catchErrorsFn(error));
  }
};

export const triggerResetSelectStudent = (data) => async (dispatch) => {
  try {
    dispatch(resetSelectStudentFn());
  } catch (e) {
    const error = { status: e?.status || 400, message: e?.message };
    dispatch(catchErrorsFn(error));
  }
};

export const triggerSetClassJoinedStudentInfo = (data) => async (dispatch) => {
  try {
    dispatch(setClassJoinedStudentInfoFn(data));
  } catch (e) {
    const error = { status: e?.status || 400, message: e?.message };
    dispatch(catchErrorsFn(error));
  }
};

export const triggerResetClassJoinedStudentInfo =
  (data) => async (dispatch) => {
    try {
      dispatch(resetClassJoinedStudentInfoFn());
    } catch (e) {
      const error = { status: e?.status || 400, message: e?.message };
      dispatch(catchErrorsFn(error));
    }
  };
