import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
  studentReportData: {},
};

const studentReportSlice = createSlice({
  name: 'studentReportSlice',
  initialState,
  reducers: {
    setStudentReportData: (state, action) => {
      state.studentReportData = action.payload;
    },
  },
});

export const getStudentReportData = (state) => {
  return state.StudentReport.studentReportData;
};

export const { setStudentReportData } = studentReportSlice.actions;

export default studentReportSlice.reducer;
