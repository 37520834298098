import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  batches: [],
  totalRecords: 0,
  loading: false,
  addOrUpdateloading: {
    loader: false,
    error: null,
    success: false,
  },
  updateDemoBatchloading: {
    loader: false,
    error: null,
    success: false,
  },
};

const adminBatchSlice = createSlice({
  name: 'batchesList',
  initialState,
  reducers: {
    setBatchesList: (state, action) => {
      state.batches = action.payload;
      state.loading = initialState.loading;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
    setTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
    setAddOrUpdateLoader: (state, action) => {
      state.addOrUpdateloading = action.payload;
    },
    setUpdateDemoBatchLoader: (state, action) => {
      state.updateDemoBatchloading = action.payload;
    },
    reset: (state) => {
      state.addOrUpdateloading = initialState.addOrUpdateloading;
      state.updateDemoBatchloading = initialState.updateDemoBatchloading;
    },
  },
});

export const getBatchesListRedux = (state) => state.AdminBatch.batches;
export const getBatchesListLoaderRedux = (state) => state.AdminBatch.loading;
export const getTotalRecordsRedux = (state) => state.AdminBatch.totalRecords;
export const {
  setBatchesList,
  setLoader,
  setTotalRecords,
  setAddOrUpdateLoader,
  setUpdateDemoBatchLoader,
  reset,
} = adminBatchSlice.actions;
export const getAddOrUpdateBatchLoaderRedux = (state) =>
  state.AdminBatch.addOrUpdateloading;
export const getUpdateDemoBatchLoaderRedux = (state) =>
  state.AdminBatch.updateDemoBatchloading;

export default adminBatchSlice.reducer;
