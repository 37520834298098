import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  initiateRegistration: {
    success: null,
    error: null,
    data: null,
  },
  verifyRegistrationOTP: {
    success: null,
    error: null,
    data: null,
  },
  saveRegistrationDetails: {
    success: null,
    error: null,
    data: null,
  },
  savePasscode: {
    success: null,
    error: null,
    data: null,
  },
  resendPasscode: {
    success: null,
    error: null,
    data: null,
  },
  loading: false,
};

const registerSlice = createSlice({
  name: 'StudentGuardianRegistration',
  initialState,
  reducers: {
    initiateUserRegistrationSuccessful(state, action) {
      state.initiateRegistration = {
        success: true,
        error: false,
        data: action.payload,
      };
      state.loading = initialState.loading;
    },
    verifyUserRegistrationOTPSuccessful(state, action) {
      state.verifyRegistrationOTP = {
        success: true,
        error: false,
        data: action.payload,
      };
      state.loading = initialState.loading;
    },
    resendRegistrationOTPSuccessful(state, action) {
      state.resendPasscode = {
        success: true,
        error: false,
        data: action.payload,
      };
      state.loading = initialState.loading;
    },
    saveUserRegistrationDetailsSuccessful(state, action) {
      state.saveRegistrationDetails = {
        success: true,
        error: false,
        data: action.payload,
      };
      state.loading = initialState.loading;
    },
    setUserPasscodeSuccessful(state, action) {
      state.savePasscode = {
        success: true,
        error: false,
        data: action.payload,
      };
      state.loading = initialState.loading;
    },
    registrationFailed(state, action) {
      if (action?.payload?.methodName !== '') {
        state[action?.payload?.methodName] = {
          success: false,
          error: true,
          data: action.payload,
        };
        state.loading = initialState.loading;
      }
    },
    resetErrors(state, action) {
      if (action?.payload?.methodName !== '') {
        state[action?.payload?.methodName] = {
          success: null,
          error: null,
          data: null,
        };
        state.loading = initialState.loading;
      }
    },
    resetRegistration(state) {
      state.initiateRegistration = initialState.initiateRegistration;
      state.verifyRegistrationOTP = initialState.verifyRegistrationOTP;
      state.saveRegistrationDetails = initialState.saveRegistrationDetails;
      state.savePasscode = initialState.savePasscode;
      state.resendPasscode = initialState.resendPasscode;
      state.loading = initialState.loading;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const getStudentGuardianRegistrationLoaderRedux = (state) =>
  state?.StudentGuardianRegistration?.loading || false;

export const {
  initiateUserRegistrationSuccessful,
  verifyUserRegistrationOTPSuccessful,
  resendRegistrationOTPSuccessful,
  saveUserRegistrationDetailsSuccessful,
  setUserPasscodeSuccessful,
  resetRegistration,
  registrationFailed,
  resetErrors,
  setLoader,
} = registerSlice.actions;

export default registerSlice.reducer;
