import { APIClient } from './api_helper';
import * as url from './url_helper';

const api = new APIClient();

// List Of Grades
export const listOfBooks = (gradeId, boardId, pageNo, pageSize = 10) => {
  let query = '';
  if (gradeId && boardId) {
    query = `?gradeId=${gradeId}&boardId=${boardId}`;
  }
  const data = { pageNo, recordSize: pageSize };
  return api.create(url.LIST_OF_BOOKS + query, data);
};
