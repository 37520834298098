import { getUserDetails } from 'slices/admin/auth/reducer';
import { APIClient } from './api_helper';
import * as url from './url_helper';
import { toUpper, snakeCase } from 'lodash';
import { store } from 'index';

const api = new APIClient();

export const prepareDropdownSchema = (
  data = [],
  value = '',
  label = '',
  labelUpperCase = false,
) => {
  if (data?.length > 0 && value && label) {
    return data
      ?.map((item) => {
        return {
          label: labelUpperCase ? item[label]?.toUpperCase() : item[label],
          value: item[value],
        };
      })
      .filter((item) => item);
  }

  return data;
};

export const prepareGSDropdownSchema = (data = []) => {
  if (data?.length > 0) {
    return data
      ?.map((item) => {
        let pincode = item?.formatted_address?.match(/\d{6}/);
        return {
          label: `${item?.name} (${pincode})`,
          value: item?.place_id,
        };
      })
      .filter((item) => item);
  }

  return data;
};

export const getDayGreetings = () => {
  const date = new Date();
  const currentTime = date.getHours();

  let greeting;

  if (currentTime >= 0 && currentTime <= 12) {
    greeting = 'Good Morning';
  } else if (currentTime > 12 && currentTime <= 18) {
    greeting = 'Good Afternoon';
  } else {
    greeting = 'Good Evening';
  }

  return greeting;
};

export const prepareDemoBatchDropdownSchema = (demoBatchTimeArray = []) => {
  if (demoBatchTimeArray?.length > 0) {
    return demoBatchTimeArray
      ?.map((item) => {
        const optionItem = `${item?.startTime}${item?.startAmPm} - ${item?.endTime}${item?.endAmPm}`;
        return {
          label: optionItem,
          value: optionItem,
        };
      })
      .filter((item) => item);
  }
};

export const prepareAuthHeaderInfo = (loginState = null) => {
  if (loginState) {
    const accessToken = loginState?.loginAccountToken || '';
    if (accessToken) {
      const payload = { headers: { Authorization: `Bearer ${accessToken}` } };
      return payload;
    }
  }

  return '';
};

export const hideMiddleChars = (
  string = '',
  prefixLength = 2,
  suffixLength = 3,
) => {
  if (string && string !== '') {
    string = string?.toString();
    return (
      string?.slice(0, prefixLength) +
      string?.slice(suffixLength)?.replace(/.(?=...)/g, '*')
    );
  }

  return '';
};

export const isNumeric = (str = '') => {
  if (typeof str != 'string') return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};

export const getHeadersPayload = (accessToken = '') => {
  if (accessToken) {
    return { headers: { Authorization: `Bearer ${accessToken}` } };
  }

  return null;
};

export const getHeadersForFilePayload = () => {
  return { headers: { 'Content-Type': 'multipart/form-data' } };
};

export const getAuthenticatedHeadersForFilePayload = (accessToken = '') => {
  if (accessToken) {
    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    };
  }
  return null;
};

export const capitalizeFirstLetter = (string = '') => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const truncateString = (source, size = 20) => {
  return source?.length > size ? source?.slice(0, size - 1) + '…' : source;
};

export const acceptedOnlyNumbers = (event = '') => {
  if (!/[0-9]/.test(event.key)) {
    return event.preventDefault();
  }
};

export const acceptedOnlyAlphabets = (event = '') => {
  if (!/^[a-zA-Z ]+$/.test(event.key)) {
    return event.preventDefault();
  }
};

export const checkAdminPath = () => {
  try {
    const adminDetails = getUserDetails(store.getState());
    return {
      status: true,
      data: adminDetails,
    };
  } catch (e) {
    return {
      status: false,
      data: null,
    };
  }
};

export const getClassRunningStatus = (
  classRunningDate = null,
  classStartTime = null,
  classEndTime = null,
) => {
  let classStatus = null;
  try {
    if (classRunningDate && classStartTime && classEndTime) {
      const currentDate = new Date();
      const classDate = classRunningDate && new Date(classRunningDate);
      let startDateTime,
        endDateTime = null;
      if (
        classDate &&
        currentDate.getFullYear() === classDate.getFullYear() &&
        currentDate.getMonth() === classDate.getMonth() &&
        currentDate.getDate() === classDate.getDate()
      ) {
        startDateTime = new Date();

        let startMatch = classStartTime?.match(/(\d+):(\d+)([APMapm]{2})/);
        if (startMatch && startMatch.length === 4) {
          let [startHours, startMinutes, startPeriod] = startMatch
            .slice(1)
            .map((str, index) =>
              index === 2 ? str?.toUpperCase() : parseInt(str, 10),
            );
          if (startPeriod === 'PM') {
            startHours += 12;
          }
          startDateTime?.setHours(startHours, startMinutes, 0, 0);
        }

        endDateTime = new Date();
        let endMatch = classEndTime?.match(/(\d+):(\d+)([APMapm]{2})/);
        if (endMatch && endMatch.length === 4) {
          let [endHours, endMinutes, endPeriod] = endMatch
            .slice(1)
            .map((str, index) =>
              index === 2 ? str?.toUpperCase() : parseInt(str, 10),
            );
          if (endPeriod === 'PM') {
            endHours += 12;
          }
          endDateTime?.setHours(endHours, endMinutes, 0, 0);
        }

        if (currentDate < startDateTime) {
          classStatus = 'notStarted';
        } else if (currentDate >= startDateTime && currentDate <= endDateTime) {
          classStatus = 'ongoing';
        } else {
          classStatus = 'finished';
        }
      } else {
        const daysRemaining = Math.floor(
          (classDate - currentDate) / (24 * 60 * 60 * 1000),
        );

        if (daysRemaining >= 2 || currentDate < classDate) {
          classStatus = 'notStarted';
        } else {
          classStatus = 'finished';
        }
      }
    }

    return classStatus;
  } catch (e) {
    return classStatus;
  }
};

export const randomPictureName = () => {
  const d = new Date();
  let time = d.getTime();

  return `${time}.jpeg`;
};

export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const loadScript = (url = null, id = null) => {
  let script = document.createElement('script');
  script.type = 'module';
  script.id = id || '';

  if (script.readyState) {
    //IE
    script.onreadystatechange = function () {
      if (script.readyState == 'loaded' || script.readyState == 'complete') {
        script.onreadystatechange = null;
        console.log('#########loadedScripttt');
        return true;
      }
    };
  } else {
    //Others
    script.onload = function () {
      console.log('##################window', window);
      console.log('#########loadedScript');
      return true;
    };
  }

  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
};

export const epicSearchService = (queryParams = {}) => {
  return api.get(url.EPIC_SEARCH, { params: queryParams });
};
export const sanitySearchService = (queryParams = {}) => {
  return api.get(url.EPIC_SEARCH + 'sanity', { params: queryParams });
};

export const getFormattedRoleByName = (roleName) => {
  return toUpper(snakeCase(roleName));
};

export const isTokenExpired = (token) =>
  Date.now() >= JSON.parse(atob(token.split('.')[1])).exp * 1000;

export const uploadToS3 = (studentId, data, headerInfo) => {
  return api.create(
    `${url.UPLOAD_TO_S3}/${studentId}/enrollmentAssets`,
    data,
    headerInfo,
  );
};
