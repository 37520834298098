import {
  initiateForgotPasscodeSuccess,
  verifyForgotPasscodeOtpSuccess,
  createNewPasscodeSuccess,
  forgotPasscodeError,
  resetForgotPasscodeStore,
  setLoader,
} from './reducer';
import {
  initiateForgotPasscodeService,
  verifyForgotPasscodeOtpService,
  createNewPasscodeService,
} from 'helpers/auth';

export const initiateForgotPasscode = (payload) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    let data = await initiateForgotPasscodeService(payload);
    if (data?.status === 200) {
      dispatch(initiateForgotPasscodeSuccess(data));
    } else {
      data = { ...data, methodName: 'initiateForgotPasscode' };
      dispatch(forgotPasscodeError(data));
    }
  } catch (e) {
    const error = { ...e, methodName: 'initiateForgotPasscode' };
    dispatch(forgotPasscodeError(error));
  }
};

export const verifyForgotPasscodeOtp = (payload) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    let data = await verifyForgotPasscodeOtpService(payload);
    if (data?.status === 200) {
      dispatch(verifyForgotPasscodeOtpSuccess(data));
    } else {
      data = { ...data, methodName: 'verifyForgotPasscode' };
      dispatch(forgotPasscodeError(data));
    }
  } catch (e) {
    const error = { ...e, methodName: 'verifyForgotPasscode' };
    dispatch(forgotPasscodeError(error));
  }
};

export const createNewPasscode =
  (payload, verifyOtpInfo = {}) =>
  async (dispatch) => {
    try {
      let headersObj;
      if (verifyOtpInfo?.data?.tempToken) {
        headersObj = {
          headers: {
            Authorization: `Bearer ${verifyOtpInfo?.data?.tempToken}`,
          },
        };
      }

      dispatch(setLoader(true));
      let data = await createNewPasscodeService(payload, headersObj);
      if (data?.status === 200) {
        dispatch(createNewPasscodeSuccess(data));
      } else {
        data = { ...data, methodName: 'resetNewPasscode' };
        dispatch(forgotPasscodeError(data));
      }
    } catch (e) {
      const error = { ...e, methodName: 'resetNewPasscode' };
      dispatch(forgotPasscodeError(error));
    }
  };

export const resetForgotPasscodeFlag = () => async (dispatch) => {
  try {
    const response = dispatch(resetForgotPasscodeStore());
    return response;
  } catch (error) {
    dispatch(forgotPasscodeError(error));
  }
};
