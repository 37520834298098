import { listOfTutorService } from 'helpers/tutors';
import { createTutor, updateTutor } from 'helpers/tutors';
import { setTutorsList, setTotalRecords } from './reducer';

import { setAddOrUpdateLoader, setLoader } from '../user/reducer';

export const getTutorsData = (id, history) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const { data, status } = await listOfTutorService(id);
    if (status === 200) {
      await dispatch(setTutorsList(data));
      dispatch(setTotalRecords(data.totalRecords));
    }
  } catch (error) {
    console.error('Error fetching tutor data:', error);
  } finally {
    dispatch(setLoader(false));
  }
};

export const createTutorService =
  (data, headerInfo, history) => async (dispatch) => {
    try {
      dispatch(setAddOrUpdateLoader(true));
      await createTutor(data, headerInfo);
      dispatch(setAddOrUpdateLoader(false));
    } catch (error) {
      dispatch(setAddOrUpdateLoader(false));
    }
  };

export const updateTutorServie =
  (data, headerInfo, history) => async (dispatch) => {
    try {
      dispatch(setAddOrUpdateLoader(true));
      await updateTutor(data, headerInfo);
      dispatch(setAddOrUpdateLoader(false));
    } catch (error) {
      dispatch(setAddOrUpdateLoader(false));
    }
  };
