import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  topics: [],
  totalRecords: 0,
  loading: false,
};

const adminTopicSlice = createSlice({
  name: 'topicsList',
  initialState,
  reducers: {
    setTopicsList: (state, action) => {
      state.topics = action.payload;
      state.loading = initialState.loading;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
    setTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
  },
});

export const getTopicsListRedux = (state) => state.AdminTopic.topics;
export const getTopicsListLoaderRedux = (state) => state.AdminTopic.loading;
export const getTotalRecordsRedux = (state) => state.AdminTopic.totalRecords;

export const { setTopicsList, setLoader, setTotalRecords } =
  adminTopicSlice.actions;

export default adminTopicSlice.reducer;
