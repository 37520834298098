import React from 'react';
import { Table } from 'reactstrap';
import { Question } from 'Components/Common/Question';
import '../generateReport.css';

const NotebookDetails = ({ studentReportData, notebookEvalDetails }) => {
  return (
    <>
      {/* Notebook Evaluation Details */}
      <section className="notebook-section">
        <h2 className="notebook-title">
          {`Chapter: ${studentReportData?.chapterName}`}
        </h2>
        <h4 className="notebook-subtitle">
          Notebook Evaluation Detailed Report
        </h4>
        <Table bordered responsive>
          <thead className="notebook-table-header">
            <tr>
              <th>Question Family</th>
              <th>Family Description</th>
              <th>Question 1</th>
              <th>Question 2</th>
              <th>Question 3</th>
              <th>Question 4</th>
              <th>Question 5</th>
            </tr>
          </thead>
          <tbody>
            {notebookEvalDetails?.map((notebookEvalDetail, idx) => (
              <React.Fragment key={idx}>
                {notebookEvalDetail.map((nb, index) => {
                  const commonCells = Array(5)
                    .fill()
                    .map((_, i) => (
                      <td
                        key={`q${i}`}
                        className={`text-center ${
                          index === 2 && nb[`Question ${i + 1}`] === 'correct'
                            ? 'bg-success text-white'
                            : ''
                        } ${
                          index === 1
                            ? 'notebook-small-width'
                            : 'notebook-large-width'
                        }`}
                      >
                        {index === 0 ? (
                          nb[`Question ${i + 1}`] ? (
                            <Question content={nb[`Question ${i + 1}`]} />
                          ) : (
                            '-'
                          )
                        ) : (
                          nb[`Question ${i + 1}`] || '-'
                        )}
                      </td>
                    ));

                  return (
                    <tr key={index}>
                      <td className="text-center">{nb['Question Family']}</td>
                      <td className="text-center notebook-large-width">
                        {nb['Family Description']}
                      </td>
                      {commonCells}
                    </tr>
                  );
                })}
                <div className="page-break"></div>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </section>
    </>
  );
};

export default NotebookDetails;
