import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  dashboardAnalytics: [],
  loading: false,
  curriculumData: [],
};

const dashboardAnalyticsSlice = createSlice({
  name: 'dashboardAnalytics',
  initialState,
  reducers: {
    setDashboardAnalytics: (state, action) => {
      state.dashboardAnalytics = action.payload;
      state.loading = initialState.loading;
    },
    setCurriculumData: (state, action) => {
      state.curriculumData = action.payload;
      state.loading = initialState.loading;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const getDashboardAnalytics = (state) =>
  state.AdminDashboard.dashboardAnalytics;
export const getDashboardAnalyticsLoader = (state) =>
  state.AdminDashboard.loading;

export const getCurriculumDashboardData = (state) =>
  state.AdminDashboard.curriculumData;

export const { setDashboardAnalytics, setLoader, setCurriculumData } =
  dashboardAnalyticsSlice.actions;

export default dashboardAnalyticsSlice.reducer;
