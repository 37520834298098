import { qcGapReport, sendReport } from 'helpers/report';
import { errorToastify, successToastify } from 'helpers/toast';
import { useState } from 'react';
import { Button, Spinner, Table, Row, Col } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import './reports.css';

export const Reports = () => {
  const [loading, setLoading] = useState({});
  const [loadingQcReport, setLoadingQcReport] = useState({});
  const [dateRanges, setDateRanges] = useState({});

  const handleSendReport = async (reportType) => {
    try {
      const payload = {
        reportType,
        dateRanges,
      };
      setLoading((prev) => {
        return { ...prev, [`${reportType}`]: true };
      });
      const response = await sendReport(payload);
      if (response?.status === 200) {
        successToastify('Report sent successfully');
      } else {
        errorToastify('Failed to send report');
      }
    } catch (error) {
      errorToastify('Failed to send report');
    } finally {
      setLoading((prev) => {
        return { ...prev, [`${reportType}`]: false };
      });
    }
  };

  const handleQuestionCategoryReport = async (reportType) => {
    try {
      setLoadingQcReport((prev) => {
        return { ...prev, [reportType]: true };
      });
      const response = await qcGapReport(reportType);
      if (response?.status === 200) {
        successToastify('Report sent successfully');
      } else {
        errorToastify('Failed to send report');
      }
    } catch (error) {
      errorToastify('Failed to send report');
    } finally {
      setLoadingQcReport((prev) => {
        return { ...prev, [reportType]: false };
      });
    }
  };

  const reports = [
    {
      type: 'Demo Class + Evaluation Notes + Evaluation Answers + Practice Sheet + Student Evaluations + QCs + QFs',
      value: 'cumulative_report',
      status: 'active',
    },
    {
      type: 'Class Config',
      value: 'class_config',
      status: 'active',
    },
    {
      type: 'Question Family',
      value: 'lecture_qf',
      status: 'active',
    },
    {
      type: 'Classes',
      value: 'classes',
      status: 'active',
    },
    {
      type: 'Raw Answer',
      value: 'raw_answer',
      status: 'active',
    },
    { type: 'Past Class Details', value: 'class_activities', status: 'active' },
    { type: 'Feedback', value: 'feedback', status: 'active' },
    {
      type: 'Element & Evaluation Timer',
      value: 'element_evaluation_timer',
      status: 'active',
    },
    {
      type: 'Past Class Tracker',
      value: 'past_classes',
      status: 'active',
    },
    {
      type: 'Subscriptions',
      value: 'subscriptions',
      status: 'active',
    },
    {
      type: 'Post Class',
      value: 'post_class_summaries',
      status: 'active',
    },
    {
      type: 'Evaluation Status',
      value: 'evaluation_status',
      status: 'active',
    },
  ];

  const questionCategoryReports = [
    {
      type: 'Student Level',
      value: 'student-level',
      status: 'active',
    },
    {
      type: 'Batch Level',
      value: 'batch-level',
      status: 'active',
    },
    {
      type: 'Chapter Level',
      value: 'chapter-level',
      status: 'active',
    },
  ];

  const handleDateChange = (dates, reportType) => {
    const [start, end] = dates;
    setDateRanges({
      ...dateRanges,
      [reportType]: { start, end },
    });
  };

  return (
    <div className="page-content">
      <Row>
        <Col>
          <h2 className="header">Reports</h2>
          <Table bordered className="table-report table-responsive-reports">
            <thead className="thead-light">
              <tr className="h5">
                <th>Report Type</th>
                <th>Date Range</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {reports?.map((report, index) => (
                <tr key={index}>
                  <td className="text-capitalize text-start h6">
                    {report?.type}
                  </td>
                  <td style={{ width: '400px' }}>
                    {/* {report?.status === 'active' ? (
                      <div className="date-picker-wrapper">
                        <DatePicker
                          selected={dateRanges[report?.type]?.start}
                          onChange={(dates) =>
                            handleDateChange(dates, report.value)
                          }
                          startDate={dateRanges[report?.type]?.start}
                          endDate={dateRanges[report?.type]?.end}
                          selectsRange
                          isClearable
                          placeholderText="Select date range"
                          className="form-control"
                          dateFormat="yyyy/MM/dd"
                        />
                      </div>
                    ) : (
                      <span className="badge bg-secondary">Coming soon</span>
                    )} */}
                    <Button
                      size="sm"
                      className="btn btn-secondary"
                      disabled
                      style={{
                        cursor: 'not-allowed',
                        padding: '.375rem 1.5rem',
                      }}
                    >
                      Coming soon
                    </Button>
                  </td>
                  <td className="text-center h6">
                    {report.status === 'active' ? (
                      <Button
                        key={`${report?.value}+${index}`}
                        size="sm"
                        color="primary"
                        onClick={() => handleSendReport(report?.value)}
                        disabled={loading[`${report.value}`]}
                        className="px-5"
                        style={{ cursor: 'pointer' }}
                      >
                        Export{' '}
                        {loading[`${report?.value}`] === true && (
                          <Spinner size="sm" />
                        )}
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        color="secondary"
                        disabled
                        style={{
                          cursor: 'not-allowed',
                          padding: '.375rem 1.5rem',
                        }}
                      >
                        Coming soon
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h2 className="header">Question Category Gap Reports</h2>
          <Table bordered className="table-report table-responsive-reports">
            <thead className="thead-light">
              <tr className="h5">
                <th>Report Type</th>
                <th>Date Range</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {questionCategoryReports.map((report, index) => (
                <tr key={index}>
                  <td className="text-capitalize text-start h6">
                    {report.type}
                  </td>
                  <td style={{ width: '400px' }}>
                    {report.status === 'coming_soon' ? (
                      <Button
                        size="sm"
                        className="btn btn-secondary"
                        disabled
                        style={{
                          cursor: 'not-allowed',
                          padding: '.375rem 1.5rem',
                        }}
                      >
                        Coming soon
                      </Button>
                    ) : (
                      <span>-</span>
                    )}
                  </td>
                  <td className="text-center h6">
                    {report.status === 'active' ? (
                      <Button
                        key={`${report.type}-${index}`}
                        size="sm"
                        color="primary"
                        onClick={() =>
                          handleQuestionCategoryReport(report.value)
                        }
                        disabled={loadingQcReport[report.value]}
                        className="px-5"
                        style={{ cursor: 'pointer' }}
                      >
                        Export{' '}
                        {loadingQcReport[report.value] && <Spinner size="sm" />}
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        color="secondary"
                        disabled
                        style={{
                          cursor: 'not-allowed',
                          padding: '.375rem 1.5rem',
                        }}
                      >
                        Coming soon
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};
