import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  items: [],
  totalRecords: 0,
  loading: false,
};

const adminItemSlice = createSlice({
  name: 'itemList',
  initialState,
  reducers: {
    setItemsList: (state, action) => {
      state.items = action.payload;
      state.loading = initialState.loading;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
    setTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
  },
});

export const getItemsListRedux = (state) => state.AdminItem.items;
export const getItemsListLoaderRedux = (state) => state.AdminItem.loading;
export const getTotalRecordsRedux = (state) => state.AdminItem.totalRecords;
export const { setItemsList, setLoader, setTotalRecords } =
  adminItemSlice.actions;

export default adminItemSlice.reducer;
