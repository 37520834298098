import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  subtopics: [],
  totalRecords: 0,
  loading: false,
};

const adminSubtopicSlice = createSlice({
  name: 'subtopicsList',
  initialState,
  reducers: {
    setSubtopicsList: (state, action) => {
      state.subtopics = action.payload;
      state.loading = initialState.loading;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
    setTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
  },
});

export const getSubtopicsListRedux = (state) => state.AdminSubtopic.subtopics;
export const getSubtopicsListLoaderRedux = (state) =>
  state.AdminSubtopic.loading;
export const getTotalRecordsRedux = (state) => state.AdminSubtopic.totalRecords;
export const { setSubtopicsList, setLoader, setTotalRecords } =
  adminSubtopicSlice.actions;

export default adminSubtopicSlice.reducer;
