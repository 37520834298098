import ac from '../../assets/images/flags/ac.svg';
import ad from '../../assets/images/flags/ad.svg';
import ae from '../../assets/images/flags/ae.svg';
import af from '../../assets/images/flags/af.svg';
import ag from '../../assets/images/flags/ag.svg';
import ai from '../../assets/images/flags/ai.svg';
import am from '../../assets/images/flags/am.svg';
import ao from '../../assets/images/flags/ao.svg';
import aq from '../../assets/images/flags/aq.svg';
import ar from '../../assets/images/flags/ar.svg';
import as from '../../assets/images/flags/as.svg';
import at from '../../assets/images/flags/at.svg';
import au from '../../assets/images/flags/au.svg';
import aw from '../../assets/images/flags/aw.svg';
import ax from '../../assets/images/flags/ax.svg';
import ba from '../../assets/images/flags/ba.svg';
import bb from '../../assets/images/flags/bb.svg';
import bd from '../../assets/images/flags/bd.svg';
import be from '../../assets/images/flags/be.svg';
import bf from '../../assets/images/flags/bf.svg';
import bh from '../../assets/images/flags/bh.svg';
import bi from '../../assets/images/flags/bi.svg';
import bj from '../../assets/images/flags/bj.svg';
import bl from '../../assets/images/flags/bl.svg';
import bm from '../../assets/images/flags/bm.svg';
import bn from '../../assets/images/flags/bn.svg';
import bo from '../../assets/images/flags/bo.svg';
import bq from '../../assets/images/flags/bq.svg';
import br from '../../assets/images/flags/br.svg';
import bs from '../../assets/images/flags/bs.svg';
import bt from '../../assets/images/flags/bt.svg';
import bv from '../../assets/images/flags/bv.svg';
import bw from '../../assets/images/flags/bw.svg';
import by from '../../assets/images/flags/by.svg';
import bz from '../../assets/images/flags/bz.svg';
import ca from '../../assets/images/flags/ca.svg';
import cc from '../../assets/images/flags/cc.svg';
import cd from '../../assets/images/flags/cd.svg';
import cf from '../../assets/images/flags/cf.svg';
import cg from '../../assets/images/flags/cg.svg';
import ch from '../../assets/images/flags/ch.svg';
import ci from '../../assets/images/flags/ci.svg';
import ck from '../../assets/images/flags/ck.svg';
import cl from '../../assets/images/flags/cl.svg';
import cm from '../../assets/images/flags/cm.svg';
import cn from '../../assets/images/flags/cn.svg';
import co from '../../assets/images/flags/co.svg';
import cp from '../../assets/images/flags/cp.svg';
import cr from '../../assets/images/flags/cr.svg';
import cu from '../../assets/images/flags/cu.svg';
import cv from '../../assets/images/flags/cv.svg';
import cw from '../../assets/images/flags/cw.svg';
import cx from '../../assets/images/flags/cx.svg';
import cy from '../../assets/images/flags/cy.svg';
import cz from '../../assets/images/flags/cz.svg';
import de from '../../assets/images/flags/de.svg';
import dg from '../../assets/images/flags/dg.svg';
import dj from '../../assets/images/flags/dj.svg';
import dk from '../../assets/images/flags/dk.svg';
import dm from '../../assets/images/flags/dm.svg';
import doi from '../../assets/images/flags/do.svg';
import dz from '../../assets/images/flags/dz.svg';
import ea from '../../assets/images/flags/ea.svg';
import ec from '../../assets/images/flags/ec.svg';
import ee from '../../assets/images/flags/ee.svg';
import eg from '../../assets/images/flags/eg.svg';
import eh from '../../assets/images/flags/eh.svg';
import er from '../../assets/images/flags/er.svg';
import es from '../../assets/images/flags/es.svg';
import esct from '../../assets/images/flags/es-ct.svg';
import esga from '../../assets/images/flags/es-ga.svg';
import et from '../../assets/images/flags/et.svg';
import eu from '../../assets/images/flags/eu.svg';
import fi from '../../assets/images/flags/fi.svg';
import fj from '../../assets/images/flags/fj.svg';
import fk from '../../assets/images/flags/fk.svg';
import fm from '../../assets/images/flags/fm.svg';
import fo from '../../assets/images/flags/fo.svg';
import fr from '../../assets/images/flags/fr.svg';
import ga from '../../assets/images/flags/ga.svg';
import gbeng from '../../assets/images/flags/gb-eng.svg';
import gbnir from '../../assets/images/flags/gb-nir.svg';
import gbsct from '../../assets/images/flags/gb-sct.svg';
import gbwls from '../../assets/images/flags/gb-wls.svg';
import gd from '../../assets/images/flags/gd.svg';
import ge from '../../assets/images/flags/ge.svg';
import gf from '../../assets/images/flags/gf.svg';
import gg from '../../assets/images/flags/gg.svg';
import gh from '../../assets/images/flags/gh.svg';
import gi from '../../assets/images/flags/gi.svg';
import gl from '../../assets/images/flags/gl.svg';
import gm from '../../assets/images/flags/gm.svg';
import gn from '../../assets/images/flags/gn.svg';
import gp from '../../assets/images/flags/gp.svg';
import gq from '../../assets/images/flags/gq.svg';
import gr from '../../assets/images/flags/gr.svg';
import gs from '../../assets/images/flags/gs.svg';
import gt from '../../assets/images/flags/gt.svg';
import gu from '../../assets/images/flags/gu.svg';
import gw from '../../assets/images/flags/gw.svg';
import gy from '../../assets/images/flags/gy.svg';
import hk from '../../assets/images/flags/hk.svg';
import hn from '../../assets/images/flags/hn.svg';
import hr from '../../assets/images/flags/hr.svg';
import ht from '../../assets/images/flags/ht.svg';
import hu from '../../assets/images/flags/hu.svg';
import id from '../../assets/images/flags/id.svg';
import ie from '../../assets/images/flags/ie.svg';
import il from '../../assets/images/flags/il.svg';
import im from '../../assets/images/flags/im.svg';
import ain from '../../assets/images/flags/in.svg';
import io from '../../assets/images/flags/io.svg';
import iq from '../../assets/images/flags/iq.svg';
import ir from '../../assets/images/flags/ir.svg';
import is from '../../assets/images/flags/is.svg';
import it from '../../assets/images/flags/it.svg';
import je from '../../assets/images/flags/je.svg';
import jm from '../../assets/images/flags/jm.svg';
import jo from '../../assets/images/flags/jo.svg';
import jp from '../../assets/images/flags/jp.svg';
import ke from '../../assets/images/flags/ke.svg';
import kg from '../../assets/images/flags/kg.svg';
import kh from '../../assets/images/flags/kh.svg';
import ki from '../../assets/images/flags/ki.svg';
import km from '../../assets/images/flags/km.svg';
import kn from '../../assets/images/flags/kn.svg';
import kp from '../../assets/images/flags/kp.svg';
import kr from '../../assets/images/flags/kr.svg';
import kw from '../../assets/images/flags/kw.svg';
import ky from '../../assets/images/flags/ky.svg';
import kz from '../../assets/images/flags/kz.svg';
import la from '../../assets/images/flags/la.svg';
import lb from '../../assets/images/flags/lb.svg';
import lc from '../../assets/images/flags/lc.svg';
import li from '../../assets/images/flags/li.svg';
import lk from '../../assets/images/flags/lk.svg';
import lr from '../../assets/images/flags/lr.svg';
import ls from '../../assets/images/flags/ls.svg';
import lt from '../../assets/images/flags/lt.svg';
import lu from '../../assets/images/flags/lu.svg';
import lv from '../../assets/images/flags/lv.svg';
import ly from '../../assets/images/flags/ly.svg';
import ma from '../../assets/images/flags/ma.svg';
import mc from '../../assets/images/flags/mc.svg';
import md from '../../assets/images/flags/md.svg';
import me from '../../assets/images/flags/me.svg';
import mf from '../../assets/images/flags/mf.svg';
import mg from '../../assets/images/flags/mg.svg';
import mh from '../../assets/images/flags/mh.svg';
import mk from '../../assets/images/flags/mk.svg';
import ml from '../../assets/images/flags/ml.svg';
import mm from '../../assets/images/flags/mm.svg';
import mn from '../../assets/images/flags/mn.svg';
import mo from '../../assets/images/flags/mo.svg';
import mp from '../../assets/images/flags/mp.svg';
import mq from '../../assets/images/flags/mq.svg';
import mr from '../../assets/images/flags/mr.svg';
import ms from '../../assets/images/flags/ms.svg';
import mt from '../../assets/images/flags/mt.svg';
import mu from '../../assets/images/flags/mu.svg';
import mv from '../../assets/images/flags/mv.svg';
import mw from '../../assets/images/flags/mw.svg';
import mx from '../../assets/images/flags/mx.svg';
import my from '../../assets/images/flags/my.svg';
import mz from '../../assets/images/flags/mz.svg';
import na from '../../assets/images/flags/na.svg';
import nc from '../../assets/images/flags/nc.svg';
import ne from '../../assets/images/flags/ne.svg';
import nf from '../../assets/images/flags/nf.svg';
import ng from '../../assets/images/flags/ng.svg';
import ni from '../../assets/images/flags/ni.svg';
import nl from '../../assets/images/flags/nl.svg';
import no from '../../assets/images/flags/no.svg';
import np from '../../assets/images/flags/np.svg';
import nr from '../../assets/images/flags/nr.svg';
import nu from '../../assets/images/flags/nu.svg';
import nz from '../../assets/images/flags/nz.svg';
import om from '../../assets/images/flags/om.svg';
import pa from '../../assets/images/flags/pa.svg';
import pe from '../../assets/images/flags/pe.svg';
import pf from '../../assets/images/flags/pf.svg';
import pg from '../../assets/images/flags/pg.svg';
import ph from '../../assets/images/flags/ph.svg';
import pk from '../../assets/images/flags/pk.svg';
import pl from '../../assets/images/flags/pl.svg';
import pm from '../../assets/images/flags/pm.svg';
import pn from '../../assets/images/flags/pn.svg';
import pr from '../../assets/images/flags/pr.svg';
import ps from '../../assets/images/flags/ps.svg';
import pt from '../../assets/images/flags/pt.svg';
import pw from '../../assets/images/flags/pw.svg';
import py from '../../assets/images/flags/py.svg';
import qa from '../../assets/images/flags/qa.svg';
import re from '../../assets/images/flags/re.svg';
import ro from '../../assets/images/flags/ro.svg';
import rs from '../../assets/images/flags/rs.svg';
import ru from '../../assets/images/flags/ru.svg';
import rw from '../../assets/images/flags/rw.svg';
import sa from '../../assets/images/flags/sa.svg';
import sb from '../../assets/images/flags/sb.svg';
import sc from '../../assets/images/flags/sc.svg';
import sd from '../../assets/images/flags/sd.svg';
import se from '../../assets/images/flags/se.svg';
import sg from '../../assets/images/flags/sg.svg';
import si from '../../assets/images/flags/si.svg';
import sj from '../../assets/images/flags/sj.svg';
import sk from '../../assets/images/flags/sk.svg';
import sl from '../../assets/images/flags/sl.svg';
import sm from '../../assets/images/flags/sm.svg';
import sn from '../../assets/images/flags/sn.svg';
import so from '../../assets/images/flags/so.svg';
import sr from '../../assets/images/flags/sr.svg';
import ss from '../../assets/images/flags/ss.svg';
import st from '../../assets/images/flags/st.svg';
import sv from '../../assets/images/flags/sv.svg';
import sx from '../../assets/images/flags/sx.svg';
import sy from '../../assets/images/flags/sy.svg';
import sz from '../../assets/images/flags/sz.svg';
import ta from '../../assets/images/flags/ta.svg';
import tc from '../../assets/images/flags/tc.svg';
import td from '../../assets/images/flags/td.svg';
import tg from '../../assets/images/flags/tg.svg';
import th from '../../assets/images/flags/th.svg';
import tj from '../../assets/images/flags/tj.svg';
import tk from '../../assets/images/flags/tk.svg';
import tl from '../../assets/images/flags/tl.svg';
import tm from '../../assets/images/flags/tm.svg';
import tn from '../../assets/images/flags/tn.svg';
import to from '../../assets/images/flags/to.svg';
import tr from '../../assets/images/flags/tr.svg';
import tt from '../../assets/images/flags/tt.svg';
import tv from '../../assets/images/flags/tv.svg';
import tw from '../../assets/images/flags/tw.svg';
import tz from '../../assets/images/flags/tz.svg';
import ua from '../../assets/images/flags/ua.svg';
import ug from '../../assets/images/flags/ug.svg';
import us from '../../assets/images/flags/us.svg';
import uy from '../../assets/images/flags/uy.svg';
import uz from '../../assets/images/flags/uz.svg';
import va from '../../assets/images/flags/va.svg';
import vc from '../../assets/images/flags/vc.svg';
import ve from '../../assets/images/flags/ve.svg';
import vg from '../../assets/images/flags/vg.svg';
import vi from '../../assets/images/flags/vi.svg';
import vn from '../../assets/images/flags/vn.svg';
import vu from '../../assets/images/flags/vu.svg';
import wf from '../../assets/images/flags/wf.svg';
import ws from '../../assets/images/flags/ws.svg';
import xk from '../../assets/images/flags/xk.svg';
import ye from '../../assets/images/flags/ye.svg';
import yt from '../../assets/images/flags/yt.svg';
import za from '../../assets/images/flags/za.svg';
import zm from '../../assets/images/flags/zm.svg';
import zw from '../../assets/images/flags/zw.svg';

const country = [
  {
    id: 1,
    flagImg: ac,
    countryName: 'Ascension Island',
    countryCode: '+247',
  },
  {
    id: 2,
    flagImg: ad,
    countryName: 'Andorra',
    countryCode: '+376',
  },
  {
    id: 3,
    flagImg: ae,
    countryName: 'United Arab Emirates',
    countryCode: '+971',
  },
  {
    id: 4,
    flagImg: af,
    countryName: 'Afghanistan',
    countryCode: '+93',
  },
  {
    id: 5,
    flagImg: ag,
    countryName: 'Antigua and Barbuda',
    countryCode: '+93',
  },
  {
    id: 6,
    flagImg: ai,
    countryName: 'Anguilla',
    countryCode: '+1',
  },
  {
    id: 7,
    flagImg: am,
    countryName: 'Armenia',
    countryCode: '+374',
  },
  {
    id: 8,
    flagImg: ao,
    countryName: 'Angola',
    countryCode: '+244',
  },
  {
    id: 9,
    flagImg: aq,
    countryName: 'Antarctica',
    countryCode: '+672',
  },
  {
    id: 10,
    flagImg: ar,
    countryName: 'Argentina',
    countryCode: '+54',
  },
  {
    id: 11,
    flagImg: as,
    countryName: 'American Samoa',
    countryCode: '+1',
  },
  {
    id: 12,
    flagImg: at,
    countryName: 'Austria',
    countryCode: '+43',
  },
  {
    id: 13,
    flagImg: au,
    countryName: 'Australia',
    countryCode: '+61',
  },
  {
    id: 14,
    flagImg: aw,
    countryName: 'Aruba',
    countryCode: '+297',
  },
  {
    id: 15,
    flagImg: ax,
    countryName: 'Aland Islands',
    countryCode: '+358',
  },
  {
    id: 16,
    flagImg: ba,
    countryName: 'Bosnia and Herzegovina',
    countryCode: '+387',
  },
  {
    id: 17,
    flagImg: bb,
    countryName: 'Barbados',
    countryCode: '+1',
  },
  {
    id: 18,
    flagImg: bd,
    countryName: 'Bangladesh',
    countryCode: '+880',
  },
  {
    id: 19,
    flagImg: be,
    countryName: 'Belgium',
    countryCode: '+32',
  },
  {
    id: 20,
    flagImg: bf,
    countryName: 'Burkina Faso',
    countryCode: '+226',
  },
  {
    id: 21,
    flagImg: bf,
    countryName: 'Bulgaria',
    countryCode: '+359',
  },
  {
    id: 22,
    flagImg: bh,
    countryName: 'Bahrain',
    countryCode: '+973',
  },
  {
    id: 23,
    flagImg: bi,
    countryName: 'Burundi',
    countryCode: '+257',
  },
  {
    id: 24,
    flagImg: bj,
    countryName: 'Benin',
    countryCode: '+229',
  },
  {
    id: 25,
    flagImg: bl,
    countryName: 'Saint Barthélemy',
    countryCode: '+590',
  },
  {
    id: 26,
    flagImg: bm,
    countryName: 'Bermuda',
    countryCode: '+1',
  },
  {
    id: 27,
    flagImg: bn,
    countryName: 'Brunei Darussalam',
    countryCode: '+673',
  },
  {
    id: 28,
    flagImg: bo,
    countryName: 'Bolivia (Plurinational State of)',
    countryCode: '+591',
  },
  {
    id: 29,
    flagImg: bq,
    countryName: 'Bonaire, Sint Eustatius and Saba',
    countryCode: '+599',
  },
  {
    id: 30,
    flagImg: br,
    countryName: 'Brazil',
    countryCode: '+55',
  },
  {
    id: 31,
    flagImg: bs,
    countryName: 'Bahamas',
    countryCode: '+1',
  },
  {
    id: 32,
    flagImg: bt,
    countryName: 'Bhutan',
    countryCode: '+975',
  },
  {
    id: 33,
    flagImg: bv,
    countryName: 'Bouvet Island',
    countryCode: '+47',
  },
  {
    id: 34,
    flagImg: bw,
    countryName: 'Botswana',
    countryCode: '+267',
  },
  {
    id: 35,
    flagImg: by,
    countryName: 'Belarus',
    countryCode: '+375',
  },
  {
    id: 36,
    flagImg: bz,
    countryName: 'Belize',
    countryCode: '+501',
  },
  {
    id: 37,
    flagImg: ca,
    countryName: 'Canada',
    countryCode: '+1',
  },
  {
    id: 38,
    flagImg: cc,
    countryName: 'Cocos (Keeling) Island',
    countryCode: '+61',
  },
  {
    id: 39,
    flagImg: cd,
    countryName: 'Democratic Republic of the Congo',
    countryCode: '+243',
  },
  {
    id: 40,
    flagImg: cf,
    countryName: 'Central African Republic',
    countryCode: '+236',
  },
  {
    id: 41,
    flagImg: cg,
    countryName: 'Republic of the Congo',
    countryCode: '+243',
  },
  {
    id: 42,
    flagImg: ch,
    countryName: 'Switzerland',
    countryCode: '+41',
  },
  {
    id: 43,
    flagImg: ci,
    countryName: 'Ivory Coast',
    countryCode: '+225',
  },
  {
    id: 44,
    flagImg: ck,
    countryName: 'Cook Islands',
    countryCode: '+682',
  },
  {
    id: 45,
    flagImg: cl,
    countryName: 'Chile',
    countryCode: '+56',
  },
  {
    id: 46,
    flagImg: cm,
    countryName: 'Cameroon',
    countryCode: '+237',
  },
  {
    id: 47,
    flagImg: cn,
    countryName: 'China',
    countryCode: '+86',
  },
  {
    id: 48,
    flagImg: co,
    countryName: 'Colombia',
    countryCode: '+57',
  },
  {
    id: 49,
    flagImg: cp,
    countryName: 'Clipperton Island',
    countryCode: '+55',
  },
  {
    id: 50,
    flagImg: cr,
    countryName: 'Costa Rica',
    countryCode: '+506',
  },
  {
    id: 51,
    flagImg: cu,
    countryName: 'Cuba',
    countryCode: '+53',
  },
  {
    id: 52,
    flagImg: cv,
    countryName: 'Cabo Verde',
    countryCode: '+238',
  },
  {
    id: 53,
    flagImg: cw,
    countryName: 'Curaçao',
    countryCode: '+599',
  },
  {
    id: 54,
    flagImg: cx,
    countryName: 'Christmas Island',
    countryCode: '+61',
  },
  {
    id: 55,
    flagImg: cy,
    countryName: 'Cyprus',
    countryCode: '+357',
  },
  {
    id: 56,
    flagImg: cz,
    countryName: 'Czech Republic',
    countryCode: '+420',
  },
  {
    id: 57,
    flagImg: de,
    countryName: 'Germany',
    countryCode: '+49',
  },
  {
    id: 58,
    flagImg: dg,
    countryName: 'Diego Garcia',
    countryCode: '+246',
  },
  {
    id: 59,
    flagImg: dj,
    countryName: 'Djibouti',
    countryCode: '+253',
  },
  {
    id: 60,
    flagImg: dk,
    countryName: 'Denmark',
    countryCode: '+45',
  },
  {
    id: 61,
    flagImg: dm,
    countryName: 'Dominica',
    countryCode: '+1',
  },
  {
    id: 62,
    flagImg: doi,
    countryName: 'Dominican Republic',
    countryCode: '+1',
  },
  {
    id: 63,
    flagImg: dz,
    countryName: 'Algeria',
    countryCode: '+213',
  },
  {
    id: 64,
    flagImg: ea,
    countryName: 'Ceuta & Melilla',
    countryCode: '34',
  },
  {
    id: 65,
    flagImg: ec,
    countryName: 'Ecuador',
    countryCode: '+593',
  },
  {
    id: 66,
    flagImg: ee,
    countryName: 'Estonia',
    countryCode: '+372',
  },
  {
    id: 67,
    flagImg: eg,
    countryName: 'Egypt',
    countryCode: '+20',
  },
  {
    id: 68,
    flagImg: eh,
    countryName: 'Western Sahara',
    countryCode: '+212',
  },
  {
    id: 69,
    flagImg: er,
    countryName: 'Eritrea',
    countryCode: '+291',
  },
  {
    id: 70,
    flagImg: es,
    countryName: 'Spain',
    countryCode: '+34',
  },
  {
    id: 71,
    flagImg: esct,
    countryName: 'Catalonia',
    countryCode: '+34',
  },
  {
    id: 72,
    flagImg: esga,
    countryName: 'Galicia',
    countryCode: '+34',
  },
  {
    id: 73,
    flagImg: et,
    countryName: 'Ethiopia',
    countryCode: '+251',
  },
  {
    id: 74,
    flagImg: eu,
    countryName: 'Europe',
    countryCode: '+3',
  },
  {
    id: 75,
    flagImg: fi,
    countryName: 'Finland',
    countryCode: '+358',
  },
  {
    id: 76,
    flagImg: fj,
    countryName: 'Fiji',
    countryCode: '+679',
  },
  {
    id: 77,
    flagImg: fk,
    countryName: 'Falkland Islands',
    countryCode: '+500',
  },
  {
    id: 78,
    flagImg: fm,
    countryName: 'Federated States of Micronesia',
    countryCode: '+691',
  },
  {
    id: 79,
    flagImg: fo,
    countryName: 'Faroe Islands',
    countryCode: '+298',
  },
  {
    id: 80,
    flagImg: fr,
    countryName: 'France',
    countryCode: '+33',
  },
  {
    id: 81,
    flagImg: ga,
    countryName: 'Gabon',
    countryCode: '+241',
  },
  {
    id: 82,
    flagImg: gbeng,
    countryName: 'England',
    countryCode: '+44',
  },
  {
    id: 83,
    flagImg: gbnir,
    countryName: 'Northern Ireland',
    countryCode: '+44',
  },
  {
    id: 84,
    flagImg: gbsct,
    countryName: 'Scotland',
    countryCode: '+44',
  },
  {
    id: 85,
    flagImg: gbwls,
    countryName: 'Wales',
    countryCode: '+44',
  },
  {
    id: 86,
    flagImg: gd,
    countryName: 'Grenada',
    countryCode: '+1',
  },
  {
    id: 87,
    flagImg: ge,
    countryName: 'Georgia',
    countryCode: '+995',
  },
  {
    id: 88,
    flagImg: gf,
    countryName: 'French Guiana',
    countryCode: '+594',
  },
  {
    id: 99,
    flagImg: gg,
    countryName: 'Guernsey',
    countryCode: '+44',
  },
  {
    id: 90,
    flagImg: gh,
    countryName: 'Ghana',
    countryCode: '+233',
  },
  {
    id: 91,
    flagImg: gi,
    countryName: 'Gibraltar',
    countryCode: '+350',
  },
  {
    id: 92,
    flagImg: gl,
    countryName: 'Greenland',
    countryCode: '+299',
  },
  {
    id: 93,
    flagImg: gm,
    countryName: 'Gambia',
    countryCode: '+220',
  },
  {
    id: 94,
    flagImg: gn,
    countryName: 'Guinea',
    countryCode: '+224',
  },
  {
    id: 95,
    flagImg: gp,
    countryName: 'Guadeloupe',
    countryCode: '+590',
  },
  {
    id: 96,
    flagImg: gq,
    countryName: 'Equatorial Guinea',
    countryCode: '+240',
  },
  {
    id: 97,
    flagImg: gr,
    countryName: 'Greece',
    countryCode: '+30',
  },
  {
    id: 98,
    flagImg: gs,
    countryName: 'South Georgia and the South Sandwich Islands',
    countryCode: '+500',
  },
  {
    id: 99,
    flagImg: gt,
    countryName: 'Guatemala',
    countryCode: '+502',
  },
  {
    id: 100,
    flagImg: gu,
    countryName: 'Guam',
    countryCode: '+1',
  },
  {
    id: 101,
    flagImg: gw,
    countryName: 'Guinea-Bissau',
    countryCode: '+245',
  },
  {
    id: 102,
    flagImg: gy,
    countryName: 'Guyana',
    countryCode: '+592',
  },
  {
    id: 103,
    flagImg: hk,
    countryName: 'Hong Kong',
    countryCode: '+852',
  },
  {
    id: 104,
    flagImg: hn,
    countryName: 'Honduras',
    countryCode: '+504',
  },
  {
    id: 105,
    flagImg: hr,
    countryName: 'Croatia',
    countryCode: '+385',
  },
  {
    id: 106,
    flagImg: ht,
    countryName: 'Haiti',
    countryCode: '+509',
  },
  {
    id: 107,
    flagImg: hu,
    countryName: 'Hungary',
    countryCode: '+36',
  },
  {
    id: 108,
    flagImg: id,
    countryName: 'Indonesia',
    countryCode: '+62',
  },
  {
    id: 109,
    flagImg: ie,
    countryName: 'Ireland',
    countryCode: '+353',
  },
  {
    id: 110,
    flagImg: il,
    countryName: 'Israel',
    countryCode: '+972',
  },
  {
    id: 111,
    flagImg: im,
    countryName: 'Isle of Man',
    countryCode: '+44',
  },
  {
    id: 112,
    flagImg: ain,
    countryName: 'India',
    countryCode: '+91',
  },
  {
    id: 113,
    flagImg: io,
    countryName: 'British Indian Ocean Territory',
    countryCode: '+246',
  },
  {
    id: 114,
    flagImg: iq,
    countryName: 'Iraq',
    countryCode: '+964',
  },
  {
    id: 115,
    flagImg: ir,
    countryName: 'Iran (Islamic Republic of)',
    countryCode: '+98',
  },
  {
    id: 116,
    flagImg: is,
    countryName: 'Iceland',
    countryCode: '+354',
  },
  {
    id: 117,
    flagImg: it,
    countryName: 'Italy',
    countryCode: '+39',
  },
  {
    id: 118,
    flagImg: je,
    countryName: 'Jersey',
    countryCode: '+44',
  },
  {
    id: 119,
    flagImg: jm,
    countryName: 'Jamaica',
    countryCode: '+1',
  },
  {
    id: 120,
    flagImg: jo,
    countryName: 'Jordan',
    countryCode: '+962',
  },
  {
    id: 121,
    flagImg: jp,
    countryName: 'Japan',
    countryCode: '+81',
  },
  {
    id: 122,
    flagImg: ke,
    countryName: 'Kenya',
    countryCode: '+254',
  },
  {
    id: 123,
    flagImg: kg,
    countryName: 'Kyrgyzstan',
    countryCode: '+996',
  },
  {
    id: 124,
    flagImg: kh,
    countryName: 'Cambodia',
    countryCode: '+855',
  },
  {
    id: 125,
    flagImg: ki,
    countryName: 'Kiribati',
    countryCode: '+686',
  },
  {
    id: 126,
    flagImg: km,
    countryName: 'Comoros',
    countryCode: '+269',
  },
  {
    id: 127,
    flagImg: kn,
    countryName: 'Saint Kitts and Nevis',
    countryCode: '+1',
  },
  {
    id: 128,
    flagImg: kp,
    countryName: 'North Korea',
    countryCode: '+850',
  },
  {
    id: 129,
    flagImg: kr,
    countryName: 'South Korea',
    countryCode: '+82',
  },
  {
    id: 130,
    flagImg: kw,
    countryName: 'Kuwait',
    countryCode: '+965',
  },
  {
    id: 131,
    flagImg: ky,
    countryName: 'Cayman Islands',
    countryCode: '+1',
  },
  {
    id: 132,
    flagImg: kz,
    countryName: 'Kazakhstan',
    countryCode: '+7',
  },
  {
    id: 133,
    flagImg: la,
    countryName: 'Laos',
    countryCode: '+856',
  },
  {
    id: 134,
    flagImg: lb,
    countryName: 'Lebanon',
    countryCode: '+961',
  },
  {
    id: 135,
    flagImg: lc,
    countryName: 'Saint Lucia',
    countryCode: '+1',
  },
  {
    id: 136,
    flagImg: li,
    countryName: 'Liechtenstein',
    countryCode: '+423',
  },
  {
    id: 137,
    flagImg: lk,
    countryName: 'Sri Lanka',
    countryCode: '+94',
  },
  {
    id: 138,
    flagImg: lr,
    countryName: 'Liberia',
    countryCode: '+231',
  },
  {
    id: 139,
    flagImg: ls,
    countryName: 'Lesotho',
    countryCode: '+266',
  },
  {
    id: 140,
    flagImg: lt,
    countryName: 'Lithuania',
    countryCode: '+370',
  },
  {
    id: 141,
    flagImg: lu,
    countryName: 'Luxembourg',
    countryCode: '+352',
  },
  {
    id: 142,
    flagImg: lv,
    countryName: 'Latvia',
    countryCode: '+371',
  },
  {
    id: 143,
    flagImg: ly,
    countryName: 'Libya',
    countryCode: '+218',
  },
  {
    id: 144,
    flagImg: ma,
    countryName: 'Morocco',
    countryCode: '+212',
  },
  {
    id: 145,
    flagImg: mc,
    countryName: 'Monaco',
    countryCode: '+377',
  },
  {
    id: 146,
    flagImg: md,
    countryName: 'Moldova',
    countryCode: '+373',
  },
  {
    id: 147,
    flagImg: me,
    countryName: 'Montenegro',
    countryCode: '+382',
  },
  {
    id: 148,
    flagImg: mf,
    countryName: 'Saint Martin',
    countryCode: '+721',
  },
  {
    id: 149,
    flagImg: mg,
    countryName: 'Madagascar',
    countryCode: '+261',
  },
  {
    id: 150,
    flagImg: mh,
    countryName: 'Marshall Islands',
    countryCode: '+692',
  },
  {
    id: 151,
    flagImg: mk,
    countryName: 'North Macedonia',
    countryCode: '+389',
  },
  {
    id: 152,
    flagImg: ml,
    countryName: 'Mali',
    countryCode: '+223',
  },
  {
    id: 153,
    flagImg: mm,
    countryName: 'Myanmar',
    countryCode: '+95',
  },
  {
    id: 154,
    flagImg: mn,
    countryName: 'Mongolia',
    countryCode: '+976',
  },
  {
    id: 155,
    flagImg: mo,
    countryName: 'Macau',
    countryCode: '+853',
  },
  {
    id: 156,
    flagImg: mp,
    countryName: 'Northern Mariana Islands',
    countryCode: '+1',
  },
  {
    id: 157,
    flagImg: mq,
    countryName: 'Martinique',
    countryCode: '+596',
  },
  {
    id: 158,
    flagImg: mr,
    countryName: 'Mauritania',
    countryCode: '+222',
  },
  {
    id: 159,
    flagImg: ms,
    countryName: 'Montserrat',
    countryCode: '+1',
  },
  {
    id: 160,
    flagImg: mt,
    countryName: 'Malta',
    countryCode: '+356',
  },
  {
    id: 161,
    flagImg: mu,
    countryName: 'Mauritius',
    countryCode: '+230',
  },
  {
    id: 162,
    flagImg: mv,
    countryName: 'Maldives',
    countryCode: '+960',
  },
  {
    id: 163,
    flagImg: mw,
    countryName: 'Malawi',
    countryCode: '+265',
  },
  {
    id: 164,
    flagImg: mx,
    countryName: 'Mexico',
    countryCode: '+52',
  },
  {
    id: 165,
    flagImg: my,
    countryName: 'Malaysia',
    countryCode: '+60',
  },
  {
    id: 166,
    flagImg: mz,
    countryName: 'Mozambique',
    countryCode: '+258',
  },
  {
    id: 167,
    flagImg: na,
    countryName: 'Namibia',
    countryCode: '+264',
  },
  {
    id: 168,
    flagImg: nc,
    countryName: 'New Caledonia',
    countryCode: '+687',
  },
  {
    id: 169,
    flagImg: ne,
    countryName: 'Niger',
    countryCode: '+227',
  },
  {
    id: 170,
    flagImg: nf,
    countryName: 'Norfolk Island',
    countryCode: '+672',
  },
  {
    id: 171,
    flagImg: ng,
    countryName: 'Nigeria',
    countryCode: '+234',
  },
  {
    id: 172,
    flagImg: ni,
    countryName: 'Nicaragua',
    countryCode: '+505',
  },
  {
    id: 173,
    flagImg: nl,
    countryName: 'Netherlands',
    countryCode: '+31',
  },
  {
    id: 174,
    flagImg: no,
    countryName: 'Norway',
    countryCode: '+47',
  },
  {
    id: 175,
    flagImg: np,
    countryName: 'Nepal',
    countryCode: '+977',
  },
  {
    id: 176,
    flagImg: nr,
    countryName: 'Nauru',
    countryCode: '+674',
  },
  {
    id: 177,
    flagImg: nu,
    countryName: 'Niue',
    countryCode: '+683',
  },
  {
    id: 178,
    flagImg: nz,
    countryName: 'New Zealand',
    countryCode: '+64',
  },
  {
    id: 179,
    flagImg: om,
    countryName: 'Oman',
    countryCode: '+968',
  },
  {
    id: 180,
    flagImg: pa,
    countryName: 'Panama',
    countryCode: '+507',
  },
  {
    id: 181,
    flagImg: pe,
    countryName: 'Peru',
    countryCode: '+51',
  },
  {
    id: 182,
    flagImg: pf,
    countryName: 'French Polynesia',
    countryCode: '+689',
  },
  {
    id: 183,
    flagImg: pg,
    countryName: 'Papua New Guinea',
    countryCode: '+675',
  },
  {
    id: 184,
    flagImg: ph,
    countryName: 'Philippines',
    countryCode: '+63',
  },
  {
    id: 185,
    flagImg: pk,
    countryName: 'Pakistan',
    countryCode: '+92',
  },
  {
    id: 186,
    flagImg: pl,
    countryName: 'Poland',
    countryCode: '+48',
  },
  {
    id: 187,
    flagImg: pm,
    countryName: 'Saint Pierre and Miquelon',
    countryCode: '+508',
  },
  {
    id: 188,
    flagImg: pn,
    countryName: 'Pitcairn',
    countryCode: '+64',
  },
  {
    id: 189,
    flagImg: pr,
    countryName: 'Puerto Rico',
    countryCode: '+1',
  },
  {
    id: 190,
    flagImg: ps,
    countryName: 'State of Palestine',
    countryCode: '+970',
  },
  {
    id: 191,
    flagImg: pt,
    countryName: 'Portugal',
    countryCode: '+351',
  },
  {
    id: 192,
    flagImg: pw,
    countryName: 'Palau',
    countryCode: '+680',
  },
  {
    id: 193,
    flagImg: py,
    countryName: 'Paraguay',
    countryCode: '+595',
  },
  {
    id: 194,
    flagImg: qa,
    countryName: 'Qatar',
    countryCode: '+974',
  },
  {
    id: 195,
    flagImg: re,
    countryName: 'Réunion',
    countryCode: '+262',
  },
  {
    id: 196,
    flagImg: ro,
    countryName: 'Romania',
    countryCode: '+40',
  },
  {
    id: 197,
    flagImg: rs,
    countryName: 'Serbia',
    countryCode: '+381',
  },
  {
    id: 198,
    flagImg: ru,
    countryName: 'Russia',
    countryCode: '+7',
  },
  {
    id: 199,
    flagImg: rw,
    countryName: 'Rwanda',
    countryCode: '+250',
  },
  {
    id: 200,
    flagImg: sa,
    countryName: 'Saudi Arabia',
    countryCode: '+966',
  },
  {
    id: 201,
    flagImg: sb,
    countryName: 'Solomon Islands',
    countryCode: '+677',
  },
  {
    id: 202,
    flagImg: sc,
    countryName: 'Seychelles',
    countryCode: '+248',
  },
  {
    id: 203,
    flagImg: sd,
    countryName: 'Sudan',
    countryCode: '+249',
  },
  {
    id: 204,
    flagImg: se,
    countryName: 'Sweden',
    countryCode: '+46',
  },
  {
    id: 205,
    flagImg: sg,
    countryName: 'Singapore',
    countryCode: '+65',
  },
  {
    id: 207,
    flagImg: si,
    countryName: 'Slovenia',
    countryCode: '+386',
  },
  {
    id: 208,
    flagImg: sj,
    countryName: 'Svalbard and Jan Mayen',
    countryCode: '47',
  },
  {
    id: 209,
    flagImg: sk,
    countryName: 'Slovakia',
    countryCode: '+421',
  },
  {
    id: 210,
    flagImg: sl,
    countryName: 'Sierra Leone',
    countryCode: '+232',
  },
  {
    id: 212,
    flagImg: sm,
    countryName: 'San Marino',
    countryCode: '+378',
  },
  {
    id: 213,
    flagImg: sn,
    countryName: 'Senegal',
    countryCode: '+221',
  },
  {
    id: 214,
    flagImg: so,
    countryName: 'Somalia',
    countryCode: '+252',
  },
  {
    id: 215,
    flagImg: sr,
    countryName: 'Suriname',
    countryCode: '+597',
  },
  {
    id: 216,
    flagImg: ss,
    countryName: 'South Sudan',
    countryCode: '+211',
  },
  {
    id: 217,
    flagImg: st,
    countryName: 'Sao Tome and Principe',
    countryCode: '+239',
  },
  {
    id: 218,
    flagImg: sv,
    countryName: 'El Salvador',
    countryCode: '+503',
  },
  {
    id: 219,
    flagImg: sx,
    countryName: 'Sint Maarten',
    countryCode: '+721',
  },
  {
    id: 220,
    flagImg: sy,
    countryName: 'Syria',
    countryCode: '+963',
  },
  {
    id: 221,
    flagImg: sz,
    countryName: 'Eswatini',
    countryCode: '+268',
  },
  {
    id: 222,
    flagImg: ta,
    countryName: 'Tristan da Cunha',
    countryCode: '+290',
  },
  {
    id: 223,
    flagImg: tc,
    countryName: 'Turks and Caicos Islands',
    countryCode: '+1',
  },
  {
    id: 224,
    flagImg: td,
    countryName: 'Chad',
    countryCode: '+235',
  },
  {
    id: 225,
    flagImg: tg,
    countryName: 'Togo',
    countryCode: '+228',
  },
  {
    id: 226,
    flagImg: th,
    countryName: 'Thailand',
    countryCode: '+66',
  },
  {
    id: 227,
    flagImg: tj,
    countryName: 'Tajikistan',
    countryCode: '+992',
  },
  {
    id: 228,
    flagImg: tk,
    countryName: 'Tokelau',
    countryCode: '+690',
  },
  {
    id: 229,
    flagImg: tl,
    countryName: 'Timor-Leste',
    countryCode: '+670',
  },
  {
    id: 230,
    flagImg: tm,
    countryName: 'Turkmenistan',
    countryCode: '+993',
  },
  {
    id: 231,
    flagImg: tn,
    countryName: 'Tunisia',
    countryCode: '+216',
  },
  {
    id: 232,
    flagImg: to,
    countryName: 'Tonga',
    countryCode: '+676',
  },
  {
    id: 233,
    flagImg: tr,
    countryName: 'Turkey',
    countryCode: '+90',
  },
  {
    id: 234,
    flagImg: tt,
    countryName: 'Trinidad and Tobago',
    countryCode: '+1',
  },
  {
    id: 235,
    flagImg: tv,
    countryName: 'Tuvalu',
    countryCode: '+688',
  },
  {
    id: 236,
    flagImg: tw,
    countryName: 'Taiwan',
    countryCode: '+886',
  },
  {
    id: 237,
    flagImg: tz,
    countryName: 'Tanzania',
    countryCode: '+255',
  },
  {
    id: 238,
    flagImg: ua,
    countryName: 'Ukraine',
    countryCode: '+380',
  },
  {
    id: 239,
    flagImg: ug,
    countryName: 'Uganda',
    countryCode: '+256',
  },
  {
    id: 240,
    flagImg: us,
    countryName: 'United States of America',
    countryCode: '+1',
  },
  {
    id: 242,
    flagImg: uy,
    countryName: 'Uruguay',
    countryCode: '+598',
  },
  {
    id: 243,
    flagImg: uz,
    countryName: 'Uzbekistan',
    countryCode: '+998',
  },
  {
    id: 244,
    flagImg: va,
    countryName: 'Holy See',
    countryCode: '+379',
  },
  {
    id: 245,
    flagImg: vc,
    countryName: 'Saint Vincent and the Grenadines',
    countryCode: '+1',
  },
  {
    id: 246,
    flagImg: ve,
    countryName: 'Venezuela (Bolivarian Republic of)',
    countryCode: '+58',
  },
  {
    id: 247,
    flagImg: vg,
    countryName: 'Virgin Islands (British)',
    countryCode: '+1',
  },
  {
    id: 248,
    flagImg: vi,
    countryName: 'Virgin Islands (U.S.)',
    countryCode: '+1',
  },
  {
    id: 249,
    flagImg: vn,
    countryName: 'Vietnam',
    countryCode: '+84',
  },
  {
    id: 250,
    flagImg: vu,
    countryName: 'Vanuatu',
    countryCode: '+678',
  },
  {
    id: 251,
    flagImg: wf,
    countryName: 'Wallis and Futuna',
    countryCode: '+681',
  },
  {
    id: 252,
    flagImg: ws,
    countryName: 'Samoa',
    countryCode: '+685',
  },
  {
    id: 253,
    flagImg: xk,
    countryName: 'Kosovo',
    countryCode: '+383',
  },
  {
    id: 254,
    flagImg: ye,
    countryName: 'Yemen',
    countryCode: '+967',
  },
  {
    id: 255,
    flagImg: yt,
    countryName: 'Mayotte',
    countryCode: '+262',
  },
  {
    id: 256,
    flagImg: za,
    countryName: 'South Africa',
    countryCode: '+27',
  },
  {
    id: 257,
    flagImg: zm,
    countryName: 'Zambia',
    countryCode: '+260',
  },
  {
    id: 258,
    flagImg: zw,
    countryName: 'Zimbabwe',
    countryCode: '+263',
  },
];

export default country;
