import { isEmpty } from 'lodash';
import { coachArrays } from 'pages/Constants';
import { setSlideTimers, setTimerFlag, setTimers } from './reducer';
import { getClassTimer } from 'helpers/classes';

export const setTimerData = (lectureData, classId) => async (dispatch) => {
  try {
    const { data, status } = await getClassTimer(classId);

    if (status === 200 && !isEmpty(data)) {
      const matchedLecture = data?.filter((lecture) => {
        return lecture?.lectureConfigurationId === lectureData?.configId;
      });

      if (!isEmpty(matchedLecture)) {
        matchedLecture?.forEach((lecture) => {
          // Set slide timers
          Object.keys(lecture?.slideTimers).forEach((phase) => {
            dispatch(
              setSlideTimers({
                classState: phase,
                slideTimer: lecture?.slideTimers?.[phase],
              }),
            );
          });
          // Set element timers
          Object.keys(lecture?.elementTimers).forEach((phase) => {
            dispatch(
              setTimers({
                classState: phase,
                timer: lecture?.elementTimers?.[phase],
              }),
            );
          });
          dispatch(
            setTimerFlag({
              timerFlag: true,
            }),
          );
        });
        return;
      }
    }

    const processClassContent = (lectureData) => {
      const result = {
        slideTimers: {
          teach: {},
          coach_basic: {},
          coach_intermediate: {},
          coach_advanced: {},
        },
        elementTimers: {
          teach: {},
          coach_basic: {},
          coach_intermediate: {},
          coach_advanced: {},
        },
      };

      const processSlideArray = (array, targetObject) => {
        if (!Array.isArray(array)) {
          console.warn('Expected an array, but got:', array);
          return;
        }

        if (array.some((item) => item?.type !== 'evaluation')) {
          let slideCounter = 1;
          array
            .filter((item) => item?.type !== 'evaluation')
            .forEach((item) => {
              if (item.thumbnails && Array.isArray(item.thumbnails)) {
                item.thumbnails.forEach((thumbnail) => {
                  const processedItem = {
                    elementId: item?.elementDetails?.elementId,
                    elementName: item?.elementDetails?.name,
                    elementSlideNo: thumbnail.slideNo,
                    slideNo: slideCounter,
                    timer: 0,
                  };
                  targetObject[slideCounter++] = processedItem;
                });
              }
            });
        }
      };

      const processElementArray = (array, targetObject) => {
        if (!Array.isArray(array)) {
          console.warn('Expected an array, but got:', array);
          return;
        }

        let elementCounter = 0;
        array.forEach((item) => {
          const processedItem = {
            slideType: item?.type,
            title: item?.elementDetails?.name || item?.title || null,
            timer: 0,
            elementId: item?.elementDetails?.elementId || null,
            evaluationId: item?.evaluationId || null,
            evaluationType: item?.evaluationType || null,
            evaluationAt: item?.evaluateAt || null,
          };
          targetObject[elementCounter++] = processedItem;
        });
      };

      // Process teach section
      if (Array.isArray(lectureData.teach)) {
        processSlideArray(lectureData.teach, result.slideTimers.teach);
        processElementArray(lectureData.teach, result.elementTimers.teach);
      }

      // Process coach sections
      coachArrays.forEach((coachArray) => {
        if (Array.isArray(lectureData?.[coachArray])) {
          processSlideArray(
            lectureData[coachArray],
            result.slideTimers[coachArray],
          );
          processElementArray(
            lectureData[coachArray],
            result.elementTimers[coachArray],
          );
        }
      });

      return result;
    };

    const result = processClassContent(lectureData);

    // Dispatch both timer types for each phase
    Object.keys(result.slideTimers).forEach((phase) => {
      dispatch(
        setSlideTimers({
          classState: phase,
          slideTimer: result.slideTimers[phase],
        }),
      );
    });
    Object.keys(result.elementTimers).forEach((phase) => {
      dispatch(
        setTimers({
          classState: phase,
          timer: result.elementTimers[phase],
        }),
      );
    });
  } catch (err) {
    console.log('error in setting timers', err);
  }
};
