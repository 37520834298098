import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  books: [],
  totalRecords: 0,
  loading: false,
};

const adminBookSlice = createSlice({
  name: 'booksList',
  initialState,
  reducers: {
    setBooksList: (state, action) => {
      state.books = action.payload;
      state.loading = initialState.loading;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
    setTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
  },
});

export const getBooksListRedux = (state) => state.AdminBook.books;
export const getBooksListLoaderRedux = (state) => state.AdminBook.loading;
export const getTotalRecordsRedux = (state) => state.AdminBook.totalRecords;

export const { setBooksList, setLoader, setTotalRecords } =
  adminBookSlice.actions;

export default adminBookSlice.reducer;
