import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  initiateForgotPasscode: {
    success: null,
    error: null,
    data: null,
  },
  verifyForgotPasscode: {
    success: null,
    error: null,
    data: null,
  },
  resetNewPasscode: {
    success: null,
    error: null,
    data: null,
  },
  loading: false,
};

const ForgotPasscodeSlice = createSlice({
  name: 'StudentGuardianForgotPasscode',
  initialState,
  reducers: {
    initiateForgotPasscodeSuccess(state, action) {
      state.initiateForgotPasscode = {
        success: true,
        error: false,
        data: action.payload,
      };
      state.loading = initialState.loading;
    },
    verifyForgotPasscodeOtpSuccess(state, action) {
      state.verifyForgotPasscode = {
        success: true,
        error: false,
        data: action.payload,
      };
      state.loading = initialState.loading;
    },
    createNewPasscodeSuccess(state, action) {
      state.resetNewPasscode = {
        success: true,
        error: false,
        data: action.payload,
      };
      state.loading = initialState.loading;
    },
    forgotPasscodeError(state, action) {
      if (action?.payload?.methodName !== '') {
        state[action?.payload?.methodName] = {
          success: false,
          error: true,
          data: action.payload,
        };
        state.loading = initialState.loading;
      }
    },
    resetForgotPasscodeStore(state, action) {
      state.initiateForgotPasscode = initialState.initiateForgotPasscode;
      state.verifyForgotPasscode = initialState.verifyForgotPasscode;
      state.resetNewPasscode = initialState.resetNewPasscode;
      state.loading = initialState.loading;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const getStudentGuardianForgotPasscodeLoaderRedux = (state) =>
  state?.StudentGuardianForgotPasscode?.loading || false;

export const {
  initiateForgotPasscodeSuccess,
  verifyForgotPasscodeOtpSuccess,
  createNewPasscodeSuccess,
  forgotPasscodeError,
  resetForgotPasscodeStore,
  setLoader,
} = ForgotPasscodeSlice.actions;

export default ForgotPasscodeSlice.reducer;
