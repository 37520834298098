import {
  initiateLoginService,
  checkPasscodeStatusService,
  verifyLoginPasscodeService,
  listOfAccountHoldersService,
  loginAccountWithTempTokenService,
  initiateForgotPasscodeService,
} from 'helpers/auth';

import {
  initiateLoginSuccess,
  verifyLoginPasscodeSuccess,
  listOfAccountHoldersLoginSuccess,
  loginAccountWithTempTokenSuccess,
  loginAccountDetailsSuccess,
  logoutAccount,
  resetLogin,
  loginFailed,
  resetErrors,
  setLoader,
} from './reducer';

export const initiateLogin = (payload) => async (dispatch) => {
  try {
    let otpInfo = null;
    let passcodeStatus = null;
    dispatch(setLoader(true));

    let data = await initiateLoginService(payload);
    if (data?.status === 200) {
      let checkPasscodeData = await checkPasscodeStatusService(
        data?.data?.studentGuardiansId,
      );
      if (checkPasscodeData?.status === 200 && checkPasscodeData?.data) {
        passcodeStatus = checkPasscodeData?.data?.passcodeStatus || null;
        if (checkPasscodeData?.data?.passcodeStatus === false) {
          let resOTPStatus = await initiateForgotPasscodeService(payload);
          if (resOTPStatus?.status === 200) {
            otpInfo = {
              status: true,
            };
          }
        }
      }

      data.data.passcodeStatus = passcodeStatus;
      data.data.otpInfo = otpInfo;
      dispatch(initiateLoginSuccess(data));
    } else {
      data = { ...data, methodName: 'initiateLogin' };
      dispatch(loginFailed(data));
    }
  } catch (e) {
    const error = { ...e, methodName: 'initiateLogin' };
    dispatch(loginFailed(error));
  }
};

export const verifyLoginPasscode = (payload) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    let data = await verifyLoginPasscodeService(payload);
    if (data?.status === 200) {
      dispatch(verifyLoginPasscodeSuccess(data));
    } else {
      data = { ...data, methodName: 'verifyLoginPasscode' };
      dispatch(loginFailed(data));
    }
  } catch (e) {
    const error = { ...e, methodName: 'verifyLoginPasscode' };
    dispatch(loginFailed(error));
  }
};

export const listOfAccountHoldersLogin = (accessToken) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const payload = { headers: { Authorization: `Bearer ${accessToken}` } };
    let data = await listOfAccountHoldersService(payload);
    if (data?.status === 200) {
      dispatch(listOfAccountHoldersLoginSuccess(data));
    } else {
      data = { ...data, methodName: 'listOfAccountHoldersLogin' };
      dispatch(loginFailed(data));
    }
  } catch (e) {
    const error = { ...e, methodName: 'listOfAccountHoldersLogin' };
    dispatch(loginFailed(error));
  }
};

export const loginAccountWithTempToken =
  (payload, token) => async (dispatch) => {
    try {
      let headersObj;
      if (token) {
        headersObj = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      }

      dispatch(setLoader(true));
      let data = await loginAccountWithTempTokenService(payload, headersObj);
      if (data?.status === 200) {
        dispatch(
          loginAccountWithTempTokenSuccess(data?.data?.loginToken || ''),
        );
        dispatch(loginAccountDetailsSuccess(data));
      } else {
        data = { ...data, methodName: 'loginAccountWithTempToken' };
        dispatch(loginFailed(data));
      }
    } catch (e) {
      const error = { ...e, methodName: 'loginAccountWithTempToken' };
      dispatch(loginFailed(error));
    }
  };

export const logout = () => async (dispatch) => {
  try {
    dispatch(logoutAccount());
  } catch (error) {
    dispatch(loginFailed(error));
  }
};

export const resetLoginFlag = () => async (dispatch) => {
  try {
    dispatch(resetLogin());
  } catch (error) {
    dispatch(loginFailed(error));
  }
};

export const resetInitiateLoginErrors = () => async (dispatch) => {
  const info = { methodName: 'initiateLogin' };
  dispatch(resetErrors(info));
};

export const resetVerifyLoginPasscodeErrors = () => async (dispatch) => {
  const info = { methodName: 'verifyLoginPasscode' };
  dispatch(resetErrors(info));
};

export const resetListOfAccountHoldersLoginErrors = () => async (dispatch) => {
  const info = { methodName: 'listOfAccountHoldersLogin' };
  dispatch(resetErrors(info));
};

export const resetLoginAccountDetailsErrors = () => async (dispatch) => {
  const info = { methodName: 'loginAccountDetails' };
  dispatch(resetErrors(info));
};

export const reUpdateLoginAccountInfo = (data) => async (dispatch) => {
  try {
    dispatch(loginAccountDetailsSuccess(data));
  } catch (e) {
    const error = { ...e, methodName: 'loginAccountDetailsSuccess' };
    dispatch(loginFailed(error));
  }
};
