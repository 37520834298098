import React from 'react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import moment from 'moment';
import '../generateReport.css';

const StudentPerformance = ({
  studentReportData,
  feedbacks,
  handleInputChange,
  handleKeyDown,
}) => {
  let uprioFromDate;
  if (studentReportData?.subscriptionStartDate) {
    const date = studentReportData?.subscriptionStartDate;
    const formattedDate = moment(
      typeof subscriptionStartDate === 'number'
        ? date < 1e10
          ? date * 1000
          : date
        : date,
    )
      .utc()
      .format('Do MMMM, YYYY');
    uprioFromDate = formattedDate;
  }

  return (
    <>
      {/* school and student info */}
      <section className="student-section mt-4">
        <Table bordered className="student-info-table">
          <tbody>
            <tr>
              <td>
                <strong>Student Name</strong>
              </td>
              <td>{`${studentReportData?.studentDetails?.firstName || ''} ${studentReportData?.studentDetails?.lastName || ''}`}</td>
            </tr>
            <tr>
              <td>
                <strong>School Name</strong>
              </td>
              <td>{studentReportData?.schoolName || 'N/A'}</td>
            </tr>
            <tr>
              <td>
                <strong>Grade</strong>
              </td>
              <td>{studentReportData?.gradeName || 'N/A'}</td>
            </tr>
            <tr>
              <td>
                <strong>Student with Uprio from</strong>
              </td>
              <td>{uprioFromDate || 'N/A'}</td>
            </tr>
          </tbody>
        </Table>
      </section>

      {/* class wise information */}
      <section className="class-info-section p-2">
        <Table bordered>
          <thead className="class-info-header">
            <tr>
              <th>Date</th>
              <th>Class Info</th>
              <th>Topics Covered</th>
              <th>
                Total Evaluations Done <br /> (Poll | Notebook)
              </th>
            </tr>
          </thead>
          <tbody>
            {studentReportData?.classes?.map((classItem, index) => {
              const date = moment(classItem?.classDate).format('DD MMM YY');

              const qcTopics =
                classItem?.questionCategories?.map(
                  (category) =>
                    `Category ${category?.categoryNumber}: ${category?.questionCategoryName}`,
                ) || [];

              const qfTopics =
                classItem?.questionFamilies?.map(
                  (family) =>
                    `Family ${family?.familyNumber}: ${family?.questionFamilyName}`,
                ) || [];

              const topicsCovered = [...qcTopics, ...qfTopics].join(' | ');

              const totalQuestions =
                classItem?.questions?.poll + classItem?.questions?.notebooks;

              return (
                <tr key={index}>
                  <td className="date-column">{date}</td>
                  <td>{classItem?.className}</td>
                  <td>{topicsCovered}</td>
                  <td>
                    {totalQuestions}( {classItem?.questions.poll} |{' '}
                    {classItem?.questions?.notebooks} )
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </section>

      {/* tutor observation section */}
      <section className="p-2 tutor-observation-div">
        <Card className="border-0">
          <CardHeader className="tutor-observation-header text-white text-center fs-4">
            Tutor Observations
          </CardHeader>
          <CardBody className="tutor-observation-body">
            <ul className="list-disc pl-5">
              {feedbacks?.map((feedback, index) => (
                <li key={index} className="feedback-item">
                  {/* Render input for regular view */}
                  <input
                    type="text"
                    value={feedback}
                    onChange={(e) => handleInputChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    autoFocus={index === feedbacks.length - 1}
                    className="print-hidden feedback-input"
                  />
                  {/* Render textarea for printing */}
                  <textarea
                    value={feedback}
                    readOnly
                    className="feedback-on-printing screen-hidden feedback-textarea"
                  />
                </li>
              ))}
            </ul>
          </CardBody>
        </Card>
      </section>
    </>
  );
};

export default StudentPerformance;
