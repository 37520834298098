import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  demoClassConfigurations: [],
  totalRecords: 0,
  loading: false,
  addOrUpdateloading: { loader: false, success: false, error: null },
};

const adminDemoClassSlice = createSlice({
  name: 'DemoClassesList',
  initialState,
  reducers: {
    setDemoClassConfigurations: (state, action) => {
      state.demoClassConfigurations = action.payload;
      state.loading = initialState.loading;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
    setTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
    setAddOrUpdateLoader: (state, action) => {
      state.addOrUpdateloading = action.payload;
    },
    reset: (state) => {
      state.addOrUpdateloading = initialState.addOrUpdateloading;
    },
  },
});

export const getDemoClassConfigurationsListRedux = (state) =>
  state.AdminDemoClass.demoClassConfigurations;
export const getDemoClassConfigurationsLoaderRedux = (state) =>
  state.AdminDemoClass.loading;
export const getTotalRecordsRedux = (state) =>
  state.AdminDemoClass.totalRecords;
export const {
  setDemoClassConfigurations,
  setLoader,
  setTotalRecords,
  setAddOrUpdateLoader,
  reset,
} = adminDemoClassSlice.actions;
export const getAddOrUpdateDemoClassConfigurationLoaderRedux = (state) =>
  state.AdminDemoClass.addOrUpdateloading;

export default adminDemoClassSlice.reducer;
