//Include Both Helper File with needed methods

import { successToastify } from 'helpers/toast';
import {
  createUser,
  deleteUser,
  listOfUsersService,
  updateUser,
} from 'helpers/user';
import {
  setTutorsList,
  setUsersList,
  setLoader,
  setTotalRecords,
  setAddOrUpdateLoader,
} from './reducer';

export const getUsersList =
  (user = {}, history) =>
  async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const { roleId } = user;
      const { data, status } = await listOfUsersService({ roleId });
      if (status === 200) {
        (await dispatch(setUsersList(data.records))) &&
          dispatch(setTotalRecords(data.totalRecords));
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
    }
  };

export const getTutorsList =
  (user = {}, history) =>
  async (dispatch) => {
    try {
      dispatch(setLoader(false));
      const {
        roleId: { id },
      } = user;
      const { data, status } = await listOfUsersService({ roleId: id });
      if (status === 200) {
        (await dispatch(setTutorsList(data.records))) &&
          dispatch(setTotalRecords(data.totalRecords));
      }

      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
    }
  };

export const createUserService =
  (data, headerInfo, history) => async (dispatch) => {
    try {
      dispatch(setAddOrUpdateLoader(true));
      await createUser(data, headerInfo);
      successToastify('User created successfully.');
      dispatch(setAddOrUpdateLoader(false));
    } catch (error) {
      dispatch(setAddOrUpdateLoader(false));
      // dispatch(apiError(error));
    }
  };

export const updateUserService =
  (user, headerInfo, history) => async (dispatch) => {
    try {
      dispatch(setAddOrUpdateLoader(true));
      await updateUser(user, headerInfo);
      successToastify('User updated successfully.');
      dispatch(setAddOrUpdateLoader(false));
    } catch (error) {
      dispatch(setAddOrUpdateLoader(false));

      // dispatch(apiError(error));
    }
  };

export const deleteUserService = (user, history) => async (dispatch) => {
  try {
    await deleteUser(user);
    successToastify('User removed successfully');
  } catch (error) {
    // dispatch(apiError(error));
  }
};
