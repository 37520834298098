import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
  UncontrolledDropdown,
} from 'reactstrap';
import { get, map } from 'lodash';

import { primaryLoginAuthenticationToken } from 'slices/student-guardian/auth/login/reducer';
import {
  getProfileInformationRedux,
  getGuardiansListRedux,
  getSiblingsListRedux,
  getSelectedStudentInfoRedux,
} from 'slices/student-guardian/account/profile/reducer';
import {
  triggerSetSelectStudent,
  triggerResetSelectStudent,
} from 'slices/student-guardian/account/profile/thunk';

import languages from 'common/languages';
import { base } from 'config';
import io from 'socket.io-client';

import noImage from 'assets/images/users/user-dummy-img.jpg';
import { initSocketService } from 'slices/socket/thunk';
import { getSocket } from 'slices/socket/reducer';

const StudentsDropdown = (props) => {
  const dispatch = useDispatch();
  const authUserInfo = useSelector(getProfileInformationRedux);
  const authToken = useSelector(primaryLoginAuthenticationToken);
  const siblingListData = useSelector(getSiblingsListRedux);
  const selectedStudent = useSelector(getSelectedStudentInfoRedux);
  // const socket = useSelector(getSocket);

  const [isStudentDropdown, setIsStudentDropdown] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (selectedStudent) {
      dispatch(triggerSetSelectStudent(selectedStudent));
    } else {
      const currentStudent =
        siblingListData?.length > 0 ? siblingListData[0] : null;
      dispatch(triggerSetSelectStudent(currentStudent));
    }

    // dispatch(initSocketService(selectedStudent)).then(socket=>{
    //   // socket.on('understandingAnalyatics', (data) => {
    //   //   alert(`Received hello world message: ${JSON.stringify(data)}`);
    //   //   console.log(`Received hello world message: ${JSON.stringify(data)}`);
    //   // });
    // });
  }, [siblingListData, selectedStudent]);

  // useEffect(()=>{
  //   console.log('--in use effect -- ', socket);
  //   // if(socket.connected){

  //   // }
  // },[])

  const changeStudentAction = (e, studentInfo = null) => {
    e?.preventDefault();

    dispatch(triggerSetSelectStudent(studentInfo));
  };

  const toggleStudentDropdown = () => {
    setIsStudentDropdown(!isStudentDropdown);
  };

  return (
    <div className="p-3">
      <div className="form-group mt-2">
        {siblingListData?.length > 0 && (
          <ButtonGroup>
            <UncontrolledDropdown className="guardian-student-dd">
              <DropdownToggle tag="button" className="btn btn-light btn-label">
                {selectedStudent ? (
                  <>
                    {`${selectedStudent?.members?.firstName} ${selectedStudent?.members?.lastName}`}{' '}
                    <i className="mdi mdi-chevron-down"></i>
                  </>
                ) : (
                  <>--Select Student--</>
                )}
              </DropdownToggle>
              <DropdownMenu>
                {siblingListData?.map((item, key) => {
                  return (
                    <DropdownItem
                      key={key}
                      onClick={(e) => changeStudentAction(e, item)}
                    >
                      {`${item?.members?.firstName} ${item?.members?.lastName}`}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </UncontrolledDropdown>
          </ButtonGroup>
        )}
      </div>
    </div>
  );
};

export default StudentsDropdown;
