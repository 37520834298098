import React from 'react';
import { logoDark } from 'assets/images';

const Header = ({ studentDetails, initials, className = '' }) => {
  return (
    <div className={`${className} bg-white relative overflow-hidden`}>
      <div className="uprioLogo">
        <img src={logoDark} height="100%" width="100%" alt="uprio_logo" />
      </div>
      <div className="report-header">
        <h1 className="text-white">{`${studentDetails.firstName}'s Uprio Report`}</h1>
        <div
          style={{ height: '50px', width: '50px' }}
          className="rounded-circle bg-white d-flex justify-content-center align-items-center me-3"
        >
          <span>{initials}</span>
        </div>
      </div>
    </div>
  );
};

export default Header;
