export const generateOutput = (
  inputDataArray,
  sortedQuestionCategorySummary,
) => {
  return inputDataArray.map((inputData) => {
    const questionScores = {};
    const notebookImages = {};
    const stepCategories = Array.from(
      new Set(
        inputData.questionsScoreForEachSteps.map((step) => step.stepName),
      ),
    );
    const stepData = {};

    inputData.questionId.forEach((qId, index) => {
      questionScores[`Question ${index + 1}`] = '';
      notebookImages[`Question ${index + 1}`] = '';

      inputData.questionsScoreForEachSteps.forEach((step) => {
        if (step.questionId === qId) {
          const stepIndex = stepCategories.findIndex(
            (sc) => sc === step.stepName,
          );
          const stepKey = `Step ${stepIndex + 1}`;

          // Assign step data
          if (!stepData[stepKey]) stepData[stepKey] = {};
          stepData[stepKey][`Question ${index + 1}`] = step.stepScore;

          // Assign notebook image
          notebookImages[`Question ${index + 1}`] = step.image;

          // Update question score
          questionScores[`Question ${index + 1}`] = step.questionScore;
        }
      });
    });

    const output = [
      {
        'Question Family': sortedQuestionCategorySummary?.find(
          (qc) => qc.questionFamilyId === inputData?.questionFamilyId,
        )?.familyNumber,
        'Family Description': inputData.questionFamilyName,
        ...inputData.questionId.reduce((acc, qId, index) => {
          acc[`Question ${index + 1}`] =
            inputData.questionsScoreForEachSteps.find(
              (step) => step.questionId === qId,
            ).questionDescription;
          return acc;
        }, {}),
      },
      {
        'Question Family': 'Notebook Image',
        'Family Description': '',
        ...notebookImages,
      },
      {
        'Question Family': 'Answer:',
        'Family Description': 'Correct | Incorrect | Not Done',
        ...questionScores,
      },
    ];

    Object.keys(stepData)
      .sort((a, b) => {
        const stepNumberA = parseInt(a.split(' ')[1]);
        const stepNumberB = parseInt(b.split(' ')[1]);
        return stepNumberA - stepNumberB;
      })
      .forEach((stepKey) => {
        output.push({
          'Question Family': stepKey,
          'Family Description':
            stepCategories[parseInt(stepKey.split(' ')[1]) - 1],
          ...stepData[stepKey],
        });
      });

    return output;
  });
};
