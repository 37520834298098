import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lectureData: {},
  slideData: {},
  isPreview: {
    teach: false,
    coach_basic: false,
    coach_intermediate: false,
    coach_advanced: false,
  },
  evalInProgress: {
    teach: false,
    coach_basic: false,
    coach_intermediate: false,
    coach_advanced: false,
  },
  showPreviousButton: false,
  isHTML: true,
  isLastStep: false,
  pollData: {},
};

const htmlSlidesSlice = createSlice({
  name: 'htmlSlides',
  initialState,
  reducers: {
    setLectureData: (state, action) => {
      state.number = action.payload;
    },
    setSlideNumbers: (state, action) => {
      state.slideData = action.payload;
    },
    setIsPreview: (state, action) => {
      const { classState, active } = action.payload;
      state.isPreview[classState] = active;
    },
    setEvalInProgress: (state, action) => {
      const { classState, active } = action.payload;
      state.evalInProgress[classState] = active;
    },
    setShowPreviousButton: (state, action) => {
      state.showPreviousButton = action.payload;
    },
    setIsHTML: (state, action) => {
      state.isHTML = action.payload;
    },
    setIsLastStep: (state, action) => {
      state.isLastStep = action.payload;
    },
    setPollData: (state, action) => {
      state.pollData = action.payload;
    },
  },
});

export const {
  setLectureData,
  setSlideNumbers,
  setIsPreview,
  setEvalInProgress,
  setShowPreviousButton,
  setIsHTML,
  setIsLastStep,
  setPollData,
} = htmlSlidesSlice.actions;

export const getPollData = (state) => state.HTMLSlides.pollData;
export const getIsLastStep = (state) => state.HTMLSlides.isLastStep;
export const getIsHTML = (state) => state.HTMLSlides.isHTML;
export const getShowPreviousButton = (state) =>
  state.HTMLSlides.showPreviousButton;
export const getEvalInProgress = (state) => state.HTMLSlides.evalInProgress;
export const getSlideNumber = (state) => state.HTMLSlides.slideData;
export const getPreview = (state) => state.HTMLSlides.isPreview;

export default htmlSlidesSlice.reducer;
