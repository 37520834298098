//Include Both Helper File with needed methods
import {
  deleteSchool,
  listOfSchoolsGoogleService,
  listOfSchoolsService,
  getSchools,
} from 'helpers/school';
import { successToastify } from 'helpers/toast';
import {
  setSchoolsList,
  setLoader,
  setTotalRecords,
  setAddOrUpdateLoader,
} from './reducer';

export const getSchoolsList = (user, history) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const { data, status } = await listOfSchoolsService();
    //console.log(data);
    status === 200 &&
      dispatch(setSchoolsList(data.records)) &&
      dispatch(setTotalRecords(data.totalRecords));
  } catch (error) {
    dispatch(setLoader(false));
  }
};

export const getSchoolsFromGoogle =
  (searchData, history) => async (dispatch) => {
    const {
      country = '',
      state = '',
      city = '',
      schoolKeyword = '',
    } = searchData;
    const { data, status } = await listOfSchoolsGoogleService(
      country,
      state,
      city,
      schoolKeyword,
    );
    //console.log(data);
    const resp = data.map((school) => ({
      ...school,
      label: school.name,
      value: school.place_id,
    }));
    if (status === 200) return resp;
  };

export const createSchool = (user, history) => async (dispatch) => {
  try {
    dispatch(setAddOrUpdateLoader(true));
    const { data, status } = await listOfSchoolsService();
    //console.log(data);
    status === 200 && dispatch(setSchoolsList(data));
    successToastify('School created successfully.');
    dispatch(setAddOrUpdateLoader(false));
  } catch (error) {
    dispatch(setAddOrUpdateLoader(false));
    // dispatch(apiError(error));
  }
};

export const deleteSchoolService = (schoolId, history) => async (dispatch) => {
  try {
    await deleteSchool(schoolId);
    successToastify('School removed successfully.');
  } catch (error) {
    // dispatch(apiError(error));
    //console.log('error in delete school service ', error);
  }
};

export const getSchoolsService = (id, history) => async (dispatch) => {
  const params = {
    filter: {
      id: id,
    },
    pageSize: 100,
  };
  return getSchools(params);
};
