import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  viewProfileInformation: null,
  updateProfileInformation: {
    status: null,
    message: null,
  },
  guardiansList: [],
  siblingsList: [],
  newGuardian: {
    status: null,
    message: null,
  },
  newSibling: {
    status: null,
    message: null,
  },
  verifyNewMobileNo: {
    status: null,
    message: null,
  },
  verifyNewMobileNoOTP: {
    status: null,
    message: null,
  },
  selectedStudent: null,
  classJoinedStudentInfo: null,
  errors: null,
  loading: false,
};

const StudentGuardianProfileInformationSlice = createSlice({
  name: 'StudentGuardianProfile',
  initialState,
  reducers: {
    viewProfileInformationFn: (state, action) => {
      state.viewProfileInformation = action.payload;
      state.loading = initialState.loading;
    },
    resetProfileInformationFn: (state, action) => {
      state.viewProfileInformation = initialState.viewProfileInformation;
      state.loading = initialState.loading;
    },
    updateProfileDetailsFn: (state, action) => {
      state.updateProfileInformation = action.payload;
      state.loading = initialState.loading;
    },
    resetUpdateProfileDetailsFn: (state, action) => {
      state.updateProfileInformation.status =
        initialState.updateProfileInformation.status;
      state.updateProfileInformation.message =
        initialState.updateProfileInformation.message;
      state.loading = initialState.loading;
    },
    setGuardiansListFn: (state, action) => {
      state.guardiansList = action.payload;
      state.loading = initialState.loading;
    },
    setSiblingsListFn: (state, action) => {
      state.siblingsList = action.payload;
      state.loading = initialState.loading;
    },
    resetGuardiansSiblingsListFn: (state, action) => {
      state.siblingsList = initialState.siblingsList;
      state.guardiansList = initialState.guardiansList;
      state.loading = initialState.loading;
    },
    saveNewGuardianFn: (state, action) => {
      state.newGuardian = action.payload;
      state.loading = initialState.loading;
    },
    resetSaveNewGuardianFn: (state, action) => {
      state.newGuardian.status = initialState.newGuardian.status;
      state.newGuardian.message = initialState.newGuardian.message;
      state.loading = initialState.loading;
    },
    saveNewSiblingFn: (state, action) => {
      state.newSibling = action.payload;
      state.loading = initialState.loading;
    },
    resetSaveNewSiblingFn: (state, action) => {
      state.newSibling.status = initialState.newSibling.status;
      state.newSibling.message = initialState.newSibling.message;
      state.loading = initialState.loading;
    },
    verifyNewMobileNumberFn: (state, action) => {
      state.verifyNewMobileNo = action.payload;
      state.loading = initialState.loading;
    },
    resetVerifyNewMobileNumberFn: (state, action) => {
      state.verifyNewMobileNo.status = initialState.verifyNewMobileNo.status;
      state.verifyNewMobileNo.message = initialState.verifyNewMobileNo.message;
      state.loading = initialState.loading;
    },
    verifyNewMobileNumberOTPFn: (state, action) => {
      state.verifyNewMobileNoOTP = action.payload;
      state.loading = initialState.loading;
    },
    resetVerifyNewMobileNumberOTPFn: (state, action) => {
      state.verifyNewMobileNoOTP.status =
        initialState.verifyNewMobileNoOTP.status;
      state.verifyNewMobileNoOTP.message =
        initialState.verifyNewMobileNoOTP.message;
      state.loading = initialState.loading;
    },
    setSelectStudentFn: (state, action) => {
      state.selectedStudent = action.payload;
      state.loading = initialState.loading;
    },
    resetSelectStudentFn: (state, action) => {
      state.selectedStudent = initialState.selectedStudent;
      state.loading = initialState.loading;
    },
    setClassJoinedStudentInfoFn: (state, action) => {
      state.classJoinedStudentInfo = action.payload;
      state.loading = initialState.loading;
    },
    resetClassJoinedStudentInfoFn: (state, action) => {
      state.classJoinedStudentInfo = initialState.classJoinedStudentInfo;
      state.loading = initialState.loading;
    },
    catchErrorsFn: (state, action) => {
      state.errors = action.payload;
      state.loading = initialState.loading;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const getProfileInformationRedux = (state) =>
  state?.StudentGuardianProfile?.viewProfileInformation || null;
export const getUpdateProfileInformationRedux = (state) =>
  state?.StudentGuardianProfile?.updateProfileInformation || null;
export const getGuardiansListRedux = (state) =>
  state?.StudentGuardianProfile?.guardiansList || [];
export const getSiblingsListRedux = (state) =>
  state?.StudentGuardianProfile?.siblingsList || [];
export const getNewGuardianStatusRedux = (state) =>
  state?.StudentGuardianProfile?.newGuardian || null;
export const getNewSiblingStatusRedux = (state) =>
  state?.StudentGuardianProfile?.newSibling || null;
export const getVerifyNewMobileNoRedux = (state) =>
  state?.StudentGuardianProfile?.verifyNewMobileNo || null;
export const getVerifyNewMobileNumberOTPRedux = (state) =>
  state?.StudentGuardianProfile?.verifyNewMobileNoOTP || null;
export const getSelectedStudentInfoRedux = (state) =>
  state?.StudentGuardianProfile?.selectedStudent || null;
export const getClassJoinedStudentInfoRedux = (state) =>
  state?.StudentGuardianProfile?.classJoinedStudentInfo || null;
export const getStudentProfileInfoLoaderRedux = (state) =>
  state?.StudentGuardianProfile?.loading || false;

export const {
  viewProfileInformationFn,
  resetProfileInformationFn,
  updateProfileDetailsFn,
  resetUpdateProfileDetailsFn,
  setGuardiansListFn,
  setSiblingsListFn,
  saveNewGuardianFn,
  resetSaveNewGuardianFn,
  saveNewSiblingFn,
  resetSaveNewSiblingFn,
  verifyNewMobileNumberFn,
  resetVerifyNewMobileNumberFn,
  verifyNewMobileNumberOTPFn,
  resetVerifyNewMobileNumberOTPFn,
  setSelectStudentFn,
  resetSelectStudentFn,
  setClassJoinedStudentInfoFn,
  resetClassJoinedStudentInfoFn,
  resetGuardiansSiblingsListFn,
  catchErrorsFn,
  setLoader,
} = StudentGuardianProfileInformationSlice.actions;

export default StudentGuardianProfileInformationSlice.reducer;
