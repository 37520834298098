import { APIClient } from './api_helper';
import * as url from './url_helper';
import moment from 'moment';

const api = new APIClient();

// List Of Country/States/Cities
export const listOfCitiesService = () => api.get(url.LIST_OF_CITIES);

// List Of Configuarations
export const listOfConfiguarationDataService = () =>
  api.get(url.LIST_OF_CONFIGUARATIONS);

export const getCurrentTime = () => api.get(url.GET_CURRENT_TIME);

export const uploadSanityAssets = (data, headerInfo) => {
  return api.create(url.UPLOAD_SANITY_ASSETS, data, headerInfo);
};

export const getFormatTimeRange = (time) => {
  const start = time ? moment(time) : null;
  const timeFormat = start && start.minutes() !== 0 ? 'h:mm A' : 'h A';
  const formattedTime = start ? start.format(timeFormat) : null;

  return formattedTime;
};
