import {
  listOfCitiesService,
  listOfConfiguarationDataService,
} from 'helpers/utilities';

import {
  configuarationSuccess,
  resetUtils,
  utilsFailed,
  setCitiesWithStates,
  setBoardsList,
  setGradeList,
  setSubjectsList,
  setBooksList,
  setChaptersList,
  setTopicsList,
  setSubTopicsList,
  setRolesList,
  setBatchTypesList,
  setLoader,
} from './reducer';
import { functionWrapper } from 'helpers/errors';
import { listOfBoardsService } from 'helpers/board';
import { listOfGradesService } from 'helpers/grade';
import { listOfSubjectsService } from 'helpers/subject';
import { listOfBooks } from 'helpers/book';
import { listOfChapters } from 'helpers/chapter';
import { listOfTopics } from 'helpers/topic';
import { listOfSubTopics } from 'helpers/subTopic';
import { listOfRole } from 'helpers/role';
import { listOfBatchTypes } from 'helpers/batchType';

export const getListOfConfiguarations = () => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    let data = await listOfConfiguarationDataService();
    if (data?.status === 200) {
      dispatch(configuarationSuccess(data));
    } else {
      data = { ...data, methodName: 'configuarationData' };
      dispatch(utilsFailed(data));
    }
  } catch (e) {
    const error = { ...e, methodName: 'configuarationData' };
    dispatch(utilsFailed(error));
    dispatch(setLoader(false));
  }
};

export const resetUtilsFlag = () => async (dispatch) => {
  try {
    // const response = dispatch(resetLogin());
    // return response;
  } catch (error) {
    dispatch(utilsFailed(error));
  }
};

export const getCitiesData = () => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const response = await listOfCitiesService();
    if (
      response?.data?.cities?.length > 0 ||
      response?.data?.states?.length > 0
    ) {
      const cities =
        response?.data?.cities.map((city) => ({
          ...city,
          value: city.id,
          label: city.name,
        })) || [];
      let states =
        response?.data?.states.map((state) => ({
          ...state,
          value: state.state_id,
          label: state.state_name,
        })) || [];
      const countries =
        [
          {
            value: response?.data?.countries.id,
            label: response?.data?.countries.name,
          },
        ] || [];

      states = states.sort((a, b) => a.state_name.localeCompare(b.state_name));
      dispatch(setCitiesWithStates({ cities, states, countries }));
      return { cities, states, countries };
    }
  } catch (error) {
    dispatch(setLoader(false));
  }
};

export const getListOfBoard = () => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const response = await listOfBoardsService();
    if (response?.data?.length > 0) {
      const data =
        response?.data.map((board) => ({
          ...board,
          value: board.id,
          label: board.name,
        })) || [];
      dispatch(setBoardsList(data));
    }
  } catch (error) {
    dispatch(setLoader(false));
    //console.log('error in get list board ', error);
  }
};

export const getListOfGrade = () => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const response = await listOfGradesService();
    if (response?.data?.length > 0) {
      const data = response?.data || [];
      dispatch(setGradeList(data));
    }
  } catch (e) {
    dispatch(setLoader(false));
  }
};

export const getListOfSubject = () => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const response = await listOfSubjectsService();
    if (response?.data?.length > 0) {
      const data = response?.data || [];
      dispatch(setSubjectsList(data));
    }
  } catch (e) {
    dispatch(setLoader(false));
  }
};

export const getListOfBook = (gradeId, boardId) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    //console.log('in thunk utils get gradeId, boardId', { gradeId, boardId });
    const response = await listOfBooks(gradeId, boardId);
    if (response?.data?.records?.length > 0) {
      const data = response?.data?.records || [];
      dispatch(setBooksList(data));
    }
  } catch (error) {
    dispatch(setLoader(false));
    // console.log('errror in get books list ', error);
  }
};

export const getListOfChapter = () => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const response = await listOfChapters();
    if (response?.data?.records?.length > 0) {
      const data = response?.data?.records || [];
      dispatch(setChaptersList(data));
    }
  } catch (e) {
    dispatch(setLoader(false));
  }
};

export const getListOfChapterWithFilter = (dataToPost) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    // console.log({dataToPost});
    const response = await listOfChapters(dataToPost);
    if (response?.data?.records?.length > 0) {
      const data = response?.data?.records || [];
      return data;
    }
  } catch (e) {
    dispatch(setLoader(false));
  }
};

// export const getListOfChapterWithFilters = (filter) => async (dispatch) => {
//   const response = await listOfChaptersWithFilter(filter);
//   if (response?.data?.records?.length > 0) {
//     const data = response?.data?.records || [];
//     dispatch(setChaptersList(data));
//   }
// };

export const getListOfTopic = () => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const response = await listOfTopics();
    if (response?.data?.records?.length > 0) {
      const data = response?.data?.records || [];
      //console.log('topics -- ', data);
      dispatch(setTopicsList(data));
    }
  } catch (e) {
    dispatch(setLoader(false));
  }
};

export const getListOfSubTopic = () => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const response = await listOfSubTopics();
    if (response?.data?.records?.length > 0) {
      const data = response?.data?.records || [];
      // console.log(data);
      dispatch(setSubTopicsList(data));
    }
  } catch (e) {
    dispatch(setLoader(false));
  }
};

export const getListOfRoles = () => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const response = await listOfRole();
    if (response?.data?.length > 0) {
      const data = response?.data || [];
      dispatch(setRolesList(data));
    }
  } catch (e) {
    dispatch(setLoader(false));
  }
};

export const getListOfBatchTypesService = () => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const response = await listOfBatchTypes();
    if (response?.data?.length > 0) {
      const data = response?.data || [];
      dispatch(setBatchTypesList(data));
    }
  } catch (e) {
    dispatch(setLoader(false));
  }
};
