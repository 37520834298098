import { APIClient } from './api_helper';
import * as url from './url_helper';

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem('user');
  if (user) return JSON.parse(user);
  return null;
};

// //is student/guardian is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

/*******Registration Steps*********/

// Validate/Initiate MobileNo Checks (Register)
export const initiateUserRegistrationService = (data) =>
  api.create(url.INITIALIZE_REGISTRATION, data);

// Verify MobileNo OTP (Register)
export const verifyUserRegistrationOTPService = (data) =>
  api.create(url.VERIFY_OTP_REGISTRATION, data);

// Resend MobileNo OTP (Register)
export const resendRegistrationOTPService = (data) =>
  api.create(url.RESEND_OTP_REGISTRATION, data);

// Save User Details (Register)
export const saveUserRegistrationDetailsService = (data, headerInfo = null) =>
  api.create(url.SAVE_USERDETAILS_REGISTRATION, data, headerInfo);

// Set Passcode (Register)
export const setUserPasscodeService = (data, headerInfo = null) =>
  api.create(url.SAVE_PASSCODE_REGISTRATION, data, headerInfo);

/*******Login Steps*********/

// Validate/Initiate MobileNo Checks (Login)
export const initiateLoginService = (data, headerInfo = null) =>
  api.create(url.VALIDATE_INITIAL_LOGIN, data, headerInfo);

// Passcode Status Checks (Login)
export const checkPasscodeStatusService = (userId = null) => {
  const queryParams = `?userId=${userId}`;
  return api.get(url.CHECK_PASSCODE_STATUS + queryParams);
};

// Verify Passcode (Login)
export const verifyLoginPasscodeService = (data, headerInfo = null) =>
  api.create(url.VERIFY_PASSCODE_LOGIN, data, headerInfo);

// List Of Accounts (Login)
export const listOfAccountHoldersService = (data) =>
  api.get(url.LIST_OF_ACCOUNTS_LOGIN, data);

// Login with TempToken (Login)
export const loginAccountWithTempTokenService = (data, headerInfo = null) =>
  api.create(url.LOGIN_WITH_TEMP_TOKEN, data, headerInfo);

/*******Forgot Passcode/ Create/Generate New Passcode Steps*********/

// Validate/Initiate MobileNo Checks (ForgotPasscode)
export const initiateForgotPasscodeService = (data) =>
  api.create(url.VALIDATE_INITIAL_FORGOTPASSCODE, data);

// Verify OTP (ForgotPasscode)
export const verifyForgotPasscodeOtpService = (data) =>
  api.create(url.VERIFY_OTP_FORGOTPASSCODE, data);

// Create/Set New Passcode (Create New Passcode)
export const createNewPasscodeService = (data, headerInfo = null) =>
  api.create(url.SAVE_PASSCODE_RESETPASSCODE, data, headerInfo);

/*******Profile Info Steps (Students/Guardians)*********/

// Get Account Details (Profile Information)
export const getAccountDetailsService = (data) =>
  api.get(url.GET_ACCOUNT_DETAILS, data);

// Update Account Details (Profile Information)
export const updateAccountDetailsService = (data, headerInfo = null) =>
  api.put(url.UPDATE_ACCOUNT_DETAILS, data);

// Get Guardians/Siblings List (Profile Information)
export const getGuardiansSiblingsListService = (data, headerInfo = null) =>
  api.create(url.GET_GUARDIAN_SIBLING_LIST, data, headerInfo);

// Add New Guardian (Profile Information)
export const saveOrAddNewGuardianService = (data, headerInfo = null) =>
  api.create(url.ADD_NEW_GUARDIAN, data, headerInfo);

// Add New Sibling (Profile Information)
export const saveOrAddNewSiblingService = (data, headerInfo = null) =>
  api.create(url.ADD_NEW_SIBLING, data, headerInfo);

// Verify New Mobile No. (Profile Information)
export const verifyNewMobileNumberService = (data, headerInfo = null) =>
  api.create(url.VERIFY_NEW_MOBILE_NO, data, headerInfo);

// Verify New Mobile No. OTP (Profile Information)
export const verifyNewMobileNumberOTPService = (data, headerInfo = null) =>
  api.put(url.VERIFY_NEW_MOBILE_NO_OTP, data, headerInfo);

// Invite Guardian/Student (Admin Panel)
export const inviteOrAddGuardianStudent = (data) =>
  api.create(url.INVITE_OR_ADD_GUARDIAN_STUDENT, data);

export const createStudentGuardianTemp = (data, headerInfo = null) => {
  return api.create(url.CREATE_STUDENT_GUARDIAN_TEMP, data, headerInfo);
};

// Send Whatsapp message to Guardian/Student mobileNo (Admin Panel)
export const sendWhatsappMessage = (data, headerInfo = null) =>
  api.create(url.SEND_WHATSAPP_MESSAGE, data, headerInfo);

// Get Guardian/Student Via Id (Admin Panel)
export const GetGuardianStudentViaId = (data, headerInfo = null) =>
  api.create(url.GET_STUDENT_GUARDIAN_INFO_VIA_ID, data, headerInfo);

export const getStudent = (data, headerInfo = null) => {
  const studentData = {
    studentId: data,
  };
  const queryParams = prepareQueryParams(studentData);
  const apiUrl = url.GET_STUDENT + queryParams;

  return api.get(apiUrl, headerInfo);
};

export const prepareQueryParams = (data = '') => {
  let queryParams;
  if (data) {
    queryParams = new URLSearchParams(data)?.toString();
    if (queryParams) {
      queryParams = '?' + queryParams;
    }
  }

  return queryParams;
};
/*******OTHER*********/

//API Key
export const getAPIKey = () => api.get(url.GET_API_KEY);

export const loginAdmin = (data, headerInfo = null) =>
  api.get(url.ADMIN_LOGIN, data, headerInfo);
