import { APIClient } from './api_helper';
import * as url from './url_helper';

const api = new APIClient();

// List Of Grades
export const listOfChapters = (dataToPost) =>
  api.create(url.LIST_OF_CHAPTERS, dataToPost);

export const createChapter = (data) => {
  return api.create(url.CREATE_CHAPTER, data);
};
export const deleteChapter = (id) => {
  console.log('delete chapter id', id);
  return api.put(url.DELETE_CHAPTER + '?id=' + id);
};
export const updateChapter = (id, data) => {
  return api.put(url.UPDATE_CHAPTER + '?id=' + id, data);
};
