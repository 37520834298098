import {
  initiateUserRegistrationService,
  verifyUserRegistrationOTPService,
  resendRegistrationOTPService,
  saveUserRegistrationDetailsService,
  setUserPasscodeService,
} from 'helpers/auth';

// action
import {
  initiateUserRegistrationSuccessful,
  verifyUserRegistrationOTPSuccessful,
  resendRegistrationOTPSuccessful,
  saveUserRegistrationDetailsSuccessful,
  setUserPasscodeSuccessful,
  registrationFailed,
  resetRegistration,
  resetErrors,
  setLoader,
} from './reducer';

// Is user mobileNo check is successfull then direct plot user in redux.
export const initiateUserRegistration = (payload) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    let data = await initiateUserRegistrationService(payload);
    if (data?.status === 200) {
      dispatch(initiateUserRegistrationSuccessful(data));
    } else {
      data = { ...data, methodName: 'initiateRegistration' };
      dispatch(registrationFailed(data));
    }
  } catch (e) {
    const error = { ...e, methodName: 'initiateRegistration' };
    dispatch(registrationFailed(error));
  }
};

// Is user mobileNo OTP is successfull then direct plot user in redux.
export const verifyUserRegistrationOTP = (payload) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    let data = await verifyUserRegistrationOTPService(payload);
    if (data?.status === 200) {
      dispatch(verifyUserRegistrationOTPSuccessful(data));
    } else {
      data = { ...data, methodName: 'verifyRegistrationOTP' };
      dispatch(registrationFailed(data));
    }
  } catch (e) {
    const error = { ...e, methodName: 'verifyRegistrationOTP' };
    dispatch(registrationFailed(error));
  }
};

// Is user resend OTP is successfull then direct plot user in redux.
export const resendRegistrationOTP = (payload) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    let data = await resendRegistrationOTPService(payload);
    if (data?.status === 200) {
      dispatch(resendRegistrationOTPSuccessful(data));
    } else {
      data = { ...data, methodName: 'resendPasscode' };
      dispatch(registrationFailed(data));
    }
  } catch (e) {
    const error = { ...e, methodName: 'resendPasscode' };
    dispatch(registrationFailed(error));
  }
};

// Is user registration details is saved successfull then direct plot user in redux.
export const saveUserRegistrationDetails =
  (payload, verifyOtpInfo = {}) =>
  async (dispatch) => {
    try {
      let headersObj;
      if (verifyOtpInfo?.data?.tempToken) {
        headersObj = {
          headers: {
            Authorization: `Bearer ${verifyOtpInfo?.data?.tempToken}`,
          },
        };
      }

      dispatch(setLoader(true));
      let data = await saveUserRegistrationDetailsService(payload, headersObj);
      if (data?.status === 200) {
        dispatch(saveUserRegistrationDetailsSuccessful(data));
      } else {
        data = { ...data, methodName: 'saveRegistrationDetails' };
        dispatch(registrationFailed(data));
      }
    } catch (e) {
      const error = { ...e, methodName: 'saveRegistrationDetails' };
      dispatch(registrationFailed(error));
    }
  };

// Is user registration details is saved successfull then direct plot user in redux.
export const setUserPasscode =
  (payload, userRegistrationInfo = {}) =>
  async (dispatch) => {
    try {
      let headersObj;
      if (userRegistrationInfo?.data?.tempToken) {
        headersObj = {
          headers: {
            Authorization: `Bearer ${userRegistrationInfo?.data?.tempToken}`,
          },
        };
      }

      dispatch(setLoader(true));
      let data = await setUserPasscodeService(payload, headersObj);
      if (data?.status === 200) {
        dispatch(setUserPasscodeSuccessful(data));
      } else {
        data = { ...data, methodName: 'savePasscode' };
        dispatch(registrationFailed(data));
      }
    } catch (e) {
      const error = { ...e, methodName: 'savePasscode' };
      dispatch(registrationFailed(error));
    }
  };

export const resetRegisterFlag = () => async (dispatch) => {
  try {
    dispatch(resetRegistration());
  } catch (error) {
    dispatch(registrationFailed(error));
  }
};

export const resetInitiateRegistrationErrors = () => async (dispatch) => {
  const info = { methodName: 'initiateRegistration' };
  dispatch(resetErrors(info));
};

export const resetVerifyRegistrationOTPErrors = () => async (dispatch) => {
  const info = { methodName: 'verifyRegistrationOTP' };
  dispatch(resetErrors(info));
};

export const resetResendPasscodeErrors = () => async (dispatch) => {
  const info = { methodName: 'resendPasscode' };
  dispatch(resetErrors(info));
};

export const resetSaveRegistrationDetailsErrors = () => async (dispatch) => {
  const info = { methodName: 'saveRegistrationDetails' };
  dispatch(resetErrors(info));
};

export const resetSavePasscodeErrors = () => async (dispatch) => {
  const info = { methodName: 'savePasscode' };
  dispatch(resetErrors(info));
};
