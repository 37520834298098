import { APIClient } from './api_helper';
import * as url from './url_helper';

const api = new APIClient();

// List Of Students (Admin)
export const listOfGuardians = (data = null, headerInfo = null) => {
  return api.create(url.LIST_OF_GUARDIAN, data, headerInfo);
};

// List Of Students (Admin)
export const listOfStudents = (data = null, headerInfo = null) => {
  let queryParams;
  let apiUrl = url.LIST_OF_STUDENT;
  if (data && typeof data === 'object') {
    queryParams = prepareQueryParams(data);
    apiUrl = apiUrl + queryParams;
  }

  return api.get(apiUrl, headerInfo);
};

// Create Student (Admin)
export const createStudentGuardian = (data, headerInfo = null) =>
  api.create(url.STUDENT_GUARDIAN, data, headerInfo);

// Update Student (Admin)
export const updateStudentGuardian = (studentId, data, headerInfo = null) =>
  api.put(url.STUDENT_GUARDIAN + studentId, data, headerInfo);

// Delete Student/Guardians (Admin)
export const deleteStudentGuardian = (data, headerInfo = null) => {
  const { studentId } = data;
  api.delete(url.DELETE_STUDENT_GUARDIAN + `/${studentId}`, headerInfo);
};

// Create Guardian (Admin)
export const createGuardian = (data, headerInfo = null) =>
  api.create(url.CREATE_GUARDIAN_ADMIN, data, headerInfo);

// Update Guardian (Admin)
export const updateGuardian = (data, headerInfo = null) =>
  api.put(url.UPDATE_GUARDIAN_ADMIN, data, headerInfo);

export const getStudentFamilyDetails = (data = null) => {
  let queryParams;
  const id = data.id;
  let apiUrl = `${url.LIST_OF_FAMILY_DETAILS}/${id}/families`;
  if (data && typeof data === 'object') {
    queryParams = prepareQueryParams(data);
    apiUrl = apiUrl + queryParams;
  }
  return api.get(apiUrl);
};

export const prepareQueryParams = (data = '') => {
  let queryParams;
  if (data) {
    queryParams = new URLSearchParams(data)?.toString();
    if (queryParams) {
      queryParams = '?' + queryParams;
    }
  }

  return queryParams;
};

export const sendPasscode = async (studentId) => {
  const apiUrl = `${url.LIST_OF_FAMILY_DETAILS}/${studentId}/send-passcode`;
  return api.get(apiUrl);
};

export const getSamDetailsAgainstStudent = async (payload) => {
  return api.create(`${url.GET_SAM_AGAINST_STUDENTS_URL}`, payload);
};

export const uploadPicture = async (
  studentId,
  batchId,
  payload,
  headers = {},
) => {
  return api.update(
    `${url.LIST_OF_STUDENT_V2}/${String(batchId)}/${studentId}`,
    payload,
    headers,
  );
};

export const getSubscribedSubjects = async (batchId, headers = {}) => {
  return api.get(
    `${url.LIST_OF_STUDENT_V2}/${batchId}/subscription-subjects`,
    headers,
  );
};
