import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  segments: [],
  totalRecords: 0,
  loading: false,
};

const adminSegmentSlice = createSlice({
  name: 'segmentList',
  initialState,
  reducers: {
    setSegmentsList: (state, action) => {
      state.segments = action.payload;
      state.loading = initialState.loading;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
    setTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
  },
});

export const getSegmentsListRedux = (state) => state.AdminSegment.segments;
export const getSegmentsListLoaderRedux = (state) => state.AdminSegment.loading;
export const getTotalRecordsRedux = (state) => state.AdminSegment.totalRecords;
export const { setSegmentsList, setLoader, setTotalRecords } =
  adminSegmentSlice.actions;

export default adminSegmentSlice.reducer;
