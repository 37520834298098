import { createSlice } from '@reduxjs/toolkit';
import { datadogLogs } from '@datadog/browser-logs';

export const initialState = {
  user: {},
  token: '',
  loggedIn: false,
  loading: false,
};

const signInSlice = createSlice({
  name: 'signIn',
  initialState,
  reducers: {
    userSignIn: (state, action) => {
      const { user, token, loggedIn } = action.payload;
      datadogLogs.setUser({
        id: user?.id,
        roleId: user?.roleId,
        firstName: user?.firstName,
      });
      state.user = user;
      state.token = token;
      state.loggedIn = loggedIn;
    },
    signOutAdmin: (state, action) => {
      const { loggedIn } = action.payload;
      state.user = initialState.user;
      state.token = initialState.token;
      state.loggedIn = loggedIn;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const getUserDetails = (state) => state.AdminAuth.user;
export const getUserToken = (state) => state.AdminAuth.token;
export const getLoginStatus = (state) => state.AdminAuth.loggedIn;

export const { userSignIn, signOutAdmin, setLoader } = signInSlice.actions;

export default signInSlice.reducer;
