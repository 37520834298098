const PublicPath = {
  ROOT: '/',
  REGISTER: '/register',
  LOGIN: '/login',
  ACCOUNT_HOLDERS: '/account-holders',
  FORGOT_PASSCODE: '/forgot-passcode',
  RESET_PASSCODE: '/new-passcode',
  CLASSROOM: '/classroom',
  LOGIN_STATUS: '/login/:status',
  ADMIN_LOGIN: '/login',
  LOGIN_WITH_GOOGLE: '/login/google',
  LOGIN_WITH_ZOHO: '/login/zoho',
  ADMIN_CLASSROOM: '/classroom',
  TERMS_CONDITIONS: '/page/terms-conditions',
  STUDENT_REPORT: 'student/report',
  NOT_FOUND: '/*',
};

export default PublicPath;
