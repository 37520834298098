import React, { useEffect } from 'react';
import { Navigate, useNavigate, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setAuthorization } from 'helpers/api_helper';

import { useProfile } from 'Components/Hooks/UserHooks';
import { logout } from 'slices/student-guardian/auth/login/thunk';
import {
  getLoginStatus,
  getUserToken,
  signOutAdmin,
  userSignIn,
} from 'slices/admin/auth/reducer';
import Cookies from 'js-cookie';

const AdminGuard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginStatus = useSelector(getLoginStatus);
  const token = useSelector(getUserToken);
  const tokeninLocal = Cookies.get(`${process.env.REACT_APP_DOMAIN_ENV}Token`);
  const userinLocal = JSON.parse(localStorage.getItem('adminUser'));
  useEffect(() => {
    if (!tokeninLocal) {
      dispatch(signOutAdmin({ loggedIn: false }));
      navigate('/login');
    }
    // if (!token) {
    //   dispatch(
    //     userSignIn({ user: userinLocal, token: tokeninLocal, loggedIn: true }),
    //   );
    //   setAuthorization(tokeninLocal);
    // } else {
    //   setAuthorization(token);
    // }

    if (!loginStatus) {
      dispatch(signOutAdmin({ loggedIn: false }));
      navigate('/login');
    }
  }, [loginStatus]);

  /*
    Navigate is un-auth access protected routes via url
  */
  if (!loginStatus) {
    return (
      <Navigate to={{ pathname: '/login', state: { from: props.location } }} />
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {' '}
            <Component {...props} />{' '}
          </>
        );
      }}
    />
  );
};

export { AdminGuard, AccessRoute };
