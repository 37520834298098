import React from 'react';
import { chevron, logoDark } from 'assets/images';

const Footer = ({ classname = '' }) => {
  return (
    <div className={`print-footer ${classname}`}>
      <div className="footer-logo m-2">
        <div>
          <img src={chevron} height="100%" width="100%" alt="footer_logo" />
        </div>
        <div className="me-4">
          <img src={logoDark} height="100%" width="100%" alt="footer_logo" />
        </div>
      </div>
      <div className="footer-div"></div>
    </div>
  );
};

export default Footer;
