import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import {
  getProfileInformationRedux,
  getSelectedStudentInfoRedux,
} from 'slices/student-guardian/account/profile/reducer';
import { getUserDetails } from 'slices/admin/auth/reducer';
import { getJoinClassroomTokenRedux } from 'slices/student-guardian/account/dashboard/reducer';
import { base } from 'config';
import { useSearchParams, useParams } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';

const useSocketConnection = (props) => {
  const [socket, setSocket] = useState(null);
  const authUserInfo = useSelector(getProfileInformationRedux);
  const selectedStudentData = useSelector(getSelectedStudentInfoRedux);
  const getTeacherAuth = useSelector(getUserDetails);
  const studentClassDetails = useSelector(getJoinClassroomTokenRedux);
  const params = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let id = '';
    let classId = '';
    let userType = authUserInfo?.userType;
    const authToken = searchParams.get('authToken');
    if (authUserInfo?.userType === base?.USER_TYPES?.GUARDIAN) {
      userType = base?.USER_TYPES?.GUARDIAN;
      classId = params?.id;
      id = selectedStudentData?.members?.id || '';
    } else if (
      authToken ||
      authUserInfo?.userType === base?.USER_TYPES?.STUDENT
    ) {
      userType = base?.USER_TYPES?.STUDENT;
      classId = studentClassDetails?.classId;
      id = studentClassDetails?.studentId || authUserInfo?.id;
    } else {
      classId = searchParams.get('id');
      userType = 'tutor';
      id = getTeacherAuth?.id || searchParams.get('assitantId') || '';
    }
    if (id && userType && classId) {
      const socketFinal = io(process.env.REACT_APP_VIDEO_ANALYTICS_BE, {
        query: {
          id,
          userType,
          classId,
        },
        transports: ['websocket'],
      });

      setSocket(socketFinal);
      socketFinal.on('connect', () => {
        datadogLogs.logger.info(
          `StudentID: ${id}, classId: ${classId}, userType: ${userType} Socket Connected`,
          { userType, classId, studentId: id },
        );
      });
    }
    // Cleanup the interval on component unmount
    return () => {
      socket?.disconnect(); // clearInterval(intervalId);
    };
  }, []);

  return socket;
};

export default useSocketConnection;
