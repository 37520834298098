export const listOfCountry = [
  {
    value: 1,
    label: 'India',
  },
];

export const listOfStates = [
  {
    value: 1,
    label: 'Madhaya Pradesh',
  },
];

export const listOfCities = [
  {
    value: 1,
    label: 'Indore',
  },
  {
    value: 2,
    label: 'Bhopal',
  },
  {
    value: 3,
    label: 'Jabalpur',
  },
];

export const listOfGrades = [
  {
    value: 1,
    label: 'Indore',
  },
  {
    value: 2,
    label: 'Bhopal',
  },
  {
    value: 3,
    label: 'Jabalpur',
  },
];

export const listOfGender = [
  {
    value: 'male',
    label: 'Male',
  },
  {
    value: 'female',
    label: 'Female',
  },
];

export const listOfCategory = [
  {
    value: 'group_1',
    label: 'Group 1',
  },
  {
    value: 'group_2',
    label: 'Group 2',
  },
];

export const listOfRelationWithChild = [
  {
    value: 'father',
    label: 'Father',
  },
  {
    value: 'mother',
    label: 'Mother',
  },
  {
    value: 'other',
    label: 'Other',
  },
];
