import React from 'react';
import { Input, Table } from 'reactstrap';

const QcWiseUnderstandingLevel = ({
  studentReportData,
  editableData,
  QcWiseUnderstandingLevelHeaders,
  handleChange,
  getUnderstandingLevel,
  getAssessmentStatus,
}) => {
  return (
    <>
      {/* question category wise understanding level */}
      <section className="p-2 understanding-level-div">
        <h2
          className="text-center text-white p-3"
          style={{ backgroundColor: '#00bbb4' }}
        >
          {`Chapter: ${studentReportData?.chapterName}`}
        </h2>
        <h4
          className="text-center text-white p-2"
          style={{ backgroundColor: '#00bbb4' }}
        >
          Question Family wise Understanding Summary
        </h4>
        <Table bordered responsive>
          <thead style={{ backgroundColor: '#00bbb4', color: 'white' }}>
            <tr>
              {QcWiseUnderstandingLevelHeaders?.map((header, index) => (
                <th key={header.key}>{header.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {editableData?.map((item, index) => (
              <tr key={index}>
                <td>{`Family ${item.familyNumber}`}</td>
                <td style={{ width: '200px' }}>{item.questionFamilyName}</td>
                <td>{getAssessmentStatus(item.questionsAttempted)}</td>
                <td>{item.questionsAttempted}</td>
                <td>{`${item.correctAnswers} (${((item.correctAnswers / item.questionsAttempted) * 100).toFixed(2)}%)`}</td>
                <td className="stars">
                  {getUnderstandingLevel(item.qfGapStatus)}
                </td>
                <td>
                  <Input
                    type="textarea"
                    value={item.skillsToBeTaught}
                    maxLength={75}
                    className="custom-input-style"
                    onChange={(e) =>
                      handleChange(e, item.questionFamilyId, 'skillsToBeTaught')
                    }
                  />
                </td>
                <td>
                  <Input
                    type="textarea"
                    value={item.sillyMistakes}
                    className="custom-input-style"
                    maxLength={75}
                    onChange={(e) =>
                      handleChange(e, item.questionFamilyId, 'sillyMistakes')
                    }
                  />
                </td>
                <td>
                  <Input
                    type="textarea"
                    value={item.typeOfIntervention}
                    maxLength={30}
                    className="custom-input-style"
                    onChange={(e) =>
                      handleChange(
                        e,
                        item.questionFamilyId,
                        'typeOfIntervention',
                      )
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </section>
    </>
  );
};

export default QcWiseUnderstandingLevel;
