const appRegex = {
  validation: {
    mobileNoValidation: /^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/,
    // passcodeValidation: /^(?!(.)\1{3})(?!19|20)\d{4}$/,
    passcodeValidation: /^(?!19|20)\d{4}$/,
  },
  pattern: {
    mobileNoInput: '[6789][0-9]{9}',
    alphabetInput: '[a-zA-Z ]*',
    alphaNumericInput: '[a-zA-Z0-9s]+',
  },
  maxLength: {
    mobileNoInput: '10',
    passcodeInput: '4',
    otpInput: '4',
  },
};

export { appRegex };
