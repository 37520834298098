const appDefaultMessages = {
  success: {
    otpSenfSuccessfull: 'OTP sent successfully. Please verify your OTP',
    registrationSuccessfull:
      'Congratulations, Your registration has been completed successfully',
    passcodeResetSuccessfull:
      'Congratulations, Your passcode reset successfully',
    profileUpdatedSuccessfull:
      'Congratulations, Your profile updated successfully',
    guardianAddedSuccessfull: 'Parent added successfully',
    siblingAddedSuccessfull: 'Sibling added successfully',
    demoClassScheduledSuccessfull:
      'Congratulations, Your demo class scheduled successfully',
    invitationSentSuccessfull: 'Invitation sent successfully',
    addedSuccessfull: 'Added successfully',
    updatedSuccessfull: 'Updated successfully',
    deletedSuccessfull: 'Deleted successfully',
    passcodeSetSuccessfull:
      'Congratulations, Your passcode updated successfully',
    planCreatedSussessfull: 'Plan created scuuessfully',
    submittedFeedback: 'Feedback Submitted for approval',
  },
  info: {},
  warning: {},
  error: {
    citySchoolRequired:
      'The `City` and `Search your school` field must be required`',
    noSchoolFound:
      "Sorry! We haven't found schools with your search keywords. Please try again",
    somethingWentWrong: 'Something went wrong. Please try again',
    planCreatedFailed: 'Plan creatation failed',
    submitFeedback: 'Please Submit Feedback For All Student',
    feedbackFieldsMissing: 'Please Submit All Required Fields',
  },
  validation: {
    firstNameRequired: 'First Name is required',
    lastNameRequired: 'Last Name is required',
    studentNameRequired: "Student's name is required",
    guardianNameRequired: "Parent's name is required",
    emailRequired: 'Email address is required',
    validEmail: 'Please enter a valid email address',
    mobileNoRequired: 'Mobile Number is required',
    validMobileNo: 'Please enter a valid Mobile Number',
    otpRequired: 'OTP is required',
    validOTP: 'Please enter a valid OTP',
    genderRequired: 'Gender is required',
    relationshipRequired: 'Relationship is required',
    schoolRequired: 'School is required',
    passcodeRequired: 'Passcode is required',
    validPasscode: 'Passcode must be 4 digits only',
    confirmPasscodeRequired: 'Confirm passcode is required',
    countryRequired: 'Country is required',
    stateRequired: 'State is required',
    cityRequired: 'City is required',
    zipRequired: 'Zip Code is required',
    gradeRequired: 'Grade is required',
    boardRequired: 'Board is required',
    enterYourSchool: 'Please enter the name of your school',
    matchConfirmPasscode: 'Passcode and Confirm Passcode must be matched',
    categoryRequired: 'Category is required',
    demoClassDateRequired: 'Preference date is required',
    demoClassTimeRequired: 'Slot timings is required',
    demoClassTopicRequired: 'Topic is required',
  },
};

export { appDefaultMessages };
