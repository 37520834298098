import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  listData: [],
  errors: null,
  loading: false,
};

const listDetailsSlice = createSlice({
  name: 'Table',
  initialState,
  reducers: {
    setListData: (state, action) => {
      state.listData = action.payload;
      state.loading = initialState.loading;
    },

    setErrors: (state, action) => {
      state.errors = action.payload;
      state.loading = initialState.loading;
    },
    resetErrors: (state, action) => {
      state.errors = initialState.errors;
      state.loading = initialState.loading;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const getListDetailsRedux = (state) => state.Table.listData;
export const getListDetailsLoaderRedux = (state) => state.Table.loading;

export const { setListData, setErrors, resetErrors, setLoader } =
  listDetailsSlice.actions;

export default listDetailsSlice.reducer;
