import { APIClient } from './api_helper';
import * as url from './url_helper';

const api = new APIClient();

// List Of Schools (db)
export const listOfSchoolCurriculumsService = (value = '') => {
  return api.get(url.LIST_OF_SCHOOL_CURRICULUM);
};

export const schoolCurriculumByStudentId = (studentId) => {
  return api.get(url.SCHOOL_CURRICULUM_STUDENT_ID + studentId);
};

export const createSchoolCurriculum = (data) => {
  return api.create(url.CREATE_SCHOOL_CURRICULUM, data);
};

export const updateSchoolCurriculum = (data) => {
  return api.put(url.UPDATE_SCHOOL_CURRICULUM + `${data.id}`, data);
};

export const deleteSchoolCurriculum = (data) => {
  return api.delete(url.DELETE_SCHOOL_CURRICULUM + `/${data.id}`);
};

export const exportCurriculum = (data) => {
  return api.get(url.EXPORT_CURRICULUM);
};

export const importCurriculum = (data, headerInfo) => {
  return api.create(url.IMPORT_CURRICULUM, data, headerInfo);
};

export const getCurriculums = (query = {}) => {
  return api.get(url.GET_CURRICULUMS_V2, { params: query });
};
