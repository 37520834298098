import { APIClient } from './api_helper';
import { prepareQueryParams } from './classes';
import {
  ADD_UNDERSTANDING,
  UNDERSTANDING,
  UNDERSTANDING_CLASS,
} from './url_helper';

const api = new APIClient();

export const getUnderstandingForClass = (
  { classId, usage = 'tutor' },
  headerInfo = '',
) => {
  const queryParams = prepareQueryParams({ classId, usage });
  return api.get(UNDERSTANDING_CLASS + queryParams, headerInfo);
};

export const getUnderstandingForStudent = (
  { classId, studentId, usage = 'tutor' },
  headerInfo = '',
) => {
  const queryParams = prepareQueryParams({ classId, studentId, usage });
  return api.get(UNDERSTANDING + queryParams, headerInfo);
};

export const addUnderstanding = (data, headerInfo = '') =>
  api.create(ADD_UNDERSTANDING, data, headerInfo);
