import { APIClient } from './api_helper';
import * as url from './url_helper';

const api = new APIClient();

// List Of Demo Classes
export const listOfDemoClassesService = (data) =>
  api.get(url.LIST_OF_DEMO_CLASSES, data);

// Join/Apply for the Demo Classes
export const BookDemoClassService = (data) =>
  api.create(url.BOOK_OR_APPLY_FOR_DEMO_CLASS, data);

export const createDemoClassConfiguration = (data) => {
  return api.create(url.CREATE_DEMO_CLASS_CONFIGURATION, data);
};

export const updateDemoClassConfiguration = (id, data) => {
  return api.put(url.UPDATE_DEMO_CLASS_CONFIGURATION + id, data);
};

export const deleteDemoClass = (demoClassId) => {
  try {
    //console.log(url.DELETE_DEMO_CLASS + demoClassId);
    api.delete(url.DELETE_DEMO_CLASS + demoClassId);
  } catch (error) {
    //console.log('error at delete demo classes -- ', error);
  }
};

export const deleteDemoClassConfiuration = (id) => {
  return api.delete(url.DELETE_DEMO_CLASS_CONFIGURATION + id);
};
