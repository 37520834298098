import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  socket: {},
};

const socketSlice = createSlice({
  name: 'socketData',
  initialState,
  reducers: {
    setSocket: (state, action) => {
      state.socket = action.payload;
    },
  },
});

export const getSocket = (state) => state.SocketInstance.socket;

export const { setSocket } = socketSlice.actions;

export default socketSlice.reducer;
