import { createSlice } from '@reduxjs/toolkit';

const getCurrentClassId = () => {
  const params = new URLSearchParams(window.location.search);
  const classId = params.get('id');
  if (!classId) return null;
  return classId;
};

const initialState = {
  classData: {
    classId: '',
    configId: '',
    teach: {
      completedIndex: [],
      currentIndex: 0,
    },
    coach_basic: {
      completedIndex: [],
      currentIndex: 0,
    },
    coach_intermediate: {
      completedIndex: [],
      currentIndex: 0,
    },
    coach_advanced: {
      completedIndex: [],
      currentIndex: 0,
    },
  },
  timers: {},
  liveTimer: {},
  activeElement: {
    teach: {},
    coach_basic: {},
    coach_intermediate: {},
    coach_advanced: {},
  },
  activeHtmlSlide: {
    teach: 1,
    coach_basic: 1,
    coach_intermediate: 1,
    coach_advanced: 1,
  },
  activeHtmlStep: {
    teach: 0,
    coach_basic: 0,
    coach_intermediate: 0,
    coach_advanced: 0,
  },
  currentHtmlSlide: {
    teach: 1,
    coach_basic: 1,
    coach_intermediate: 1,
    coach_advanced: 1,
  },
  slideTimers: {},
  slideLiveTimer: {},
  currentElementIndex: {
    teach: 0,
    coach_basic: 0,
    coach_intermediate: 0,
    coach_advanced: 0,
  },
  pluginStatus: {
    teach: {
      whiteboard: {
        active: false,
        show: false,
      },
      slide: {
        active: false,
        show: false,
      },
    },
    coach_basic: {
      whiteboard: {
        active: false,
        show: false,
      },
      slide: {
        active: false,
        show: false,
      },
    },
    coach_intermediate: {
      whiteboard: {
        active: false,
        show: false,
      },
      slide: {
        active: false,
        show: false,
      },
    },
    coach_advanced: {
      whiteboard: {
        active: false,
        show: false,
      },
      slide: {
        active: false,
        show: false,
      },
    },
  },
  timerFlag: {},
};

const getInitialTimerState = () => ({
  teach: {},
  coach_basic: {},
  coach_intermediate: {},
  coach_advanced: {},
});

const getInitialLiveTimerState = () => ({
  teach: null,
  coach_basic: null,
  coach_intermediate: null,
  coach_advanced: null,
});

const getInitialSlideLiveTimerState = () => ({
  teach: {
    slideNo: null,
    timer: 0,
    elementId: null,
    elementName: null,
    elementSlideNo: null,
  },
  coach_basic: {
    slideNo: null,
    timer: 0,
    elementId: null,
    elementName: null,
    elementSlideNo: null,
  },
  coach_intermediate: {
    slideNo: null,
    timer: 0,
    elementId: null,
    elementName: null,
    elementSlideNo: null,
  },
  coach_advanced: {
    slideNo: null,
    timer: 0,
    elementId: null,
    elementName: null,
    elementSlideNo: null,
  },
});

const slideStatusSlice = createSlice({
  name: 'slideStatus',
  initialState,
  reducers: {
    setClassData: (state, action) => {
      const { classState, currentIndex, completedIndex, classId, configId } =
        action.payload;
      if (classId && configId) {
        state.classData.classId = classId;
        state.classData.id = configId;
      }
      if (state?.classData?.[classState]) {
        if (completedIndex !== undefined && completedIndex !== null) {
          state?.classData?.[classState]?.completedIndex?.push(completedIndex);
        }
        state.classData[classState].currentIndex = currentIndex;
      }
    },
    setTimers: (state, action) => {
      const { classState, timer } = action.payload;
      const classId = getCurrentClassId();
      if (classId) {
        if (!state.timers[classId]) {
          state.timers[classId] = getInitialTimerState();
        }
        state.timers[classId][classState] = timer;
      }
    },
    setLiveTimer: (state, action) => {
      const { classState, liveTimer } = action.payload;
      const classId = getCurrentClassId();
      if (classId) {
        if (!state.liveTimer[classId]) {
          state.liveTimer[classId] = getInitialLiveTimerState();
        }
        state.liveTimer[classId][classState] = liveTimer;
      }
    },
    setTimerFlag: (state, action) => {
      const { timerFlag } = action.payload;
      const classId = getCurrentClassId();
      if (classId) {
        state.timerFlag[classId] = timerFlag;
      }
    },
    incrementLiveTimer: (state, action) => {
      const { classState } = action.payload;
      const classId = getCurrentClassId();
      if (classId) {
        if (!state.liveTimer[classId]) {
          state.liveTimer[classId] = getInitialLiveTimerState();
        }
        state.liveTimer[classId][classState] =
          state.liveTimer[classId][classState] !== null
            ? state.liveTimer[classId][classState] + 1
            : 1;
      }
    },
    setSlideTimers: (state, action) => {
      const { classState, slideTimer } = action.payload;
      const classId = getCurrentClassId();
      if (classId) {
        if (!state.slideTimers[classId]) {
          state.slideTimers[classId] = getInitialTimerState();
        }
        state.slideTimers[classId][classState] = slideTimer;
      }
    },
    setSlideLiveTimer: (state, action) => {
      const {
        classState,
        timer,
        slideNo,
        elementId,
        elementName,
        elementSlideNo,
      } = action.payload;
      const classId = getCurrentClassId();
      if (classId) {
        if (!state.slideLiveTimer[classId]) {
          state.slideLiveTimer[classId] = getInitialSlideLiveTimerState();
        }
        state.slideLiveTimer[classId][classState].slideNo = slideNo;
        state.slideLiveTimer[classId][classState].timer = timer;
        state.slideLiveTimer[classId][classState].elementId = elementId;
        state.slideLiveTimer[classId][classState].elementName = elementName;
        state.slideLiveTimer[classId][classState].elementSlideNo =
          elementSlideNo;
      }
    },
    incrementSlideLiveTimer: (state, action) => {
      const { classState } = action.payload;
      const classId = getCurrentClassId();
      if (classId) {
        if (!state.slideLiveTimer[classId]) {
          state.slideLiveTimer[classId] = getInitialSlideLiveTimerState();
        }
        if (state.slideLiveTimer[classId][classState].timer === null) {
          state.slideLiveTimer[classId][classState].timer = 0;
        }
        state.slideLiveTimer[classId][classState].timer += 1;
      }
    },
    setPluginStatus: (state, action) => {
      const { slide, teach, coach_basic, coach_intermediate, coach_advanced } =
        action.payload;

      state.pluginStatus = {
        ...state.pluginStatus,
        teach: {
          ...state.pluginStatus.teach,
          slide: {
            ...state?.pluginStatus?.teach?.slide,
            ...teach?.slide,
          },
          whiteboard: {
            ...state?.pluginStatus?.teach?.whiteboard,
            ...teach?.whiteboard,
          },
        },
        coach_basic: {
          ...state.pluginStatus.coach_basic,
          slide: {
            ...state?.pluginStatus?.coach_basic?.slide,
            ...coach_basic?.slide,
          },
          whiteboard: {
            ...state?.pluginStatus?.coach_basic?.whiteboard,
            ...coach_basic?.whiteboard,
          },
        },
        coach_intermediate: {
          ...state?.pluginStatus?.coach_intermediate,
          slide: {
            ...state?.pluginStatus?.coach_intermediate?.slide,
            ...coach_intermediate?.slide,
          },
          whiteboard: {
            ...state?.pluginStatus?.coach_intermediate?.whiteboard,
            ...coach_intermediate?.whiteboard,
          },
        },
        coach_advanced: {
          ...state?.pluginStatus?.coach_advanced,
          slide: {
            ...state?.pluginStatus?.coach_advanced?.slide,
            ...coach_advanced?.slide,
          },
          whiteboard: {
            ...state?.pluginStatus?.coach_advanced?.whiteboard,
            ...coach_advanced?.whiteboard,
          },
        },
      };
    },
    setCurrentHtmlSlide: (state, action) => {
      const { classState, currentHTMLSlide } = action.payload;
      state.currentHtmlSlide[classState] = currentHTMLSlide;
    },
    setActiveHtmlSlide: (state, action) => {
      const { classState, activeHTMLSlide } = action.payload;
      state.activeHtmlSlide[classState] = activeHTMLSlide;
    },
    setActiveHtmlStep: (state, action) => {
      const { classState, activeHTMLStep } = action.payload;
      state.activeHtmlStep[classState] = activeHTMLStep;
    },
    setActiveElement: (state, action) => {
      const { classState, element } = action.payload;
      state.activeElement[classState] = element;
    },
    setCurrentElementIndex: (state, action) => {
      const { classState, elementIndex } = action.payload;
      state.currentElementIndex[classState] = elementIndex;
    },
    resetData: (state) => {
      state.timers = {};
      state.liveTimer = {};
      state.slideTimers = {};
      state.slideLiveTimer = {};
      state.classData = initialState.classData;
      state.activeElement = initialState.activeElement;
      state.activeHtmlSlide = initialState.activeHtmlSlide;
      state.activeHtmlStep = initialState.activeHtmlStep;
      state.currentHtmlSlide = initialState.currentHtmlSlide;
      state.currentElementIndex = initialState.currentElementIndex;
      state.pluginStatus = initialState.pluginStatus;
    },
  },
});

export const {
  setClassData,
  incrementLiveTimer,
  setTimers,
  setCurrentElementIndex,
  setLiveTimer,
  setTimerFlag,
  setSlideTimers,
  setSlideLiveTimer,
  incrementSlideLiveTimer,
  resetData,
  setActiveElement,
  setActiveHtmlStep,
  setActiveHtmlSlide,
  setCurrentHtmlSlide,
  setPluginStatus,
} = slideStatusSlice.actions;

export const getTimers = (state) => {
  const classId = getCurrentClassId();
  return classId ? state.SlideStatus.timers[classId] : null;
};

export const getLiveTimer = (state) => {
  const classId = getCurrentClassId();
  return classId ? state.SlideStatus.liveTimer[classId] : null;
};

export const getSlideTimers = (state) => {
  const classId = getCurrentClassId();
  return classId ? state.SlideStatus.slideTimers[classId] : null;
};

export const getTimerFlag = (state) => {
  const classId = getCurrentClassId();
  return classId && state?.SlideStatus?.timerFlag
    ? state?.SlideStatus?.timerFlag?.[classId]
    : null;
};

export const getSlideLiveTimer = (state) => {
  const classId = getCurrentClassId();
  return classId ? state.SlideStatus.slideLiveTimer[classId] : null;
};

export const getCurrentHtmlSlide = (state) =>
  state.SlideStatus.currentHtmlSlide;
export const getClassData = (state) => state.SlideStatus.classData;
export const getActiveElement = (state) => state.SlideStatus.activeElement;
export const getActiveHtmlSlide = (state) => state.SlideStatus.activeHtmlSlide;
export const getActiveHtmlStep = (state) => state.SlideStatus.activeHtmlStep;
export const getCurrentElementIndex = (state) =>
  state.SlideStatus.currentElementIndex;
export const getPluginStatus = (state) => state.SlideStatus.pluginStatus;

export default slideStatusSlice.reducer;
