import { APIClient } from './api_helper';
import { prepareQueryParams } from './classes';
import * as url from './url_helper';

const api = new APIClient();

// List Of Schools (db)
export const listOfBatchesService = (data = {}) => {
  // const urlSegments = `?name=${value}`;
  let queryParams;
  if (data && typeof data === 'object') {
    queryParams = prepareQueryParams(data);
  }
  let apiUrl = url.LIST_OF_BATCHES + queryParams;
  return api.get(apiUrl);
};

export const createBatch = (data) => {
  return api.create(url.CREATE_BATCH, data);
};

export const updateBatch = (data) => {
  return api.put(url.UPDATE_BATCH, data);
};

export const deleteBatch = (data) => {
  return api.delete(url.DELETE_BATCH + data);
};

export const listOfBatchesServiceV2 = (query = {}) => {
  let apiUrl = url.BATCHES_V2;
  return api.get(apiUrl, { params: query });
};

export const createBatchV2 = (data) => {
  return api.create(url.BATCHES_V2, data);
};

export const updateBatchV2 = (id, data) => {
  return api.put(url.BATCHES_V2 + `/${id}`, data);
};

export const updateDemoBatch = (id, data) => {
  return api.put(url.DEMO_BATCH_UPDATE + `/${id}`, data);
};
