import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  schools: [],
  totalRecords: 0,
  loading: false,
  addOrUpdateloading: false,
};

const adminSchoolSlice = createSlice({
  name: 'schoolsList',
  initialState,
  reducers: {
    setSchoolsList: (state, action) => {
      state.schools = action.payload;
      state.loading = initialState.loading;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
    setTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
    setAddOrUpdateLoader: (state, action) => {
      state.addOrUpdateloading = action.payload;
    },
  },
});

export const getSchoolsListRedux = (state) => state.AdminSchool.schools;
export const getSchoolsListLoaderRedux = (state) => state.AdminSchool.loading;
export const getTotalRecordsRedux = (state) => state.AdminSchool.totalRecords;

export const {
  setSchoolsList,
  setLoader,
  setTotalRecords,
  setAddOrUpdateLoader,
} = adminSchoolSlice.actions;
export const getAddOrUpdateSchoolLoaderRedux = (state) =>
  state.AdminSchool.addOrUpdateloading;

export default adminSchoolSlice.reducer;
