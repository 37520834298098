import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  Students: [],
  Guardians: [],
  // totalRecords:0,
  AddOrUpdateStudentGuardian: null,
  errors: null,
  loading: false,
  subscribedSubjects: {},
};

const studentSlice = createSlice({
  name: 'StudentsList',
  initialState,
  reducers: {
    setStudentsList: (state, action) => {
      state.Students = action.payload;
      state.loading = initialState.loading;
    },
    // setTotalRecords: (state, action) => {
    //   state.totalRecords = action.payload;
    // },
    setGuardiansList: (state, action) => {
      state.Guardians = action.payload;
      state.loading = initialState.loading;
    },
    setAddOrUpdateStudentGuardian: (state, action) => {
      state.AddOrUpdateStudentGuardian = action.payload;
      state.loading = initialState.loading;
    },
    setSubscribedSubjects: (state, action) => {
      state.subscribedSubjects = action.payload;
    },
    resetAddOrUpdateStudentGuardian: (state, action) => {
      state.AddOrUpdateStudentGuardian =
        initialState.AddOrUpdateStudentGuardian;
      state.loading = initialState.loading;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
      state.loading = initialState.loading;
    },
    resetErrors: (state, action) => {
      state.errors = initialState.errors;
      state.loading = initialState.loading;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const getStudentsListRedux = (state) => state.Student.Students;
export const getGuardiansListRedux = (state) => state.Student.Guardians;
export const getAddStudentGuardiansRedux = (state) =>
  state.Student.AddOrUpdateStudentGuardian;
export const getStudentGuardiansErrorRedux = (state) => state.Student.errors;
export const getStudentGuardiansLoaderRedux = (state) => state.Student.loading;
export const getSubscribedSubjectsRedux = (state) => state.Student.subscribedSubjects;
// export const getTotalRecordsRedux = (state) => state.Student.totalRecords;
export const {
  setStudentsList,
  setGuardiansList,
  setAddOrUpdateStudentGuardian,
  resetAddOrUpdateStudentGuardian,
  setErrors,
  resetErrors,
  setLoader,
  setSubscribedSubjects,
  // setTotalRecords
} = studentSlice.actions;

export default studentSlice.reducer;
