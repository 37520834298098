import { APIClient } from './api_helper';
import * as url from './url_helper';

const api = new APIClient();

export const listOfTutorService = (id) => {
  const query = {
    tutorId: id,
  };
  return api.get(url.TUTOR_DETAILS, { params: query });
};

export const createTutor = (data, headerInfo) => {
  return api.create(url.TUTOR_DETAILS, data, headerInfo);
};

export const updateTutor = (data, headerInfo) => {
  return api.put(url.TUTOR_DETAILS, data, headerInfo);
};

export const deleteTutor = (data) => {
  return api.delete(url.TUTOR_DETAILS + `/${data}`);
};
