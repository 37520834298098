import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  configuarationData: {
    success: null,
    error: null,
    data: null,
  },
  citiesWithStates: [],
  boards: [],
  grades: [],
  subjects: [],
  books: [],
  chapters: [],
  topics: [],
  subTopics: [],
  roles: [],
  batchTypes: [],
  loading: false,
};

const UtilsSlice = createSlice({
  name: 'Utils',
  initialState,
  reducers: {
    configuarationSuccess(state, action) {
      state.configuarationData = {
        success: true,
        error: false,
        data: action.payload,
      };
      state.loading = initialState.loading;
    },
    resetUtils(state) {
      state.configuarationData = initialState.configuarationData;
      state.loading = initialState.loading;
    },
    utilsFailed(state, action) {
      if (action?.payload?.methodName !== '') {
        state[action?.payload?.methodName] = {
          success: false,
          error: true,
          data: action.payload,
        };
        state.loading = initialState.loading;
      }
    },
    setCitiesWithStates: (state, action) => {
      state.citiesWithStates = action.payload;
      state.loading = initialState.loading;
    },
    setBoardsList: (state, action) => {
      state.boards = action.payload;
      state.loading = initialState.loading;
    },
    setGradeList: (state, action) => {
      state.grades = action.payload;
      state.loading = initialState.loading;
    },
    setSubjectsList: (state, action) => {
      state.subjects = action.payload;
      state.loading = initialState.loading;
    },
    setBooksList: (state, action) => {
      state.books = action.payload;
      state.loading = initialState.loading;
    },
    setChaptersList: (state, action) => {
      state.chapters = action.payload;
      state.loading = initialState.loading;
    },
    setTopicsList: (state, action) => {
      state.topics = action.payload;
      state.loading = initialState.loading;
    },
    setSubTopicsList: (state, action) => {
      state.subTopics = action.payload;
      state.loading = initialState.loading;
    },
    setRolesList: (state, action) => {
      state.roles = action.payload;
      state.loading = initialState.loading;
    },
    setBatchTypesList: (state, action) => {
      state.batchTypes = action.payload;
      state.loading = initialState.loading;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const configuarationInfo = (state) =>
  state?.Utils?.configuarationData?.data?.data || null;

export const getCitiesAndStatesRedux = (state) =>
  state.Utils.citiesWithStates || [];
export const getBoardsRedux = (state) => state.Utils.boards || [];
export const getGradeList = (state) => state.Utils.grades || [];
export const getSubjectsList = (state) => state.Utils.subjects || [];
export const getBooksList = (state) => state.Utils.books || [];
export const getChaptersList = (state) => state.Utils.chapters || [];
export const getTopicsList = (state) => state.Utils.topics || [];
export const getSubTopicsList = (state) => state.Utils.subTopics || [];
export const getRolesList = (state) => state.Utils.roles || [];
export const getBatchTypesList = (state) => state.Utils.batchTypes || [];
export const getUtilsListLoaderRedux = (state) => state.Utils.loading;

export const {
  configuarationSuccess,
  resetUtils,
  utilsFailed,
  setCitiesWithStates,
  setBoardsList,
  setGradeList,
  setSubjectsList,
  setBooksList,
  setChaptersList,
  setTopicsList,
  setSubTopicsList,
  setRolesList,
  setBatchTypesList,
  setLoader,
} = UtilsSlice.actions;

export default UtilsSlice.reducer;
