import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  tutors: [],
  totalRecords: 0,
  loading: false,
  addOrUpdateloading: false,
};

const adminTutorSlice = createSlice({
  name: 'tutorsList',
  initialState,
  reducers: {
    setTutorsList: (state, action) => {
      state.tutors = action.payload;
      state.loading = initialState.loading;
    },
    setTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
    setAddOrUpdateLoader: (state, action) => {
      state.addOrUpdateloading = action.payload;
    },
  },
});

export const getTutorsDataRedux = (state) => state.AdminTutor.tutors;

export const getTutorsListLoaderRedux = (state) => state.AdminTutor.loading;
export const getTotalRecordsRedux = (state) => state.AdminTutor.totalRecords;
export const getAddOrUpdateTutorLoaderRedux = (state) =>
  state.AdminTutor.addOrUpdateloading;

export const {
  setTutorsList,
  setLoader,
  setTotalRecords,
  setAddOrUpdateLoader,
} = adminTutorSlice.actions;

export default adminTutorSlice.reducer;
