import { createSlice } from '@reduxjs/toolkit';
import { base } from 'config';

const defaultEvaluationState = {
  classId: '',
  [base.ROOMS_IDS.PRACTICE]: {
    showModal: false,
    status: false,
    type: null,
    id: null,
    evaluateAt: null,
  },
  [base.ROOMS_IDS.TEACH]: {
    showModal: false,
    status: false,
    type: null,
    id: null,
    evaluateAt: null,
  },
  [base.ROOMS_IDS.COACH_BASIC]: {
    showModal: false,
    status: false,
    type: null,
    id: null,
    evaluateAt: null,
  },
  [base.ROOMS_IDS.COACH_INTERMEDIATE]: {
    showModal: false,
    status: false,
    type: null,
    id: null,
    evaluateAt: null,
  },
  [base.ROOMS_IDS.COACH_ADVANCED]: {
    showModal: false,
    status: false,
    type: null,
    id: null,
    evaluateAt: null,
  },
};

const defaultActiveRoom = {
  classId: '',
  classLevel: 'teach',
};

export const initialState = {
  evaluationState: defaultEvaluationState,
  activeRoom: defaultActiveRoom,
  lecture: {},
  setRawLecture: [],
  currentLectureIndex: 0,
  classId: '',
};

const contentInClassSlice = createSlice({
  name: 'ContentInClass',
  initialState,
  reducers: {
    setEvaluationState: (state, action) => {
      const { roomId, newState, classId } = action.payload;

      // Reset to defaultEvaluationState if classId is different
      if (classId !== state.evaluationState.classId && classId) {
        state.evaluationState = { ...defaultEvaluationState };
      }

      // Update classId if provided
      if (classId) {
        state.evaluationState.classId = String(classId);
      }

      // If roomId is not provided or invalid, just update the classId and return
      if (!roomId || !state.evaluationState[roomId]) {
        if (!roomId) {
          return;
        } else {
          console.error(
            'roomId is invalid or not defined in the initial state',
          );
          return;
        }
      }

      // Update the specific roomId state with the partial newState
      state.evaluationState[roomId] = {
        ...state.evaluationState[roomId],
        ...Object.fromEntries(
          Object.entries(newState).filter(
            ([key, value]) => value !== undefined,
          ),
        ),
      };
    },
    setActiveRoomState: (state, action) => {
      const { classId, classLevel } = action.payload;

      if (classId !== state.evaluationState.classId && classId) {
        state.activeRoom = { ...defaultActiveRoom };
      } else {
        state.activeRoom.classLevel = classLevel;
      }
    },
    setRawLecture: (state, action) => {
      state.setRawLecture = action.payload;
    },
    setCurrentLectureIndex: (state, action) => {
      state.currentLectureIndex = action.payload;
    },
    setLecture: (state, action) => {
      state.lecture = action.payload;
    },
    setClassId: (state, action) => {
      state.classId = action.payload;
    },
  },
});

export const getLectureRedux = (state) => state.ContentInClass.lecture;
export const getRawLectureRedux = (state) => state.ContentInClass.setRawLecture;
export const getCurrentLectureIndexRedux = (state) =>
  state.ContentInClass.currentLectureIndex;

export const selectEvaluationState = (state) =>
  state?.ContentInClass?.evaluationState || {};
export const selectActiveRoomState = (state) =>
  state?.ContentInClass?.activeRoom || {};

export const {
  setEvaluationState,
  setActiveRoomState,
  setLecture,
  setCurrentLectureIndex,
  setRawLecture,
  setClassId,
} = contentInClassSlice.actions;

export default contentInClassSlice.reducer;
