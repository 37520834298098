import axios from 'axios';
import { api } from 'config';
import { errorToastify } from './toast';
import PublicPath from 'Routes/path/publicPath';
import { datadogLogs } from '@datadog/browser-logs';
// default
axios.defaults.baseURL = api.API_BASE_URL;
// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';

const publicPathsArr = [
  '/register',
  '/login',
  '/account-holders',
  '/forgot-passcode',
  '/new-passcode',
  '/login',
  '/login/google',
  '/page/terms-conditions',
];

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    if (response?.status !== 200) {
      const log = {
        url: response?.config?.url,
        method: response?.config?.method,
        data: response?.config?.data,
        status: response?.status,
        responseData: response?.data,
      };
      datadogLogs?.logger?.error('API log', log);
    }

    const statusCode =
      response?.status === 200 || response?.status === 201 ? 200 : 500;
    return {
      code: response?.code || null,
      status: statusCode,
      message: response?.data?.message || null,
      data: response?.data?.data || response?.data || null,
    };
  },
  function (error) {
    const log = {
      url: error?.response?.config?.url,
      method: error?.response?.config?.method,
      data: error?.response?.config?.data,
      status: error?.response?.status,
      responseData: error?.response?.data,
    };
    datadogLogs?.logger?.error('API log', log);
    let isPublicPath;
    if (parseInt(error?.response?.status, 10) === 419) {
      isPublicPath = checkPublicPath();
      if (isPublicPath === false) {
        localStorage.removeItem('persist:root');
        errorToastify('Login session expired, Please login in again');
        window.location.replace('/login');
      }
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      case 403:
        message = 'Forbidden';
        break;
      case 404:
        message = 'Sorry! the data you are looking for could not be found';
        break;
      default:
        message = error?.response?.data?.message || error?.message || error;
    }

    // errorToastify(message || 'Something went wrong. Please try again.');
    return Promise.reject({
      code: error?.code || null,
      status: error?.response?.data?.status || null,
      message:
        error?.response?.data?.message ||
        'Something went wrong. Please try again.',
      data: error?.response?.data?.data || error?.response?.data || null,
    });
  },
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  // axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
};

const checkPublicPath = () => {
  return publicPathsArr?.includes(window.location.pathname);
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = async (url, params) => {
    let response;

    let paramKeys = [];

    if (params) {
      /*Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";*/
      response = await axios.get(`${url}`, {
        ...params,
        withCredentials: true,
      });
    } else {
      response = await axios.get(`${url}`, { withCredentials: true });
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data, headerInfo = '') => {
    return axios.post(url, data, {
      ...headerInfo,
      maxContentLength: 10 * 1000 * 1000, // 10mb
      maxBodyLength: 10 * 1000 * 1000, // 10mb
      withCredentials: true,
    });
  };
  /**
   * Updates data
   */
  update = (url, data, headerInfo = '') => {
    return axios.patch(url, data, { ...headerInfo, withCredentials: true });
  };

  put = (url, data, headerInfo = '') => {
    return axios.put(url, data, { ...headerInfo, withCredentials: true });
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config, withCredentials: true });
  };
}
const getLoggedinUser = () => {
  const user = sessionStorage.getItem('authUser');
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };
