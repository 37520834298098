import { setAuthorization } from 'helpers/api_helper';
import { signOutAdmin, setLoader, userSignIn } from './reducer';
import { persister } from 'index';
import { loginAdmin } from 'helpers/auth';
import { errorToastify } from 'helpers/toast';
import Cookie from 'js-cookie';

export const logoutAdmin = () => async (dispatch) => {
  try {
    const tokenConfig =
      process.env.REACT_APP_DOMAIN_ENV === 'local'
        ? {
            path: '/',
          }
        : {
            domain: '.uprio.com',
            path: '/',
          };
    Cookie.remove(`${process.env.REACT_APP_DOMAIN_ENV}Token`, tokenConfig);
    localStorage.clear();
    dispatch(signOutAdmin(false));
    persister.pause();
    await persister.flush().then(() => {
      return persister.purge();
    });
    console.log('clearing local storage');
  } catch (error) {
    //console.log('error in singot admin in thunk -- ', error);
  }
};

export const loginAdminService = (payload) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const { data, status } = await loginAdmin(payload);
    const token = Cookie.get(`${process.env.REACT_APP_DOMAIN_ENV}Token`);
    status === 200 &&
      dispatch(userSignIn({ user: data, token, loggedIn: true }));
  } catch (error) {
    dispatch(setLoader(false));
    errorToastify(error.message || 'Can not login, please contact admin.');
  }
};
