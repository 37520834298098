import { APIClient } from './api_helper';
import * as url from './url_helper';

const api = new APIClient();

// List Of Schools (db)
export const listOfSchoolsService = (value = '', city = '') => {
  const urlSegments = `?name=${value}&cityId=${city}`;
  return api.get(url.LIST_OF_SCHOOL + urlSegments);
};

// List Of Schools (Google)
export const listOfSchoolsGoogleService = (
  country = '',
  state = '',
  city = '',
  schoolKeyword = '',
) => {
  const urlSegments = `?country=${country}&state=${state}&city=${city}&name=${schoolKeyword}`;
  return api.get(url.LIST_OF_SCHOOLS_GOOGLEAPIS + urlSegments);
};

// create School
export const createSchoolService = (data, headerInfo) => {
  try {
    console.log('header data : ', headerInfo);
    return api.create(url.CREATE_SCHOOL, data, headerInfo);
  } catch (error) {
    //console.log(error);
  }
};

export const updateSchoolNameService = (data, headerInfo) => {
  try {
    return api.put(url.UPDATE_SCHOOL_NAME, data, headerInfo);
  } catch (error) {
    //console.log(error);
  }
};

export const deleteSchool = (params) => {
  try {
    //console.log(url.DELETE_SCHOOL + params);
    return api.delete(url.DELETE_SCHOOL + params);
  } catch (error) {
    //console.log('error in delete school -- ', error);
  }
};

export const getSchools = (query) => {
  try {
    return api.get(url.GET_SCHOOLS_V2, { params: query });
  } catch (error) {}
};
