import {
  listOfDemoClassesService,
  listOfScheduledClassesService,
  BookDemoClassService,
  getClassDetailsService,
  getJoinClassroomTokenService,
} from 'helpers/classes';
import { getHeadersPayload } from 'helpers/common';
import { studentViewerToken } from 'helpers/studentViewerToken';
import {
  setSGTrialClassesList,
  setSGScheduledClassesList,
  setClassDetails,
  postBookTrialClasses,
  resetBookTrialClasses,
  setJoinClassroomToken,
  catchErrors,
  resetErrors,
  setLoader,
  setStreamingToken,
} from './reducer';

export const getSGTrialClassesList =
  (payload = '', accessToken = '') =>
  async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const headersInfo = getHeadersPayload(accessToken);
      const { data, status, message } = await listOfDemoClassesService(
        payload,
        headersInfo,
      );
      if (status === 200) {
        dispatch(setSGTrialClassesList(data?.records || []));
      } else {
        dispatch(catchErrors({ status, message }));
      }
    } catch (e) {
      const error = { status: e?.status || 400, message: e?.message };
      dispatch(catchErrors(error));
    }
  };

export const getSGScheduledClassesList =
  (payload = '', accessToken = '') =>
  async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const headersInfo = getHeadersPayload(accessToken);
      const { data, status, message } = await listOfScheduledClassesService(
        payload,
        headersInfo,
      );
      if (status === 200) {
        const classData = data?.filter((item) => {
          if (item?.classDetails) {
            return item;
          }
        });
        dispatch(setSGScheduledClassesList(classData));
      } else {
        dispatch(catchErrors({ status, message }));
      }
    } catch (e) {
      const error = { status: e?.status || 400, message: e?.message || '' };
      dispatch(catchErrors(error));
    }
  };

export const getClassDetails =
  (id = '', accessToken = '') =>
  async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const headersInfo = getHeadersPayload(accessToken);
      const { data, status, message } = await getClassDetailsService(
        id,
        headersInfo,
      );
      if (status === 200) {
        dispatch(setClassDetails(data));
      } else {
        dispatch(catchErrors({ status, message }));
      }
      return data;
    } catch (e) {
      const error = { status: e?.status || 400, message: e?.message || '' };
      dispatch(catchErrors(error));
    }
  };

export const getClassroomToken =
  (id = '') =>
  async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const { data, status, message } = await getJoinClassroomTokenService(id);
      if (status === 200) {
        dispatch(setJoinClassroomToken(data));
      } else {
        dispatch(catchErrors({ status, message }));
      }
    } catch (e) {
      const error = { status: e?.status || 400, message: e?.message || '' };
      dispatch(catchErrors(error));
    }
  };

export const bookDemoOrTrialClass =
  (data = '', accessToken = '') =>
  async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const headersInfo = getHeadersPayload(accessToken);
      const { status, message } = await BookDemoClassService(data, headersInfo);
      if (status === 200) {
        dispatch(postBookTrialClasses({ status, message }));
      } else {
        dispatch(postBookTrialClasses({ status, message }));
      }
    } catch (e) {
      const error = { status: e?.status || 400, message: e?.message || '' };
      dispatch(postBookTrialClasses(error));
    }
  };

export const resetBookDemoOrTrialClass = () => async (dispatch) => {
  try {
    dispatch(resetBookTrialClasses());
  } catch (e) {
    const error = { status: e?.status || 400, message: e?.message || '' };
    dispatch(catchErrors(error));
  }
};

export const resetSGDashboardErrors = () => async (dispatch) => {
  dispatch(resetErrors());
};

export const getStreamingToken = (query) => async (dispatch) => {
  const { data } = await studentViewerToken(query);
  dispatch(setStreamingToken(data));
};
