import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  initiateLogin: {
    success: null,
    error: null,
    data: null,
  },
  verifyLoginPasscode: {
    success: null,
    error: null,
    data: null,
  },
  listOfAccountHoldersLogin: {
    success: null,
    error: null,
    data: null,
  },
  loginAccountToken: null,
  loginAccountDetails: {
    success: null,
    error: null,
    data: null,
  },
  loading: false,
};

const loginSlice = createSlice({
  name: 'StudentGuadianLogin',
  initialState,
  reducers: {
    initiateLoginSuccess(state, action) {
      state.initiateLogin = {
        success: true,
        error: false,
        data: action.payload,
      };
      state.loading = initialState.loading;
    },
    verifyLoginPasscodeSuccess(state, action) {
      state.verifyLoginPasscode = {
        success: true,
        error: false,
        data: action.payload,
      };
      state.loading = initialState.loading;
    },
    listOfAccountHoldersLoginSuccess(state, action) {
      state.listOfAccountHoldersLogin = {
        success: true,
        error: false,
        data: action.payload,
      };
      state.loading = initialState.loading;
    },
    loginAccountWithTempTokenSuccess(state, action) {
      state.loginAccountToken = action.payload;
      state.loading = initialState.loading;
    },
    loginAccountDetailsSuccess(state, action) {
      state.loginAccountDetails = {
        success: true,
        error: false,
        data: action.payload,
      };
      state.loading = initialState.loading;
    },
    logoutAccount(state, action) {
      state.initiateLogin = initialState.initiateLogin;
      state.verifyLoginPasscode = initialState.verifyLoginPasscode;
      state.listOfAccountHoldersLogin = initialState.listOfAccountHoldersLogin;
      state.loginAccountToken = initialState.loginAccountToken;
      state.loginAccountDetails = initialState.loginAccountDetails;
      state.loading = initialState.loading;
    },
    resetLogin(state) {
      state.initiateLogin = initialState.initiateLogin;
      state.verifyLoginPasscode = initialState.verifyLoginPasscode;
      state.loading = initialState.loading;
    },
    loginFailed(state, action) {
      if (action?.payload?.methodName !== '') {
        state[action?.payload?.methodName] = {
          success: false,
          error: true,
          data: action.payload,
        };
        state.loading = initialState.loading;
      }
    },
    resetErrors(state, action) {
      if (action?.payload?.methodName !== '') {
        state[action?.payload?.methodName] = {
          success: null,
          error: null,
          data: null,
        };
        state.loading = initialState.loading;
      }
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const primaryLoginAuthenticationToken = (state) =>
  state?.StudentGuardianLogin?.loginAccountToken || null;
export const tempLoginAuthenticationToken = (state) =>
  state?.StudentGuardianLogin?.verifyLoginPasscode?.data?.data?.tempToken ||
  null;
export const authUserLoggedInfo = (state) =>
  state?.StudentGuardianLogin?.loginAccountDetails?.data?.data || null;
export const getStudentGuardianLoginLoaderRedux = (state) =>
  state?.StudentGuardianLogin?.loading || false;

export const {
  initiateLoginSuccess,
  verifyLoginPasscodeSuccess,
  listOfAccountHoldersLoginSuccess,
  loginAccountWithTempTokenSuccess,
  loginAccountDetailsSuccess,
  logoutAccount,
  resetLogin,
  loginFailed,
  resetErrors,
  setLoader,
} = loginSlice.actions;

export default loginSlice.reducer;
