import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  trialClasses: [],
  scheduledClasses: [],
  bookTrialClass: {
    status: null,
    message: null,
  },
  classDetails: {},
  errors: null,
  joinClassroomToken: {},
  loading: false,
  streamingToken: {},
};

const StudentGuardianDashboardSlice = createSlice({
  name: 'StudentGuardianDashboard',
  initialState,
  reducers: {
    setSGTrialClassesList: (state, action) => {
      state.trialClasses = action.payload;
      state.loading = initialState.loading;
    },
    setSGScheduledClassesList: (state, action) => {
      state.scheduledClasses = action.payload;
      state.loading = initialState.loading;
    },
    setClassDetails: (state, action) => {
      state.classDetails = action.payload;
      state.loading = initialState.loading;
    },
    postBookTrialClasses: (state, action) => {
      state.bookTrialClass = action.payload;
      state.loading = initialState.loading;
    },
    resetBookTrialClasses: (state, action) => {
      state.bookTrialClass.status = initialState.bookTrialClass.status;
      state.bookTrialClass.message = initialState.bookTrialClass.message;
      state.loading = initialState.loading;
    },
    setJoinClassroomToken: (state, action) => {
      state.joinClassroomToken = action.payload;
    },
    catchErrors: (state, action) => {
      state.errors = action.payload;
      state.loading = initialState.loading;
    },
    resetErrors: (state, action) => {
      state.errors = initialState.errors;
      state.loading = initialState.loading;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
    setStreamingToken: (state, action) => {
      state.streamingToken = action.payload;
    },
  },
});

export const getSGTrialClassesListRedux = (state) =>
  state?.StudentGuardianDashboard?.trialClasses || [];
export const getSGScheduledClassesListRedux = (state) =>
  state?.StudentGuardianDashboard?.scheduledClasses || [];
export const getClassDetailsRedux = (state) =>
  state?.StudentGuardianDashboard?.classDetails || {};
export const bookSGTrialClassesRedux = (state) =>
  state?.StudentGuardianDashboard?.bookTrialClass || null;
export const catchErrorsSGDashboard = (state) =>
  state?.StudentGuardianDashboard?.errors || null;
export const getSGDashboardLoaderRedux = (state) =>
  state?.StudentGuardianDashboard?.loading || false;
export const getJoinClassroomTokenRedux = (state) =>
  state?.StudentGuardianDashboard?.joinClassroomToken || null;
export const getStreamingTokenRedux = (state) =>
  state?.StudentGuardianDashboard?.streamingToken || null;

export const {
  setSGTrialClassesList,
  setSGScheduledClassesList,
  setClassDetails,
  postBookTrialClasses,
  resetBookTrialClasses,
  setJoinClassroomToken,
  catchErrors,
  resetErrors,
  setLoader,
  setStreamingToken,
} = StudentGuardianDashboardSlice.actions;

export default StudentGuardianDashboardSlice.reducer;
