import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  users: [],
  totalRecords: 0,
  tutors: [],
  loading: false,
  addOrUpdateloading: false,
};

const adminUserSlice = createSlice({
  name: 'usersList',
  initialState,
  reducers: {
    setUsersList: (state, action) => {
      state.users = action.payload;
      state.loading = initialState.loading;
    },
    setTutorsList: (state, action) => {
      state.tutors = action.payload;
      state.loading = initialState.loading;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
    setTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
    setAddOrUpdateLoader: (state, action) => {
      state.addOrUpdateloading = action.payload;
    },
  },
});

export const getUsersListRedux = (state) => state.AdminUser.users;
export const getTutorsListRedux = (state) => state.AdminUser.tutors;
export const getUsersListLoaderRedux = (state) => state.AdminUser.loading;
export const getTotalRecordsRedux = (state) => state.AdminUser.totalRecords;
export const getAddOrUpdateUserLoaderRedux = (state) =>
  state.AdminUser.addOrUpdateloading;

export const {
  setUsersList,
  setTutorsList,
  setLoader,
  setTotalRecords,
  setAddOrUpdateLoader,
} = adminUserSlice.actions;

export default adminUserSlice.reducer;
