import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';

export const Question = ({ content, lineClamp }) => {
  return (
    <p className={`${lineClamp && 'line-clamp-2'}`}>
      {content?.map((item, index) => {
        switch (item?.type) {
          case 'latex':
            return <Latex key={index}>{`$${item?.content}$`}</Latex>;
          case 'break':
            return <br key={index} />;
          case 'text':
            return <span key={index}>{item?.content}</span>;
          case 'image':
            return (
              <img className="w-100" key={index} src={item?.content} alt="" />
            );
          default:
            return null;
        }
      })}
    </p>
  );
};
