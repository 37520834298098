//Include Both Helper File with needed methods

import { errorToastify, successToastify } from 'helpers/toast';
import {
  createSchoolCurriculum,
  deleteSchoolCurriculum,
  listOfSchoolCurriculumsService,
  schoolCurriculumByStudentId,
  updateSchoolCurriculum,
  exportCurriculum,
  importCurriculum,
  getCurriculums,
} from 'helpers/schoolCurriculum';
import {
  setSchoolCurriculumsList,
  setLoader,
  setAddOrUpdateLoader,
  setCurriculums,
} from './reducer';

export const getSchoolCurriculumsList = (user, history) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const { data, status } = await listOfSchoolCurriculumsService();
    status === 200 && dispatch(setSchoolCurriculumsList(data));
  } catch (error) {
    dispatch(setLoader(false));
  }
};

export const getSchoolCurriculumByStudentId =
  (studentId) => async (dispatch) => {
    try {
      const { data, status } = await schoolCurriculumByStudentId(studentId);
      if (status === 200) return data;
    } catch (error) {
      errorToastify('Error getting School Curriculum by StudentId.');
    }
  };

export const createSchoolCurriculumService =
  (dataToPost) => async (dispatch) => {
    try {
      dispatch(setAddOrUpdateLoader(true));
      const { data, status } = await createSchoolCurriculum(dataToPost);
      status === 200 &&
        successToastify('School Curriculum created successfully.');
      dispatch(setAddOrUpdateLoader(false));
    } catch (error) {
      dispatch(setAddOrUpdateLoader(false));
      errorToastify('Error creating School Curriculum.');
    }
  };

export const updateSchoolCurriculumService =
  (dataToPost) => async (dispatch) => {
    try {
      dispatch(setAddOrUpdateLoader(true));
      const { data, status } = await updateSchoolCurriculum(dataToPost);
      status === 200 &&
        successToastify('School Curriculum update successfully.');
      dispatch(setAddOrUpdateLoader(false));
    } catch (error) {
      dispatch(setAddOrUpdateLoader(false));
      errorToastify('Error updating School Curriculum.');
    }
  };

export const deleteSchoolCurriculumService =
  (user, history) => async (dispatch) => {
    try {
      const { data, status } = await deleteSchoolCurriculum();
      status === 200 &&
        successToastify('School Curriculum removed successfully.');
    } catch (error) {
      errorToastify('Error in removing School Curriculum. ');
    }
  };

export const exportCurriculumService = () => async (dispatch) => {
  try {
    const { data, status } = await exportCurriculum();
    status === 200 &&
      successToastify('School Curriculum Downloaded successfully.');
    return data;
  } catch (error) {
    errorToastify('Error in Downloading School Curriculum.');
  }
};

export const importCurriculumService =
  (formData, headerInfo) => async (dispatch) => {
    try {
      const { status } = await importCurriculum(formData, headerInfo);

      status === 200 &&
        successToastify('School Curriculum Upload Successfully');
      return status;
    } catch (error) {
      errorToastify('Error in Importing School Curriculum.');
    }
  };

export const getCurriculumService = (query) => async (dispatch) => {
  try {
    const { data, status } = await getCurriculums(query);
    if (status === 200) dispatch(setCurriculums(data));
  } catch (error) {
    errorToastify('Error in Getting The School Curriculum.');
  }
};
