const USER_TYPE = {
  STUDENT: 'student',
  TUTOR: 'tutor',
};

const DEFAULT_PRACTICE_SLIDE = `https://docs.google.com/presentation/d/1C2GEvWAfhV8QbkAN-HWfCCD28Wp9BD5r0cTlImM2_ho/embed`;

module.exports = {
  USER_TYPE,
  DEFAULT_PRACTICE_SLIDE,
};
