//Include Both Helper File with needed methods

import { listOfDemoClassConfigurationsService } from 'helpers/classes';
import {
  createDemoClassConfiguration,
  deleteDemoClassConfiuration,
  updateDemoClassConfiguration,
} from 'helpers/demoClasses';
import { errorToastify, successToastify } from 'helpers/toast';
import {
  setDemoClassConfigurations,
  setLoader,
  setTotalRecords,
  setAddOrUpdateLoader,
} from './reducer';

export const getDemoClassConfigurationList = () => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const { data, status } = await listOfDemoClassConfigurationsService();
    status === 200 &&
      dispatch(setDemoClassConfigurations(data.records)) &&
      dispatch(setTotalRecords(data.totalRecords));
  } catch (error) {
    dispatch(setLoader(false));
  }
};

export const createDemoClassConfigurationService =
  (dataToPost, history) => async (dispatch) => {
    try {
      dispatch(setAddOrUpdateLoader({ loader: true }));
      await createDemoClassConfiguration(dataToPost);
      dispatch(
        setAddOrUpdateLoader({ loader: false, success: true, error: null }),
      );
    } catch (error) {
      dispatch(setAddOrUpdateLoader({ loader: false, success: false, error }));
    }
  };

export const updateDemoConfigurationClassService =
  (id, dataToPost) => async (dispatch) => {
    try {
      dispatch(setAddOrUpdateLoader({ loader: true }));
      await updateDemoClassConfiguration(id, dataToPost);
      dispatch(
        setAddOrUpdateLoader({ loader: false, success: true, error: null }),
      );
    } catch (error) {
      dispatch(setAddOrUpdateLoader({ loader: false, success: false, error }));
    }
  };

export const deleteDemoClassConfigfurationService =
  (id) => async (dispatch) => {
    try {
      await deleteDemoClassConfiuration(id);
      successToastify('Demo class Configuration deleted successfully.');
    } catch (error) {
      errorToastify('Failed to delete Demo Class Configuration');
    }
  };
