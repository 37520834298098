import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import RoutePath from './path/publicPath';
import route from './path/adminPath';
import Video from 'pages/DyteMeetings/video';
import StudentReport from 'pages/Admin/Users/V2Grids/GenerateQcGapReport/StudentReport';

/*****Public pages******/
const Register = lazy(
  () => import('pages/StudentGuardians/Authentication/Register'),
);

const Login = lazy(() => import('pages/StudentGuardians/Authentication/Login'));

const ListOfAccountHolders = lazy(
  () =>
    import('pages/StudentGuardians/Authentication/Login/ListOfAccountHolders'),
);

const ForgotPasscode = lazy(
  () => import('pages/StudentGuardians/Authentication/ForgotPasscode'),
);

const CreatePasscode = lazy(
  () => import('pages/StudentGuardians/Authentication/CreatePasscode'),
);

/*****Admin Login******/
const AdminLogin = lazy(() => import('pages/Admin/Authentication'));

/*****Classroom Meeting******/
const DyteMeeting = lazy(() => import('pages/DyteMeetings'));

/*****Others******/
const LoginGoogle = lazy(
  () => import('pages/StudentGuardians/Authentication/Login/LoginGoogle'),
);

const LoginStatus = lazy(
  () => import('pages/StudentGuardians/Authentication/Login/LoginStatus'),
);

const OnlyStudentStreaming = lazy(
  () => import('pages/OnlyStudentStreaming/OnlyStudentStreaming'),
);

const TermsConditions = lazy(() => import('pages/Public/TermsConditions'));

const LoginZoho = lazy(
  () => import('pages/StudentGuardians/Authentication/Login/LoginZoho'),
);

const publicRoutes = [
  // { path: RoutePath.REGISTER, component: <Register /> },
  // { path: RoutePath.LOGIN, component: <Login /> },
  // { path: RoutePath.ACCOUNT_HOLDERS, component: <ListOfAccountHolders /> },
  // { path: RoutePath.FORGOT_PASSCODE, component: <ForgotPasscode /> },
  // { path: RoutePath.RESET_PASSCODE, component: <CreatePasscode /> },
  { path: RoutePath.CLASSROOM, component: <DyteMeeting /> },
  { path: RoutePath.LOGIN_STATUS, component: <LoginStatus /> },
  { path: RoutePath.ADMIN_LOGIN, component: <AdminLogin /> },
  { path: RoutePath.LOGIN_WITH_GOOGLE, component: <LoginGoogle /> },
  { path: RoutePath.LOGIN_WITH_ZOHO, component: <LoginZoho /> },
  // { path: RoutePath.ADMIN_CLASSROOM, component: <DyteMeeting /> },
  { path: RoutePath.TERMS_CONDITIONS, component: <TermsConditions /> },
  { path: RoutePath.STUDENT_REPORT, component: <StudentReport /> },
  { path: '/video', component: <Video /> },

  { path: route.ONLY_STUDENT_STREAMING, component: <OnlyStudentStreaming /> },
];

export default publicRoutes;
