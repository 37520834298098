import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  configurations: [],
  loading: false,
  addOrUpdateloading: false,
  availableSlots: [],
  slotsLoading: false,
};

const adminConfigurationSlice = createSlice({
  name: 'configurationsList',
  initialState,
  reducers: {
    setConfigurationsList: (state, action) => {
      state.configurations = action.payload;
      state.loading = initialState.loading;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
    setAddOrUpdateLoader: (state, action) => {
      state.addOrUpdateloading = action.payload;
    },
    setAvailableSlots: (state, action) => {
      state.availableSlots = action.payload;
    },
    setSlotsLoading: (state, action) => {
      state.slotsLoading = action.payload;
    },
  },
});

export const getConfigurationsListRedux = (state) =>
  state.AdminConfiguration.configurations;
export const getConfigurationsListLoaderRedux = (state) =>
  state.AdminConfiguration.loading;
export const getConfigurationAddOrUpdateLoaderRedux = (state) =>
  state.AdminConfiguration.addOrUpdateloading;
export const getAvailableSlots = (state) =>
  state.AdminConfiguration.availableSlots;
export const getSlotsLoading = (state) => state.AdminConfiguration.slotsLoading;

export const {
  setConfigurationsList,
  setLoader,
  setAddOrUpdateLoader,
  setAvailableSlots,
  setSlotsLoading,
} = adminConfigurationSlice.actions;

export default adminConfigurationSlice.reducer;
