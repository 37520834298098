const FrontendPath = {
  INDEX: '/',
  DASHBOARD: '/dashboard',
  ACCOUNT_DASHBOARD: '/account/dashboard',
  ACCOUNT_CLASSES: '/account/classes',
  ACCOUNT_PROFILE: '/account/profile',
  ACCOUNT_ANALYTICS_OLD: '/account/analytics',
  ACCOUNT_CLASS_ANALYTICS: '/class/analytics/:id',

  STUDENT_DASHBOARD: '/student-activities',
  STUDENT_ACTIVITIES: '/student-activities',
  STUDENT_SUBSCRIBED_PREMIUM_CLASS: '/subscribed-premium-class',
  STUDENT_PROFILE: '/student-profile',
  STUDENT_CALENDAR: '/student-calendar',
  FREE_USER_DASHBOARD: '/free-user-self/dashboard',

  NOT_FOUND: '/*',
};

export default FrontendPath;
