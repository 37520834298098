import { APIClient } from './api_helper';
import * as url from './url_helper';

const api = new APIClient();

// List Of Demo Classes
export const listOfRole = (data) => api.get(url.LIST_OF_ROLE, data);

// export const createRole = (data) => {
//   try {
//     api.create(url.CREATE_ROLE, data)
//   }catch(error){
//     //console.log("error at create demo classes -- ", error);
//   }
// };

// export const updateRole = (data) => {
//   try{
//     api.put(url.UPDATE_ROLE, data);
//   }catch(error){
//     //console.log("error at update demo classes -- ",error);
//   }
// };
