import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import RoutePath from './path/frontendPath';

/*****Dashboard******/
const Dashboard = lazy(() => import('pages/Admin/Dashboard'));

const AccountDashboard = lazy(
  () => import('pages/StudentGuardians/Accounts/Dashboard'),
);

/*****Classes******/
const AccountAnalytics = lazy(
  () =>
    import('pages/StudentGuardians/Accounts/Classes/Analytics/OldAnalytics'),
);

const ClassAnalytics = lazy(
  () => import('pages/StudentGuardians/Accounts/Classes/Analytics'),
);

const AccountClasses = lazy(
  () => import('pages/StudentGuardians/Accounts/Classes'),
);

/*****Profile & Settings******/
const AccountProfile = lazy(
  () => import('pages/StudentGuardians/Accounts/Profile'),
);

/*****Other******/

const StudentDashboard = lazy(
  () => import('pages/Admin/Student/Dashboard/StudentDashboard'),
);

const Activities = lazy(
  () => import('pages/Admin/Student/Activities/Activities'),
);

const SubscribedPremiumClass = lazy(
  () => import('pages/Admin/Student/Dashboard/SubscribedPremiumClass'),
);

const Calendar = lazy(() => import('pages/Admin/Student/Calendar/Calendar'));

const StudentProfile = lazy(
  () => import('pages/Admin/Student/ProfileAndSettings/StudentProfile'),
);

const FreeUserDashboard = lazy(
  () =>
    import('pages/StudentGuardians/FreeUserSelf/Dashboard/FreeUserDashboard'),
);

const authProtectedRoutes = [
  { path: RoutePath.DASHBOARD, component: <Dashboard /> },

  { path: RoutePath.STUDENT_DASHBOARD, component: <StudentDashboard /> },
  { path: RoutePath.STUDENT_ACTIVITIES, component: <Activities /> },
  {
    path: RoutePath.STUDENT_SUBSCRIBED_PREMIUM_CLASS,
    component: <SubscribedPremiumClass />,
  },
  { path: RoutePath.STUDENT_PROFILE, component: <StudentProfile /> },
  { path: RoutePath.STUDENT_CALENDAR, component: <Calendar /> },
  { path: RoutePath.FREE_USER_DASHBOARD, component: <FreeUserDashboard /> },

  { path: RoutePath.ACCOUNT_DASHBOARD, component: <AccountDashboard /> },
  { path: RoutePath.ACCOUNT_CLASSES, component: <AccountClasses /> },
  { path: RoutePath.ACCOUNT_PROFILE, component: <AccountProfile /> },
  { path: RoutePath.ACCOUNT_ANALYTICS_OLD, component: <AccountAnalytics /> },
  { path: RoutePath.ACCOUNT_CLASS_ANALYTICS, component: <ClassAnalytics /> },

  { path: RoutePath.INDEX, exact: true, component: <Navigate to="/login" /> },
  { path: RoutePath.NOT_FOUND, component: <Navigate to="/login" /> },
];

export default authProtectedRoutes;
