//Include Both Helper File with needed methods

import { getUnderstandingLevel } from 'common/data';
import {
  createClass,
  createClassV2,
  createPracticeClass,
  createPracticeSheet,
  deleteClass,
  downloadRecording,
  getClassConfiguration,
  getClassDetailsService,
  getLectureConfiguration,
  getPracticeClassPreview,
  getPracticeEvaluation,
  getStudentsByParams,
  listOfScheduledClassesService,
  setBatchTimerData,
  updateClass,
  updateClassV2,
  updatePracticeClass,
  updatePracticeSheet,
} from 'helpers/classes';
import {
  catchErrors,
  setAddOrUpdateLoader,
  setBookDemoLoading,
  setClassConfiguration,
  setClassDetails,
  setClassesList,
  setLoader,
  setPracticeClassLoading,
  setStudentsUnderstanding,
} from './reducer';

import { BookDemoClassService } from 'helpers/demoClasses';
import { successToastify } from 'helpers/toast';
import {
  addUnderstanding as addUnderstandingApi,
  getUnderstandingForClass as getUnderstandingForClassApi,
} from 'helpers/understanding';
import {
  setClassId,
  setCurrentLectureIndex,
  setLecture,
  setRawLecture,
} from 'slices/inClass/content/reducer';

export const getClassesList = (filtersData) => async (dispatch) => {
  try {
    // console.log('--filters-- ', filtersData);
    dispatch(setLoader(true));
    const { data, status } = await listOfScheduledClassesService(filtersData);
    // console.log('---list of scheduled classes--', data);
    status === 200 && dispatch(setClassesList(data));
  } catch (error) {
    dispatch(setLoader(false));
    // dispatch(apiError(error));
  }
};

export const createClassService = (dataTopost) => async (dispatch) => {
  try {
    dispatch(setAddOrUpdateLoader(true));
    const { status } = await createClass(dataTopost);
    // console.log('---list of scheduled classes--', data);
    status === 200 && successToastify('Class created successfully.');
    dispatch(setAddOrUpdateLoader(false));
  } catch (error) {
    dispatch(setAddOrUpdateLoader(false));
    // console.log("error in create class service ", error);
  }
};

export const updateClassService = (dataTopost) => async (dispatch) => {
  try {
    dispatch(setAddOrUpdateLoader(true));
    const { status } = await updateClass(dataTopost);
    // console.log('---list of scheduled classes--', data);
    status === 200 && successToastify('Class updated successfully.');
    dispatch(setAddOrUpdateLoader(false));
  } catch (error) {
    dispatch(setAddOrUpdateLoader(false));
    // console.log("error in udpate class service ", error);
  }
};

export const deleteClassService = (dataTopost) => async (dispatch) => {
  try {
    const { status } = await deleteClass(dataTopost);
    // console.log('---list of scheduled classes--', data);
    status === 200 && successToastify('Class deleted successfully.');
  } catch (error) {
    // console.log("error in delete class service ", error);
  }
};

export const getClass =
  (id = '') =>
  async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const { data, status, message } = await getClassDetailsService(id);
      if (status === 200) {
        dispatch(setClassDetails(data));
      }
    } catch (e) {
      const error = { status: e?.status || 400, message: e?.message || '' };
      dispatch(catchErrors(error));
    }
  };

export const getUnderstandingForClass =
  ({ classId, usage = 'tutor' }, headerInfo = '') =>
  async (dispatch) => {
    try {
      const { status, data } = await getUnderstandingForClassApi(
        { classId, usage },
        headerInfo,
      );
      if (status === 200) {
        const studentsUnderstandingMap = {};
        data?.forEach((ele) => {
          const { studentId, subTopicId, understandingLevel } = ele;
          if (studentId && subTopicId && understandingLevel) {
            const cellKey = `${studentId}-${subTopicId}`;
            studentsUnderstandingMap[cellKey] =
              getUnderstandingLevel(understandingLevel);
          }
        });
        dispatch(setStudentsUnderstanding(studentsUnderstandingMap));
      }
    } catch (error) {}
  };

export const addUnderstanding =
  (dataTopost = {}, headerInfo = '') =>
  async () => {
    try {
      const { status } = await addUnderstandingApi(dataTopost, headerInfo);
      if (status === 200) successToastify('Added understanding level');
    } catch (error) {}
  };

export const createClassServiceV2 = (dataTopost) => async (dispatch) => {
  try {
    dispatch(setAddOrUpdateLoader({ loader: true }));
    await createClassV2(dataTopost);
    dispatch(setAddOrUpdateLoader({ loader: false, success: true }));
  } catch (error) {
    dispatch(setAddOrUpdateLoader({ loader: false, error }));
  }
};

export const updateClassServiceV2 = (id, data) => async (dispatch) => {
  try {
    dispatch(setAddOrUpdateLoader({ loader: true }));
    await updateClassV2(id, data);
    dispatch(setAddOrUpdateLoader({ loader: false, success: true }));
  } catch (error) {
    dispatch(setAddOrUpdateLoader({ loader: false, error }));
  }
};

export const createPracticeSheetV2 = (data) => async (dispatch) => {
  try {
    dispatch(setPracticeClassLoading({ loader: true }));
    await createPracticeSheet(data);
    dispatch(setPracticeClassLoading({ loader: false, success: true }));
  } catch (error) {
    dispatch(setPracticeClassLoading({ loader: false, error }));
  }
};

export const createPracticeClassV2 = (data) => async (dispatch) => {
  try {
    dispatch(setPracticeClassLoading({ loader: true }));
    await createPracticeClass(data);
    dispatch(setPracticeClassLoading({ loader: false, success: true }));
  } catch (error) {
    dispatch(setPracticeClassLoading({ loader: false, error }));
  }
};

export const updatePracticeClassV2 = (id, data) => async (dispatch) => {
  try {
    dispatch(setPracticeClassLoading({ loader: true }));
    await updatePracticeClass(id, data);
    dispatch(setPracticeClassLoading({ loader: false, success: true }));
  } catch (e) {
    dispatch(setPracticeClassLoading({ loader: false, error: e }));
    const error = { status: e?.status || 400, message: e?.message || '' };
    dispatch(catchErrors(error));
  }
};

export const updatePracticeSheetV2 = (data, id) => async (dispatch) => {
  try {
    dispatch(setPracticeClassLoading({ loader: true }));
    await updatePracticeSheet(data, id);
    dispatch(setPracticeClassLoading({ loader: false, success: true }));
  } catch (e) {
    dispatch(setPracticeClassLoading({ loader: false, error: e }));
    const error = { status: e?.status || 400, message: e?.message || '' };
    dispatch(catchErrors(error));
  }
};

export const getPracticeClassPreviewV2 =
  (classId, headerInfo = '') =>
  async (dispatch) => {
    try {
      return await getPracticeClassPreview(classId, headerInfo);
    } catch (e) {
      const error = { status: e?.status || 400, message: e?.message || '' };
      dispatch(catchErrors(error));
    }
  };

export const getPracticeEvaluationV2 = async ({
  grade,
  board,
  subject,
  studentId,
  headerInfo = '',
}) => {
  return getPracticeEvaluation(grade, board, subject, studentId, headerInfo);
};

export const getStudentListV2 =
  (batchId, headerInfo = '') =>
  async (dispatch) => {
    try {
      return await getStudentsByParams(batchId, headerInfo);
    } catch (e) {
      const error = { status: e?.status || 400, message: e?.message || '' };
      dispatch(catchErrors(error));
    }
  };

export const joinDemoClassService = (dataTopost) => async (dispatch) => {
  try {
    dispatch(setBookDemoLoading({ loader: true }));
    await BookDemoClassService(dataTopost);
    dispatch(setBookDemoLoading({ loader: false, success: true }));
  } catch (error) {
    dispatch(setBookDemoLoading({ loader: false, error }));
  }
};

export const getConfigurationForClass =
  (classId, header) => async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const { data, status } = await getClassConfiguration(classId, header);
      if (status === 200) {
        dispatch(setClassConfiguration(data));
      }
      dispatch(setLoader(false));
    } catch (e) {}
  };

export const getLecture = (classId, header) => async (dispatch, getState) => {
  try {
    dispatch(setLoader(true));
    const { data, status } = await getLectureConfiguration(classId, header);
    const getClassId = getState().ContentInClass.classId;
    if (classId !== getClassId) {
      dispatch(setCurrentLectureIndex(0));
      dispatch(setClassId(classId));
    }
    if (status === 200) {
      await setBatchTimerData(data?.lectures || [], classId)();
      const currentLectureIndex = getState().ContentInClass.currentLectureIndex;
      dispatch(setRawLecture(data));
      dispatch(setLecture(data?.lectures?.[currentLectureIndex || 0]));
      dispatch(setCurrentLectureIndex(currentLectureIndex || 0));
    }
    dispatch(setLoader(false));
  } catch (e) {
    console.error('Error in getLecture:', e);
    dispatch(setLoader(false));
  }
};

export const changeLecture = (direction) => async (dispatch, getState) => {
  const rawLecture = getState().ContentInClass.setRawLecture;
  const currentIndex = getState().ContentInClass.currentLectureIndex;
  let newIndex = currentIndex;
  if (direction === 'next' && currentIndex < rawLecture.lectures.length - 1) {
    newIndex = currentIndex + 1;
  } else if (direction === 'previous' && currentIndex > 0) {
    newIndex = currentIndex - 1;
  }

  const newLecture = rawLecture?.lectures?.[newIndex];
  dispatch(setLecture(newLecture));
  dispatch(setCurrentLectureIndex(newIndex));
};

export const downloadRecordingService =
  (classId, recordingUrl = '') =>
  async (dispatch) => {
    try {
      await downloadRecording(classId, recordingUrl);
    } catch (e) {
      const error = { status: e?.status || 400, message: e?.message || '' };
      dispatch(catchErrors(error));
    }
  };
