import { errorToastify } from '../helpers/toast';

export const functionWrapper = (fn) => {
  return async (...args) => {
    try {
      await fn(...args);
    } catch (error) {
      errorToastify(
        error?.message || 'Something went wrong. Please try again.',
      );
      return {
        code: error?.code || 400,
        name: error?.name || 'Anonymous',
        message: error?.message || 'Something went wrong. Please try again.',
      };
    }
  };
};
