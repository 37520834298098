import { isEmpty } from 'lodash';
import { APIClient } from './api_helper';
import { setSlideTimer } from './html';
import * as url from './url_helper';
import { coachArrays } from 'pages/Constants';

const api = new APIClient();

// List Of Scheduled Classes (Student/Guardians)
export const listOfScheduledClassesService = (data = {}, headerInfo = '') => {
  let queryParams;
  if (data && typeof data === 'object') {
    queryParams = prepareQueryParams(data);
  }
  let apiUrl = url.LIST_OF_SCHEDULED_CLASSES + queryParams;

  return api.get(apiUrl, headerInfo);
};

//List Class V2 API
export const listClassV2 = (query = {}) => {
  let apiUrl = url.CLASS_DETAILS_V2;
  return api.get(apiUrl, { params: query });
};

export const classStatusChange = (data, classId, headerInfo = '') => {
  let apiUrl = url.CLASS_DETAILS_V2 + classId;
  return api.put(apiUrl, data, headerInfo);
};

// Class Details
export const getClassDetailsService = (id, headerInfo = '') => {
  let apiUrl = url.CLASS_DETAILS + id;
  return api.get(apiUrl, headerInfo);
};

// List Of Demo Classes (Student/Guardians)
export const listOfDemoClassesService = (data = {}, headerInfo = '') => {
  let queryParams;
  if (data && typeof data === 'object') {
    queryParams = prepareQueryParams(data);
  }
  let apiUrl = url.LIST_OF_DEMO_CLASSES + queryParams;
  return api.get(apiUrl, headerInfo);
};
// List Of Demo Class Configurations (Student/Guardians)
export const listOfDemoClassConfigurationsService = (
  data = {},
  headerInfo = '',
) => {
  return api.get(url.LIST_OF_DEMO_CLASS_CONFIGURATIONS, headerInfo);
};

// Join/Apply for the Demo Classes (Student/Guardians)
export const BookDemoClassService = (data, headerInfo = '') =>
  api.create(url.BOOK_OR_APPLY_FOR_DEMO_CLASS, data, headerInfo);

export const prepareQueryParams = (data = '') => {
  let queryParams;
  if (data) {
    queryParams = new URLSearchParams(data)?.toString();
    if (queryParams) {
      queryParams = '?' + queryParams;
    }
  }

  return queryParams;
};

export const createClass = (data, headerInfo = '') =>
  api.create(url.CREATE_CLASS, data, headerInfo);

export const updateClass = (data, headerInfo = '') =>
  api.put(url.UPDATE_CLASS, data, headerInfo);

export const deleteClass = (data, headerInfo = '') =>
  api.delete(url.DELETE_CLASS + data, headerInfo);

export const getJoinClassroomTokenService = (id, headerInfo = '') => {
  let apiUrl = url.JOIN_CLASSROOM_TOKEN + id;
  return api.get(apiUrl, headerInfo);
};

// List Of Notebooks (Via Subtopics)
export const getNotebookViaSubTopics = (data = {}, headerInfo = '') => {
  let queryParams;
  if (data && typeof data === 'object') {
    queryParams = prepareQueryParams(data);
  }
  let apiUrl = url.LIST_OF_NOTEBOOKS_VIA_SUBTOPICS + queryParams;
  return api.get(apiUrl, headerInfo);
};

export const getClassConfiguration = (classId, headerInfo = '') => {
  return api.get(`${url.CLASS_CONTENT}/${classId}`, headerInfo);
};

export const getLectureConfiguration = (classId, headerInfo = '') => {
  return api.get(`${url.CLASS_LECTURE}/${classId}`, headerInfo);
};

export const getClassTimer = (classId, headerInfo = '') => {
  return api.get(`${url.SLIDE_TIMER}/${classId}`, headerInfo);
};

export const createClassV2 = (data) => {
  return api.create(url.LIST_OF_CLASSES_V2, data);
};

export const updateClassV2 = (id, data) => {
  return api.put(url.LIST_OF_CLASSES_V2 + `/${id}`, data);
};

export const createPracticeClass = (data) => {
  return api.create(`${url.LIST_OF_CLASSES_V2}/practice-classes`, data);
};

export const createPracticeSheet = (data) => {
  return api.create(`${url.LIST_OF_PRACTICE_SHEET_V2}`, data);
};

export const getPracticeEvaluation = (
  grade,
  board,
  subject,
  studentId,
  headerInfo = '',
) => {
  return api.get(
    `${url.LIST_OF_CLASSES_V2}/notebook-practice-evaluation?grade=${grade}&board=${board}&studentId=${studentId}&subject=${subject}`,
    headerInfo,
  );
};

export const getPracticeSheet = async (queryString) => {
  const apiUrl = `${url.LIST_OF_PRACTICE_SHEET_V2}?${queryString}`;
  return api.get(apiUrl);
};

export const updatePracticeClass = (id, data) => {
  return api.put(url.LIST_OF_CLASSES_V2 + `/practice-classes/${id}`, data);
};

export const updatePracticeSheet = (data, id) => {
  return api.update(url.LIST_OF_PRACTICE_SHEET_V2 + `/${id}`, data);
};

export const getPracticeClassPreview = (classId, headerInfo = '') => {
  return api.get(
    `${url.LIST_OF_CLASSES_V2}/practice-classes/${classId}/preview`,
    headerInfo,
  );
};

export const getStudentsByParams = (batchId, headerInfo = '') => {
  return api.get(
    `/${url.LIST_OF_STUDENT_V2}`,
    {
      params: { 'filter[batchId]': batchId },
    },
    headerInfo,
  );
};

export const postElementStatus = (data) => {
  return api.create(url.CLASS_STATUS, data);
};

export const getAssistantLink = (batchId, classId, headerInfo = '') => {
  return api.get(
    `${url.LIST_OF_CLASSES_V2}/getAssistantLink?batchId=${batchId}&classId=${classId}`,
    headerInfo,
  );
};

export const downloadRecording = (classId, recordingUrl = '') => {
  const urlWithParams = `${url.CLASSES_V2}/${classId}/recording/download?recordingUrl=${recordingUrl}`;
  return api.get(urlWithParams);
};

export const downloadRawAttentionData = (classId) => {
  const urlWithParams = `${url.CLASSES_V2}/${classId}/attention/raw/export`;
  return api.get(urlWithParams);
};

export const downloadVideoAnalyticsAttentionData = (classId) => {
  const urlWithParams = `${url.CLASSES_V2}/${classId}/attention/video-analytics/export`;
  return api.get(urlWithParams);
};

export const downloadCalculatedAttentionData = (classId) => {
  const urlWithParams = `${url.CLASSES_V2}/${classId}/attention/calculated/export`;
  return api.get(urlWithParams);
};

export const downloadEmotionData = (classId) => {
  const urlWithParams = `${url.CLASSES_V2}/${classId}/emotion/export`;
  return api.get(urlWithParams);
};

export const downloadClassSummary = (classId) => {
  const urlWithParams = `/v2/reports/class-summaries/export/${classId}`;
  return api.get(urlWithParams);
};

export const submitTutorFeedback = (data) => {
  return api.create(url.SUBMIT_TUTOR_FEEDBACK, data);
};
export const getLectureSanityStatus = (classId) => {
  const urlWithParams = `${url.CLASSES_V2}/${classId}/content-status`;
  return api.get(urlWithParams);
};

export const getFeedbackById = (classId) => {
  const urlWithParams = `${url.GET_FEEDBACK_BY_ID}/${classId}`;
  return api.get(urlWithParams);
};

export const getNotebookEvaluation = (id, params) => {
  const paramsString = prepareQueryParams(params);
  const urlWithParams = `v2/classes/evaluations/${id}${paramsString}`;
  return api.get(urlWithParams);
};

export const getUpdatedSummaryDetails = (queryParams) => {
  const urlWithParams = `${url.LIST_OF_EVALUATIONS}`;
  return api.get(urlWithParams, { params: queryParams });
};

export const getElementBasedNbCaptures = (classId) => {
  const urlWithParams = `v2/classes/evaluations/${classId}/teach-phase-nb-capture`;
  return api.get(urlWithParams);
};

export const getClassFeedback = (params, classId) => {
  let apiUrl = `${url.LIST_OF_FEEDBACKS}/${classId}`;
  return api.get(apiUrl, { params });
};

export const getSimplifiedQuestion = (params) => {
  let apiUrl = url.QC_FILTER_STEP;
  return api.get(apiUrl, { params });
};

export const getQcFilters = async (params) => {
  let apiUrl = url.QC_GAP_FILTER;
  return api.get(apiUrl, { params });
};

export const getQcReport = async (params) => {
  let apiUrl = url.QC_GAP_SUMMARY_LIST;
  return api.get(apiUrl, { params });
};

export const getQcStepReport = async (params) => {
  let apiUrl = url.QC_GAP_STEPS;
  return api.get(apiUrl, { params });
};

export const addOngoingChapter = async (payload) =>
  api.create(url.ONGOING_CHAPTERS, payload);

export const getRecentOngoingChapter = async (params) =>
  api.get(`${url.ONGOING_CHAPTERS}/recent`, {
    params,
  });

export const updateOnGoingLecture = async (classId, lectureId) => {
  const data = { lectureId };

  api.update(`${url.CLASS_SUMMARIES_V2}/${classId}/ongoing-lectures`, data);
};

export const setBatchTimerData = (lecturesArray, classId) => async () => {
  try {
    const { data: existingTimers, status } = await getClassTimer(classId);

    const initialSlideState = {
      teach: 1,
      coach_basic: 1,
      coach_intermediate: 1,
      coach_advanced: 1,
    };

    const initialStepState = {
      teach: 0,
      coach_basic: 0,
      coach_intermediate: 0,
      coach_advanced: 0,
    };

    const processClassContent = (lectureData) => {
      const slideTimers = {
        teach: {},
        coach_basic: {},
        coach_intermediate: {},
        coach_advanced: {},
      };
      const elementTimers = {
        teach: {},
        coach_basic: {},
        coach_intermediate: {},
        coach_advanced: {},
      };

      const processSlideArray = (array, targetSlideObject) => {
        if (!Array.isArray(array)) {
          console.warn('Expected an array, but got:', array);
          return;
        }

        let slideCounter = 1;
        if (array.some((item) => item?.type !== 'evaluation')) {
          array
            .filter((item) => item?.type !== 'evaluation')
            .forEach((item) => {
              if (item.thumbnails && Array.isArray(item.thumbnails)) {
                item.thumbnails.forEach((thumbnail) => {
                  const processedItem = {
                    elementId: item?.elementDetails?.elementId,
                    elementName: item?.elementDetails?.name,
                    elementSlideNo: thumbnail.slideNo,
                    slideNo: slideCounter,
                    timer: 0,
                  };
                  targetSlideObject[slideCounter++] = processedItem;
                });
              }
            });
        }

        return slideCounter - 1;
      };

      const processElementArray = (array, targetObject) => {
        if (!Array.isArray(array)) {
          console.warn('Expected an array, but got:', array);
          return;
        }

        let elementCounter = 0;
        array.forEach((item) => {
          const processedItem = {
            slideType: item?.type,
            title: item?.elementDetails?.name || item?.title || null,
            timer: 0,
            elementId: item?.elementDetails?.elementId || null,
            evaluationId: item?.evaluationId || null,
            evaluationType: item?.evaluationType || null,
            evaluationAt: item?.evaluateAt || null,
          };
          targetObject[elementCounter++] = processedItem;
        });
      };

      let totalSlides = 0;

      // Process teach section
      if (Array.isArray(lectureData.teach)) {
        totalSlides = processSlideArray(lectureData.teach, slideTimers.teach);
        processElementArray(lectureData.teach, elementTimers.teach);
      }

      // Process coach sections
      coachArrays.forEach((coachArray) => {
        if (Array.isArray(lectureData?.[coachArray])) {
          processSlideArray(lectureData[coachArray], slideTimers[coachArray]);
          processElementArray(
            lectureData[coachArray],
            elementTimers[coachArray],
          );
        }
      });

      return { slideTimers, elementTimers, totalSlides };
    };

    if (!Array.isArray(lecturesArray)) {
      console.error('Expected an array of lectures');
      return;
    }

    lecturesArray.forEach((lectureData) => {
      if (isEmpty(lectureData)) return;

      const existingLectureTimer =
        status === 200 && !isEmpty(existingTimers)
          ? existingTimers.find(
              (timer) => timer.lectureConfigurationId === lectureData.configId,
            )
          : null;

      if (!existingLectureTimer) {
        const { slideTimers, elementTimers, totalSlides } =
          processClassContent(lectureData);

        setSlideTimer({
          classId: classId,
          currentSlide: { ...initialSlideState },
          currentStep: { ...initialStepState },
          slideTimers: slideTimers,
          elementTimers: elementTimers,
          totalNumberOfSlide: totalSlides,
          lectureConfigurationId: lectureData.configId,
          currentPhase: 'teach',
        });
      }
    });
  } catch (err) {
    console.log('error in batch processing timers', err);
  }
};
