import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
  loading: {
    loader: false,
    success: false,
    error: null,
  },
};

const sanitySlice = createSlice({
  name: 'sanitySlice',
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
    reset: (state) => {
      state.loading = initialState.loading;
    },
  },
});

export const getSanityLoaderRedux = (state) => state.Sanity.loading;

export const { setLoader, reset } = sanitySlice.actions;

export default sanitySlice.reducer;
