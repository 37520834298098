import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const GuardianLayoutMenudata = () => {
  const history = useNavigate();

  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isStudentClasses, setIsStudentClasses] = useState(false);
  const [isStudentProfileAndSttings, setIsStudentProfileAndSttings] =
    useState(false);

  const [iscurrentState, setIscurrentState] = useState(false);

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute('subitems')) {
      const ul = document.getElementById('two-column-menu');
      const iconItems = ul.querySelectorAll('.nav-icon.active');
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove('active');
        var id = item.getAttribute('subitems');
        if (document.getElementById(id))
          document.getElementById(id).classList.remove('show');
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel');
    if (iscurrentState !== 'Dashboard') {
      setIsDashboard(false);
    }
    if (iscurrentState !== 'Classes') {
      setIsStudentClasses(false);
    }
    if (iscurrentState !== 'profileSetting') {
      setIsStudentProfileAndSttings(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isStudentClasses,
    isStudentProfileAndSttings,
  ]);

  const menuItems = [
    {
      id: 'dashboard',
      label: 'Dashboard',
      icon: 'mdi mdi-speedometer',
      link: '/account/dashboard',
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState('Dashboard');
        updateIconSidebar(e);
      },
    },
    {
      id: 'myClasses',
      label: 'Classes',
      icon: 'mdi mdi-google-classroom',
      link: '/account/classes',
      stateVariables: isStudentClasses,
      click: function (e) {
        e.preventDefault();
        setIsStudentClasses(!isStudentClasses);
        setIscurrentState('Classes');
        updateIconSidebar(e);
      },
    },
    {
      id: 'profileSetting',
      label: 'Profile & Settings',
      icon: 'mdi mdi-account-outline',
      link: '/account/profile',
      stateVariables: isStudentProfileAndSttings,
      click: function (e) {
        e.preventDefault();
        setIsStudentProfileAndSttings(!isStudentProfileAndSttings);
        setIscurrentState('profileSetting');
        updateIconSidebar(e);
      },
    },
  ];

  return <React.Fragment>{menuItems}</React.Fragment>;
};

export default GuardianLayoutMenudata;
