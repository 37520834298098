import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getStudentReportData } from 'slices/admin/qcGap/reducer';

import { chapterWiseStepLevelAnswers } from 'helpers/report';
import Header from './ReportStructureComponent/Header';
import StudentPerformance from './ReportStructureComponent/StudentPerformance';
import Footer from './ReportStructureComponent/Footer';
import QcWiseUnderstandingLevel from './ReportStructureComponent/QcWiseUnderstandingLevel';
import NotebookDetails from './ReportStructureComponent/NotebookDetails';
import {
  QC_WISE_UNDERSTANDING_LEVEL_HEADERS,
  GAP_STATUS_ORDER,
} from 'pages/Constants';
import { generateOutput } from './helper';

import './generateReport.css';

const StudentReport = () => {
  const studentReportData = useSelector(getStudentReportData);
  const [feedbacks, setFeedbacks] = useState([]);
  const [notebookEvalDetails, setNotebookEvalDetails] = useState([]);
  const [editableData, setEditableData] = useState([]);

  const handleInputChange = (e, index) => {
    const newFeedbacks = [...feedbacks];
    newFeedbacks[index] = e.target.value;
    setFeedbacks(newFeedbacks);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const newFeedbacks = [...feedbacks];
      if (newFeedbacks[index].trim()) {
        newFeedbacks.push('');
        setFeedbacks(newFeedbacks);
      }
    } else if (
      e.key === 'Backspace' &&
      feedbacks[index] === '' &&
      feedbacks.length > 1
    ) {
      e.preventDefault();
      const newFeedbacks = feedbacks.filter((_, i) => i !== index);
      setFeedbacks(newFeedbacks);
    }
  };

  useEffect(() => {
    if (studentReportData) {
      let sortedQuestionFamilySummary =
        studentReportData?.questionFamilySummary?.sort((a, b) => {
          return (
            GAP_STATUS_ORDER.indexOf(a.qcGapStatus) -
            GAP_STATUS_ORDER.indexOf(b.qcGapStatus)
          );
        });

      sortedQuestionFamilySummary = sortedQuestionFamilySummary?.map((qf) => {
        const { sillyMistakes, skillsToBeTaught, typeOfIntervention } = qf;
        return {
          ...qf,
          sillyMistakes: sillyMistakes === '' ? 'No' : sillyMistakes,
          skillsToBeTaught: skillsToBeTaught === '' ? 'NA' : skillsToBeTaught,
          typeOfIntervention:
            typeOfIntervention === '' ? 'None' : typeOfIntervention,
        };
      });

      // sorting based on understanding level as well as setting initials as no, na and none
      setEditableData(sortedQuestionFamilySummary);

      // setting feedbacks if there else add you feedback as initial
      if (studentReportData?.feedbacks?.length) {
        setFeedbacks(studentReportData.feedbacks);
      } else {
        setFeedbacks(['Add your feedback here...']);
      }

      // step level answer details for all qc covered by student.
      const fetchStepLevelAnswers = async () => {
        const questionFamilyIds = sortedQuestionFamilySummary?.map(
          (qc) => qc.questionFamilyId,
        );
        const payload = {
          chapterId: studentReportData.chapterId,
          subjectId: studentReportData.subjectId,
          studentId: studentReportData.studentId,
          questionFamilyIds: questionFamilyIds,
        };
        const stepLevelAnswerRecords =
          await chapterWiseStepLevelAnswers(payload);
        const result = generateOutput(
          stepLevelAnswerRecords?.data,
          sortedQuestionFamilySummary,
        );

        setNotebookEvalDetails(result);
      };

      // find step Level answer records
      fetchStepLevelAnswers();
    }
  }, [studentReportData]);

  const getInitials = (studentDetails) => {
    const { firstName = '', lastName = '' } = studentDetails || {};

    const firstInitial = firstName.charAt(0).toUpperCase();
    const lastInitial = lastName.charAt(0).toUpperCase();

    return `${firstInitial}${lastInitial}`;
  };

  const getAssessmentStatus = (questionsAttempted) => {
    return questionsAttempted >= 1 ? 'Yes' : 'No';
  };

  const getUnderstandingLevel = (qcGapStatus) => {
    if (qcGapStatus?.includes('ni')) return '⭐⭐⭐⭐';
    if (qcGapStatus?.includes('li')) return '⭐⭐⭐';
    if (qcGapStatus?.includes('hi')) return '⭐⭐';
    return '⭐';
  };

  const handleChange = (e, id, field) => {
    const newData = editableData.map((item) => {
      if (item.questionCategoryId === id) {
        return { ...item, [field]: e.target.value };
      }
      return item;
    });
    setEditableData(newData);
  };

  useEffect(() => {
    handleFirstPageBreak();
  }, []);

  const handleFirstPageBreak = () => {
    const firstPageBreakdiv = document.querySelector('.tutor-observation-div');
    const secondPageBreakdiv = document.querySelector(
      '.understanding-level-div',
    );
    firstPageBreakdiv.classList.add('page-break');
    secondPageBreakdiv.classList.add('page-break');
  };

  return (
    <div className="report-container">
      {/* header */}
      <Header
        studentDetails={studentReportData?.studentDetails}
        initials={getInitials(studentReportData?.studentDetails)}
      />
      <div className="main-body bg-white relative overflow-hidden">
        {/* student performance */}
        <StudentPerformance
          studentReportData={studentReportData}
          feedbacks={feedbacks}
          handleInputChange={handleInputChange}
          handleKeyDown={handleKeyDown}
        />

        <Footer classname="tutor-observation-div" />

        <Header
          studentDetails={studentReportData?.studentDetails}
          initials={getInitials(studentReportData?.studentDetails)}
          className="print-header"
        />

        {/* qc wise understanding level */}
        <QcWiseUnderstandingLevel
          studentReportData={studentReportData}
          editableData={editableData}
          getUnderstandingLevel={getUnderstandingLevel}
          handleChange={handleChange}
          QcWiseUnderstandingLevelHeaders={QC_WISE_UNDERSTANDING_LEVEL_HEADERS}
          getAssessmentStatus={getAssessmentStatus}
        />

        <Footer classname="understanding-level-div" />

        <Header
          studentDetails={studentReportData?.studentDetails}
          initials={getInitials(studentReportData?.studentDetails)}
          className="print-header"
        />

        {/* notebook detailed response */}
        <NotebookDetails
          studentReportData={studentReportData}
          notebookEvalDetails={notebookEvalDetails}
        />
      </div>
      <Footer />
    </div>
  );
};

export default StudentReport;
