//Include Both Helper File with needed methods

import {
  createBatch,
  createBatchV2,
  updateBatch,
  updateBatchV2,
  listOfBatchesService,
  deleteBatch,
  updateDemoBatch,
} from 'helpers/batches';
import { successToastify } from 'helpers/toast';
import {
  setBatchesList,
  setLoader,
  setTotalRecords,
  setAddOrUpdateLoader,
  setUpdateDemoBatchLoader,
} from './reducer';

export const getBatchesList = (filter) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const { data, status } = await listOfBatchesService(filter);
    status === 200 &&
      dispatch(setBatchesList(data.records)) &&
      dispatch(setTotalRecords(data.totalRecords));
  } catch (error) {
    dispatch(setLoader(false));
  }
};

export const createBatchService = (data) => async (dispatch) => {
  try {
    dispatch(setAddOrUpdateLoader({ loadre: true }));
    await createBatch(data);
    successToastify('Batch created successfully.');
    dispatch(setAddOrUpdateLoader({ loader: false, success: true }));
  } catch (error) {
    dispatch(setAddOrUpdateLoader({ loader: false, error }));
  }
};

export const createBatchServiceV2 = (data) => async (dispatch) => {
  try {
    dispatch(setAddOrUpdateLoader({ loader: true }));
    await createBatchV2(data);

    dispatch(setAddOrUpdateLoader({ loader: false, success: true }));
  } catch (error) {
    dispatch(setAddOrUpdateLoader({ loader: false, error }));
  }
};

export const updateBatchService = (data) => async (dispatch) => {
  try {
    dispatch(setAddOrUpdateLoader({ loader: true }));
    await updateBatch(data);
    successToastify('Batch updated successfully.');
    dispatch(setAddOrUpdateLoader({ loader: false, success: true }));
  } catch (error) {
    dispatch(setAddOrUpdateLoader({ loader: false, error }));
  }
};

export const updateBatchServiceV2 = (id, data) => async (dispatch) => {
  try {
    dispatch(setAddOrUpdateLoader({ loader: true }));
    await updateBatchV2(id, data);
    dispatch(setAddOrUpdateLoader({ loader: false, success: true }));
  } catch (error) {
    dispatch(setAddOrUpdateLoader({ loader: false, error }));
  }
};

export const updateDemoBatchService = (id, data) => async (dispatch) => {
  try {
    dispatch(setUpdateDemoBatchLoader({ loader: true }));
    await updateDemoBatch(id, data);
    dispatch(setUpdateDemoBatchLoader({ loader: false, success: true }));
  } catch (error) {
    dispatch(setUpdateDemoBatchLoader({ loader: false, error }));
  }
};

export const deleteBatchService = (data) => async (dispatch) => {
  try {
    await deleteBatch(data);
    successToastify('Batch removed successfully.');
  } catch (error) {}
};
